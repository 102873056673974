import React, {useState, useEffect, useReducer} from 'react';
import classNames from '../../../_ClassNames';

import ItemGrid from '../../../Components/Inventory/ItemGrid';
import PackageGrid from '../../../Components/Inventory/PackageGrid';
import PackageEdit from '../../../Components/Inventory/PackageEdit';
import Modifiers from '../../../Components/Inventory/Modifiers';
import ItemEdit from '../../../Components/Inventory/ItemEdit';

import {default as ItemsApi} from '../../../Api/Items'

export default function Main(props) {
	let {page} = props

	let components = {
		items: <Items {...props} />,
		modifiers: <Modifiers {...props} />
	}

	return (components[page]);

}

function Items(props) {

	const {	
			items,
			setItems,
			currentPackage,
			setCurrentPackage,
			setCurrentItem,
			currentItem,
			currentCategory,
			isPackage,
			setIsPackage,
			categories,
			setCategories,
			packages,
			setPackages,
			modifierCategories,
	} = props

	let [editingPackage, setEditingPackage] = useState(false)
	let [updateMessage, setUpdateMessage] = useState("")

	useEffect(function() {
		if (updateMessage) {
			setTimeout(function() {
				setUpdateMessage("")
			}, 2000)
		}
 	}, [updateMessage]) 


	useEffect(function() {
		if (!editingPackage) {
			setCurrentPackage(false)
			setCurrentItem(false)
		}
 	}, [currentCategory]) 

	if (currentCategory.name === "Packages") {
		return (
			<> 
				<ShowPackages 
					packages={packages}
					setPackages={setPackages}
					currentPackage={currentPackage}
					setCurrentPackage={setCurrentPackage}
					setCurrentItem={setCurrentItem}
					editingPackage={editingPackage}
					setEditingPackage={setEditingPackage}
				/>
			</>
		)
	} else {
		return (
			<div>

				<div className="grid sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 text-black">
					{!editingPackage && 
							<ItemEdit 
								currentItem={currentItem} 
								setCurrentItem={setCurrentItem} 
								items={items}
								setItems={setItems}
								categories={categories}
								setCategories={setCategories}
								currentCategory={currentCategory}
								modifierCategories={modifierCategories}
							/>
						
					}

					{editingPackage && 
						<PackageEdit setPackages={setPackages} setEditingPackage={setEditingPackage} currentPackage={currentPackage} setCurrentPackage={setCurrentPackage}/>
					}
					
					<ShowItems 
						items={items} 
						setItems={setItems}
						currentItem={currentItem}
						setCurrentItem={setCurrentItem}
						currentCategory={currentCategory}
						currentPackage={currentPackage}
						setCurrentPackage={setCurrentPackage}
						editingPackage={editingPackage}
						setEditingPackage={setEditingPackage}
						categories={categories}
						setCategories={setCategories}
					/>
					
					
				</div>
			</div>
			
		)
	}		
}

function ShowItems({items, setItems, currentItem, setCurrentItem, currentCategory, currentPackage, setCurrentPackage, editingPackage, setEditingPackage, categories, setCategories}) {

	return (
			<div>
				<div className="p-5">
					<div className="underline text-xl font-bold">
						{currentCategory.name}
					</div>
					<button 
						className={` ${classNames.add_item_button} border-2 rounded m-5 p-3 package-items`} 
						onClick={() => {
							setCurrentItem({
								id: false,
								name: "",
								price: 0,
								description: "",
								quantity: 1,
								categoryId: currentCategory.id,
								items: [],
							})
							setCurrentPackage(false);
							setEditingPackage(false)
						}}
					>
						
							Add +
						
					</button>
				</div>

				
				<div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 text-black">

			
				</div>
				
				

				<ItemGrid 
					items={items} 
					sortable={true}
					onClick={(item) => {
						if (!editingPackage) {
							setCurrentItem(item)
							setCurrentPackage(false)
						} else {	
							//add items to package
							function updateDuplicate() {
								let {package_items} = currentPackage;
								let found = false;
								for (let i in package_items) {
									if (item.name === package_items[i].name) {
										package_items[i].quantity += item.quantity;
										found = true;
										break;
									}
								}
								if (found) {
									let price = 0;
									currentPackage.package_items = package_items
									for (let i of currentPackage.package_items) {
										price += i.price * i.quantity;
									}
									currentPackage.price = price
									setCurrentPackage({...currentPackage});
									return true
								}
								

								return false
							}
							
							if (!updateDuplicate()) {
								currentPackage.package_items.push({
									price: item.price,
									quantity: item.quantity,
									name: item.name,
									description: item.description,
								});

								let price = 0;
								for (let i of currentPackage.package_items) {
									price += i.price * i.quantity;
								}
								currentPackage.price = price

								setCurrentPackage({...currentPackage})
							}
						}
					}}

					onDelete={async (item) => {
						let destroyed = await ItemsApi.destroy(item.id);
						
						if (destroyed.code === 1) {
							items = items.filter(i => i.id !== item.id)
							setItems(items)
							categories = categories.map((category, index) => {
								if (category.name === currentCategory.name) {
									category.items = items;
								}
								return category
							})
							setCategories(categories);
						}
						
					}}
				/>
			</div>
	)
}


function ShowPackages({packages, setPackages, currentPackage, setCurrentPackage, setCurrentItem, editingPackage, setEditingPackage}) {
	return (
		<div className="grid sm:grid-cols-2 md:grid-cols-2-1 lg:grid-cols-2-1 xl:grid-cols-2-1 text-black">
			<div>
				<div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 text-black">
					<button 
						className={` ${classNames.add_item_button} border-2 rounded m-5 p-3 package-items`} 
						onClick={() => {
							setCurrentPackage({
								name: "",
								price: 0,
								description: "",
								quantity: 1,
								package_items: [],
							})
							setCurrentItem(false)
							setEditingPackage(true)
						}}>
						<div className="text-left">
							New +
						</div>
					</button>
				</div>
				
				<div className="p-5 text-xl underline">Packages</div>
				
				<PackageGrid 
					packages={packages} 
					onClick={(item) => {
						setCurrentPackage(item)
						setCurrentItem(false);
						setEditingPackage(true);
					}}
				/>
			</div>
			<PackageEdit 
				currentPackage={currentPackage} 
				setCurrentPackage={setCurrentPackage} 
				setEditingPackage={setEditingPackage}
				setPackages={setPackages}
			/>
		</div>
		
	)
}

