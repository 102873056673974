import React from 'react'
import Orders from '../../../Api/Orders'
import classNames from '../../../_ClassNames'


export default function OrderCancelButtons({order, setOrder, setOrders}) {
	
	function cancelOrder(canceled) {
		Orders.update(order.id, {canceled: canceled}).then(data => {
			if (data.code === 1) {
				if (setOrders) {
					setOrders(prev => {
						return (
							prev.map(_order => {
								if (_order.id === order.id) {
									_order.canceled = data.orders.canceled;
								}
								return _order
							})
						)
					})
				}
				setOrder(prevOrder => {return ({...prevOrder, canceled: data.orders.canceled})})
			}
		})
	}

	return (
		<>
			{order.canceled && 
				<button className={`${classNames.enable_order_button} text-right text-xs`} onClick={(e) => {
					e.preventDefault()
					cancelOrder(false)
				}}>Enable</button>
			}
			{!order.canceled && 
				<button className={`${classNames.cancel_order_button} text-right text-xs`} onClick={(e) => {
					e.preventDefault()
					cancelOrder(true);
				}}>Cancel</button>
			}
		</>
	)
}