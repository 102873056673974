import React, {useState, useEffect} from 'react' 
import Items from '../../Api/Items'
import ItemModifiers from './ItemModifiers';

let classNames = {
	input: 'appearance-none block w-full h-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500',
	label: "block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2",
	dollar_label: "block uppercase tracking-wide text-gray-700 text-xl font-bold mb-2",
	customer_button: "w-11/12 ml-3 mt-3 bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 mb-5 border border-gray-400 rounded shadow text-left",
	cancel_button: "w-11/12 ml-3 mt-3 bg-white hover:bg-red-100 text-red-800 font-semibold py-2 px-4 mb-5 border border-red-400 rounded shadow text-left",
	remove_button: "mt-8 bg-white hover:bg-red-100 text-red-800 font-semibold py-2 px-6 border border-red-400 rounded shadow text-right text-xs",
	modifier_button: "float-right h-full bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 mb-5 border border-gray-400 rounded shadow text-center",
}

export default function ItemEdit({currentItem, setCurrentItem, items, setItems, categories, setCategories, currentCategory, modifierCategories}) {
	if (!currentItem) {
		return null;
	}


	function updateItem() {
		Items.update(currentItem.id, {
			name: currentItem.name,
			price: currentItem.price,
			quantity: currentItem.quantity,
			description: currentItem.description,
		}).then(data => {
			let item = data.items
			items = items.map((tmpItem, i) => {
				if (tmpItem.id === item.id) {
					return item
				}
				return tmpItem
			})
			setItems(items)

			categories = categories.map((category, index) => {
				if (category.name === currentCategory.name) {
					category.items = items;
				}
				return category
			})
			setCategories(categories);
		})
	}


	function createItem() {
		Items.create(currentItem).then(data => {
			let item = data.item;

	  		setItems(prev => {
	  			return [...prev, data.items];
	  		})

  			for (let category of categories) {
				if (category.id === data.items.categoryId) {
					category.items.push({
						id: data.items.id,
						name: data.items.name,
	  					price: data.items.price,
	  					description: data.items.description,
	  					quantity: data.items.quantity,
					})
					break ;
				}
			}

			setCategories([...categories])
			setCurrentItem(false)
		})	
	}

	
	function validateItem() {
		if (!currentItem.name) {
  			console.log("No Name")
  			return false ;
  		} else if (!currentItem.price) {
			console.log("No price")
			return false ;
  		} else if (!currentItem.quantity) {
  			console.log("no Quantity")
  			return false ;
  		} else if (!currentItem.categoryId) {
  			console.log("No Category")
  			return false ;
  		}
  		return true
	}

	return(
			<div className="w-full max-w-lg p-5">
				<div className="grid grid-cols-2">
				  	<button className={classNames.customer_button} onClick={() => {
				  		if (!validateItem()) {
				  			return false
				  		}

				  		if (currentItem.id) {
				  			updateItem();
				  		} else {
				  			createItem()
				  		}

				  	}}>Save</button>

				  	<button className={classNames.cancel_button} onClick={() => {
				  		setCurrentItem(false)
				  	}}>Cancel</button>  
			    </div>
			    <div className="w-full px-3 mb-6 md:mb-0">
			    	<label className={classNames.label} for="grid-last-name">
						Name
					</label>
					<input 
						className={classNames.input} 
						value={currentItem.name} 
						onChange={(e) =>{
					  	setCurrentItem((prevState) => {
					  		return (
					  			{...prevState, name: e.target.value}
					  		)
					  	})
					}}/>
			    </div>
			    <div className="w-full px-3">
					<label className={classNames.label} for="grid-last-name">
						Price
					</label>
					<input 
						type="number"
						step="0.1"
						className={classNames.input} 
						value={currentItem.price} 
						onChange={(e) =>{
					  	setCurrentItem((prevState) => {
					  		return (
					  			{...prevState, price: e.target.value}
					  		)
					  	})
					}}/>
			    </div>
			 	
			    <div className="w-full px-3">
					<label className={classNames.label} for="grid-last-name">
						Quantity
					</label>
					<input 
						type="number"
						className={classNames.input} 
						value={currentItem.quantity} 
						onChange={(e) =>{
					  	setCurrentItem((prevState) => {
					  		return (
					  			{...prevState, quantity: e.target.value}
					  		)
					  	})
					}}/>
		    	</div>

			    <div className="w-full px-3 mb-6 md:mb-0">
					<label className={classNames.label} for="grid-first-name">
						Description
					</label>
					<input 
						className={classNames.input} 
						value={currentItem.description} 
						onChange={(e) =>{
					  	setCurrentItem((prevState) => {
					  		return (
					  			{...prevState, description: e.target.value}
					  		)
					  	})
					}}/>
			    </div>
			   <ItemModifiers modifierCategories={modifierCategories} currentItem={currentItem}/>
			</div>
	) 

}