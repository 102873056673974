import React from 'react'

export default function Leftnav({setPage, pageNames}) {
	return (
		 <ul className="p-5">
		 	{pageNames.map((page, index) => {
		 		return (
	 				<li key={`page-index-${page}`}>
				        <button
						  className='border-b-2 rounded w-11/12 p-3 ml-2 mt-2'
						  onClick={() => {
								setPage(page)
						  }}
						>
					  		<small className='text-xs'>{page}</small>
						</button>
		   			</li>
		   		)
		 	})}
	      	
	  </ul>

	)
}