
let classNames = {
	input: 'appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500',
	label: "block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2",
	item_remove_button: "text-xs float-right bg-white hover:bg-red-100 text-red-800 font-semibold py-1 px-2 border border-red-400 rounded shadow text-center",
	back_button: "w-full mt-3 bg-white hover:bg-red-100 text-red-800 font-semibold py-2 px-4 border border-red-400 rounded shadow text-left",
	clear_button: "w-11/12 mt-3 bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow text-left",
	confirm_button: "ml-2 mt-3 mb-4 w-11/12 bg-white hover:bg-green-100 text-green-800 font-semibold py-2 px-4 border border-green-400 rounded shadow text-left",
	e_contract_confirm_button: "mt-3 mb-4 w-full p-5 bg-white hover:bg-green-100 text-green-800 font-semibold border border-green-400 rounded shadow text-center",
	select_page_button: "ml-2 mt-3 mb-4 w-50 bg-white hover:bg-green-100 text-green-800 font-semibold py-2 px-4 border border-green-400 rounded shadow text-left",
	add_item_button: "bg-white hover:bg-green-100 text-green-800 font-semibold py-2 px-4 border border-green-400 rounded shadow text-left",
	payment_type_button: "w-full bg-white hover:bg-green-100 text-green-800 font-semibold py-2 px-4 border border-green-400 rounded shadow text-left",

	cancel_order_button: "w-full mt-3 bg-white hover:bg-red-100 text-red-800 font-semibold py-2 px-4 border border-red-400 rounded-r-md shadow text-left",
	enable_order_button: "w-full mt-3 bg-white hover:bg-yellow-100 text-yellow-800 font-semibold py-2 px-4 border border-yellow-400 rounded-r-md shadow text-left",
	view_customer_button: "w-full mt-3 bg-white hover:bg-green-100 text-green-800 font-semibold py-2 px-4 border border-green-400 rounded-l-md shadow text-left",

	customer_button: "w-full mt-3 bg-green-100 hover:bg-green-300 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow text-left",

	inventory: {
		show_items: "w-full mt-3 bg-white hover:bg-green-100 text-green-800 font-semibold py-2 px-4 border border-green-400 rounded-r-md shadow text-left",
		show_items_selected: "w-full mt-3 bg-green-300 text-black font-semibold py-2 px-4 border border-green-400 rounded-r-md shadow text-left",
		show_modifiers: "w-full mt-3 bg-white hover:bg-blue-100 text-blue-800 font-semibold py-2 px-4 border border-blue-400 rounded-l-md shadow text-left",
		show_modifiers_selected: "w-full mt-3 bg-blue-300 text-black font-semibold py-2 px-4 border border-blue-400 rounded-l-md shadow text-left",
		add_button: "w-1/4 mt-3 bg-white hover:bg-green-100 text-green-800 font-semibold py-2 px-4 p-2 m-3 border border-green-400 rounded-r-md shadow text-left",
		cancel_button: "w-full mt-3 bg-white hover:bg-red-100 text-red-800 font-semibold py-2 px-4 border border-red-400 rounded-r-md shadow text-left",
		tooltip_button: "w-1/4 bg-white hover:bg-green-100 text-green-800 font-semibold py-2 px-4 p-2 border border-green-400 rounded-r-md shadow text-left",

	}
	


}

export default classNames