import React , {useState, useEffect} from 'react'
import Users from '../../../Api/Users';


export default function Register({setUser}) {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [masterKey, setMasterKey] = useState("");

     return (
             <div className="p-20 items-center justify-center min-h-screen bg-gray-100">
                <div className="px-8 py-6 mt-4 text-left bg-white shadow-lg">
                    <div className="flex justify-center">
                    </div>
                    <h3 className="text-2xl font-bold text-center p-10 ">Register account</h3>
                   
                        <div className="mt-4">
                            <div>
                                <label className="block" htmlFor="email">Email</label>
                                <input 
                                    type="text" 
                                    placeholder="Email" 
                                    className="w-full px-4 py-2 mt-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-blue-600" 
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                                <span className="text-xs tracking-wide text-red-600">Email field is required </span>
                            </div>
                            <div className="mt-4">
                                <label className="block">Password</label>
                                <input 
                                    type="password" 
                                    placeholder="Password" 
                                    className="w-full px-4 py-2 mt-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-blue-600" 
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                            </div>
                            <div className="mt-4">
                                <label className="block">Confirm Password</label>
                                <input 
                                    type="password" 
                                    placeholder="Password" 
                                    className="w-full px-4 py-2 mt-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-blue-600" 
                                    value={confirmPassword}
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                />
                            </div>

                            <div className="mt-4">
                                <label className="block">Master Key</label>
                                <input 
                                    type="password" 
                                    placeholder="Key to Create a new Profile" 
                                    className="w-full px-4 py-2 mt-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-blue-600" 
                                    value={masterKey}
                                    onChange={(e) => setMasterKey(e.target.value)}
                                />
                            </div>

                            <div className="flex items-baseline justify-between">
                                <button 
                                    type="button"
                                    className="px-6 py-2 mt-4 text-white bg-blue-600 rounded-lg hover:bg-blue-900"
                                    onClick={ async () => {
                                        if (password !== confirmPassword) {
                                            alert("Passwords do not match");
                                            return false;
                                        }
                                        let data = await Users.register(email, password, masterKey);
                                        if (data.code === 1) {
                                            setUser(data.user); 
                                        } else {
                                            alert(data.message);
                                        }
                                    }}
                                >Register</button>
                                {/*<a href="#" className="text-sm text-blue-600 hover:underline">Forgot password?</a>*/}
                            </div>
                        </div>
                    
                </div>
            </div>
        )

}