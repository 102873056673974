import React, {useState, useContext} from 'react'

import ApplicationSettingsContext from '../../Context/ApplicationSettings'
import {default as SettingsApi} from '../../Api/Settings'
import Checkbox from '../Checkbox';
import ToolTip from '../ToolTip';


export default function InventorySettings() {
	let {applicationSettings : settings, setApplicationSettings} = useContext(ApplicationSettingsContext);
	const [show_inventory_packages, setShowInventoryPackages] = useState(settings.show_inventory_packages);
	return (
		<>
			<ToolTip tip="Toggle whether you use packages or not">
				<Checkbox 
					label='Inventory Packages' 
					checked={show_inventory_packages} 
					setChecked={(checked) => {
						SettingsApi.update(settings.id, {show_inventory_packages: checked}).then(data => {
							// console.log(data)
						})
						setShowInventoryPackages(checked)
						setApplicationSettings(settings => {
							settings.show_inventory_packages = checked;
							return settings;
						})
					}}
				/>
			</ToolTip>

		</>

	)
}