import React, {useRef, useContext} from 'react'

import ReactTooltip from 'react-tooltip';
import ApplicationSettingsContext from '../../Context/ApplicationSettings'
	export default function ToolTip({children, tip, toolTipOn}) {
			let {applicationSettings : settings} = useContext(ApplicationSettingsContext);

			if (toolTipOn || settings.toolTips) {
				return <ToolTipOn tip = {tip}>{children}</ToolTipOn>
			} else {
				return <ToolTipOff>{children}</ToolTipOff>
			}
			
	}
	


	function ToolTipOn({children, tip}) {
		let ref = useRef();
			return (
				<>
					<div ref={ref => ref = ref} data-tip={tip} className="" style={{backgroundColor: "white"}}>
						{children}
					</div>

					<ReactTooltip />
				</>
			)
	}

	function ToolTipOff({children}) {
			return (
				<>
					<div className="pt-5" style={{backgroundColor: "white"}}>
						{children}
					</div>
				</>
			)
	}
