import React from 'react'

export default function OrderItemGrid({items, onClick}) {
	return (
		<div>
			<div className="grid sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-1 text-black">
				{items && items.map((item, index) => {
					return (
						<div className="">
							<div className="flex flex-row p-1">
								<small className="w-full">
									{item.name}	<span style={{fontSize: 10}}>(x{item.quantity})</span>
								</small>
								<div className="mr-5 w-full text-right">
									${item.price} 
								</div>
							</div>
							<hr />
						</div>
					)
				})}
			</div>
		</div>
	)

}