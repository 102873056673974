import React from 'react'


export default function ShowCustomerInformation({order}) {
	return (
		<div className='customer-list-information'>
		    <div className='customer-list-name'>
		      {order.first_name}
		      {' '}
		      {order.last_name}
		    </div>

		    <div className='customer-list-phone'>
		      {order.phone_number}
		    </div>

		    <div className='customer-list-address truncate'>
		      {order.address}
		      {' '}
		      {order.city}
		    </div>
		    <div className='customer-list-date'>
		      {order.rental_date.toString()} {order.rental_time}
		    </div>
		</div>
	)
}