import React, {useState, useEffect} from 'react'
import "react-grid-layout/css/styles.css"
import "react-resizable/css/styles.css"
import GridLayout from "react-grid-layout";
import Items from '../../Api/Items';
import useResize from '../../Hooks/useResize'
export default function ItemGrid({items, onClick, onDelete, sortable, skipGrid}) {

	let [layout, setLayout] = useState([]);
	let width = useResize();
	useEffect(function() {
		if (items) {
			let _layout = items.map(item => {
				return {
					i: item.id.toString(),
					x: item.x,
					y: item.y,
					w: 3,
					h: 3,
					static: sortable ? false : true
				}
			})
			setLayout(_layout)
			}
		
	}, [items])



	if (width >= 600 && !skipGrid) {
		return (
		<GridLayout
	        className="layout"
	        layout={layout}
	        cols={8}
	        rowHeight={20}
	        width={800}
	        onDragStop = {async (items) => {
				let _items = items.map(item => {
					item.id = parseInt(item.i);
					return item;
				})
				await Items.updatePositions(_items);

			}}
      	>
        {items && items.map((item, index) => {
			let name = item.name;
			let price = item.price;
			let quantity = item.quantity;
			return (
				<div key={item.id.toString()}>
					{onDelete && 
						<div className="w-full">
							<div 
								style={{marginTop: 5, marginRight: 10}} 
								className="cursor-pointer float-right mb-5 border-2 w-1/12 rounded-md text-center hover:bg-red-300 hover:border-red-300 hover:text-red-800 bg-red-100 border-red-100 text-black"
								onClick={() => {
									onDelete(item);
								}}	
							>x</div>
						</div>
					}
							<div 
								className="border-2 rounded m-2 p-3 package-items cursor-pointer" 
								onClick={() => { onClick(item)}}
							>
								<div className="text-left">
									{name}	
								</div>
								<div className="text-right text-xs">
									${price} x {quantity}
								</div>
							</div>
				</div>
			)
		})}
      </GridLayout>

	)
	}
	


	return (
		<div>
			<div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 text-black">
				{items && items.map((item, index) => {
					let name = item.name;
					let price = item.price;
					let quantity = item.quantity;
					return (
						<div>
							{onDelete && 
								<div className="w-full">
									<div 
										style={{marginTop: 5, marginRight: 10}} 
										className="cursor-pointer float-right mb-5 border-2 w-1/12 rounded-md text-center hover:bg-red-300 hover:border-red-300 hover:text-red-800 bg-red-100 border-red-100 text-black"
										onClick={() => {
											onDelete(item);
										}}	
									>x</div>
								</div>
							}
							<div 
								className="border-2 rounded m-2 p-3 package-items cursor-pointer" 
								onClick={() => { onClick(item)}}
							>
								<div className="text-left">
									{name}	
								</div>
								<div className="text-right">
									${price} x {quantity}
								</div>
							</div>
						</div>
					)
				})}
			</div>
		</div>
	)

}
