import React, {useState, useEffect} from 'react'
import Checkbox from '../Checkbox';
import Employees from '../../Api/Employees'

import classNames from '../../_ClassNames'

import { EditText, EditTextarea } from 'react-edit-text';
import 'react-edit-text/dist/index.css';

export default function EmployeeSettings() {

	let [page, setPage] = useState('Show Employees');
	let [employees, setEmployees] = useState([]);

	useEffect(function() {
		Employees.getAll().then(data => {
			if (data.code === 1) {
				setEmployees(data.employees);	
			} else {
				alert(data.message)
			}
			
		})
	}, [])
 
	let components = {
		"Show Employees": <ShowEmployees employees={employees} setEmployees={setEmployees} />,
		"Add Employee" : <RegisterEmployee setEmployees={setEmployees} />
	}

	return (
		<>
			Employee Settings
			<div className="flex flex-row">
				{components && Object.keys(components).map((component, index) => {
					return (<button className={`${classNames.select_page_button}`} onClick={() => setPage(component)}>{component}</button>)
				})}
			</div>
			{components[page]}
		</>

	)
}


function ShowEmployees({employees, setEmployees}) {

	 const roles = ["Employee", "Manager", "Admin"];

	function updateEmployee(employee, field, value) {
		setEmployees(old => {
 			return (
 				old.map(emp => {
 					if (emp.id === employee.id) {
 						emp[field] = value;
 					}
 					return emp
 				})
 			)
 		})
	}

	return(
		<div className="grid w-full">
				{employees && employees.map((employee, index) => {
					return (
						<div className="text-xl text-left bg-white shadow-lg">
							<div className="p-5">
						 	<EditText value={employee.first_name} onChange={(text) => { updateEmployee(employee, "first_name", text) }} showEditButton />
						 	<EditText value={employee.last_name} onChange={(text) => { updateEmployee(employee, "last_name", text) }} showEditButton />
						 	</div>
							<div className="p-2" >{employee.email}</div>

							<div className="w-full border-t-2 border-b-2">
								
								<div className="mt-2 p-3">
                            		<Checkbox label="Paid By Piece" id={`emp-paid-piece-${employee.id}`} checked={employee.paid_by_piece} setChecked={(checked) => {updateEmployee(employee, "paid_by_piece", checked)}} />
                            	</div>

								<div className="m-3">
	                            	<Checkbox label="Paid Hourly" id={`emp-paid-hourly-${employee.id}`} checked={employee.paid_hourly} setChecked={(checked) => {updateEmployee(employee, "paid_hourly", checked)}} />
	                            </div>
								<div className="w-full p-3">
									<div className=''>
									Hourly Rate:
									</div>
									<div className='p-5'>
										<EditText value={employee.hourly_rate} type="number" step='0.01' onChange={(text) => { updateEmployee(employee, "hourly_rate", text) }} showEditButton />
									</div>
								</div>
							</div>

    
                            
							
							<div className="flex p-5 w-full">
							{roles.map((_role, index) => {
                        		return (
                    				<button 
                    					className={`text-sm p-5 w-50 border-0 ${_role === employee.role ? "bg-green-100" : "bg-white"}`}
                    					onClick={() => updateEmployee(employee, 'role', _role)}
                    				>
                    					{_role}
                    				</button>
                    			)
                        	})}
                        	</div>

                        	<div className="m-3">
                        		<Checkbox label="Delivery Driver" id={`emp-driver-${employee.id}`} checked={employee.driver} setChecked={(checked) => {updateEmployee(employee, "driver", checked)}} />
                        	</div>
							
							<div className="flex flex-row p-4">
								<button className={`${classNames.view_customer_button}`} onClick={() => {
									Employees.update(employee.id, {...employee}).then(data => {
									})
								}}>Save</button>
								<button className={`${employee.active ? classNames.cancel_order_button : classNames.enable_order_button} text-right`} onClick={(e) => {
									Employees.update(employee.id, {active: !employee.active}).then(data => {
										if (data.code === 1) {
											let _employee = data.employees;
											setEmployees(old => {
												let employeeList = old.map(_e => {
													if (_e.id === _employee.id) {
														return _employee;
													} else {
														return _e;
													}
												})
												return employeeList
											})
										} else {
											alert(data.message)
										}
									})
								}}>{employee.active ? "Remove" : "Enable"} </button>
							</div>
						</div>
					)
				})}
		</div>
	)
}


function RegisterEmployee({setEmployees}) {
	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [driver, setDriver] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

	const [paid_hourly, setPaidHourly] = useState(false)
	const [paid_by_piece, setPaidByPiece] = useState(false)
	const [hourly_rate, setHourlyRate] = useState(0);

    const [role, setRole] = useState("Employee");

    const roles = ["Employee", "Manager", "Admin"];


    return (
             <div className="items-center justify-center min-h-screen">
                <div className="px-8 py-6 mt-4 text-left bg-white shadow-lg">
                    <div className="flex justify-center">
                    </div>
                    <h3 className="text-2xl font-bold text-center p-10 ">Add Employee</h3>
                        <div className="mt-4">
                        	<div className="flex flex-row mb-5">
	                         	<div>
	                                <label className="block" htmlFor="email">First Name</label>
	                                <input 
	                                    type="text" 
	                                    placeholder="Last name" 
	                                    className="w-full px-4 py-2 mt-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-blue-600" 
	                                    value={firstName}
	                                    onChange={(e) => setFirstName(e.target.value)}
	                                />
	                            </div>
	                            <div className='ml-5'>
	                                <label className="block" htmlFor="email">Last Name</label>
	                                <input 
	                                    type="text" 
	                                    placeholder="Last Name" 
	                                    className="w-full px-4 py-2 mt-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-blue-600" 
	                                    value={lastName}
	                                    onChange={(e) => setLastName(e.target.value)}
	                                />
	                            </div>
                            </div>
                            <div>
                                <label className="block" htmlFor="email">Email</label>
                                <input 
                                    type="text" 
                                    placeholder="Email" 
                                    className="w-full px-4 py-2 mt-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-blue-600" 
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                                <span className="text-xs tracking-wide text-red-600">Email field is required </span>
                            </div>
                            <div className="mt-4">
                                <label className="block">Password</label>
                                <input 
                                    type="password" 
                                    placeholder="Password" 
                                    className="w-full px-4 py-2 mt-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-blue-600" 
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                            </div>
                            <div className="mt-4">
                                <label className="block">Confirm Password</label>
                                <input 
                                    type="password" 
                                    placeholder="Password" 
                                    className="w-full px-4 py-2 mt-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-blue-600" 
                                    value={confirmPassword}
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                />
                            </div>

                           

                            <div className="mt-4 border-b-2 p-5">
                            	{roles.map((_role, index) => {
                            		return <button 
                            					className={`flex flex-column text-lg p-5 w-50 border-0 ${_role === role ? "bg-green-100" : "bg-white"}`}
                            					onClick={() => setRole(_role)}
                            				>
                            				{_role}
                            				</button>
                            	})}
                            </div>

                            


                            <div className="flex flex-row mb-5 p-5">
	                         	<div className="mt-4">
                            		<Checkbox label="Paid Hourly" checked={paid_hourly} setChecked={setPaidHourly} />
                            	</div>

	                            
                            </div>

                            <div className='ml-5 flex flex-row'>
	                                <label className="block mt-4 ml-4 mr-4" htmlFor="email">Rate:</label>
	                                <input 
	                                    type="number"
	                                    step="0.01" 
	                                    placeholder="Rate" 
	                                    className="w-full px-4 py-2 mt-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-blue-600" 
	                                    value={hourly_rate}
	                                    onChange={(e) => setHourlyRate(e.target.value)}
	                                />
	                            </div>


                             <div className="mt-4 p-5 pb-10 border-b-2">
                            	<Checkbox label="Paid Per Piece" checked={paid_by_piece} setChecked={setPaidByPiece} />
                            </div>



                             <div className="mt-6">
                            	<Checkbox label="Delivery Driver" checked={driver} setChecked={setDriver} />
                            </div>


                            <div className="flex items-baseline justify-between mt-4">
                                <button 
                                    type="button"
                                    className="px-6 py-2 mt-4 text-white bg-blue-600 rounded-lg hover:bg-blue-900"
                                    onClick={ async () => {
                                        if (password !== confirmPassword) {
                                            alert("Passwords do not match");
                                            return false;
                                        }
                                        let data = await Employees.create({
                                        	first_name: firstName,
	                                         last_name: lastName,
	                                         email:email,
	                                         password:password,
	                                         role: role,
	                                         driver: driver,
	                                         paid_by_piece: paid_by_piece,
	                                         paid_hourly: paid_hourly,
	                                         hourly_rate: hourly_rate,
	                                     });
                                        if (data.code === 1) {
                                        	setEmployees(old => {
                                        		return [...old, data.employee]
                                        	})
                                            alert("Success")
                                        } else {
                                            alert(data.message);
                                        }
                                    }}
                                >Add Employee</button>
                            </div>
                        </div>
                    
                </div>
            </div>
        )

}
