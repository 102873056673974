import React, {useState, useEffect} from 'react'
import moment from 'moment'
import OrderItems from "../../Api/OrderItems"

export default function ItemSales({customers, from, to}) {
  
  let [total, setTotal] = useState(false);
  let [items, setItems] = useState(false);
  let [searchItems, setSearchItems] = useState('')
  let [search, setSearch] = useState("");


  useEffect(function() {
  	OrderItems.getBreakdown(from, to).then(data => {
  		setItems(data.order_items.breakdown);
  		setTotal(data.order_items.totals)
  	})
  }, [from, to])


  useEffect(function() {
  	if (items && search) {
  		let searchReturn = [];
  		for (let item of items)
  			if (item.name.toLowerCase().indexOf(search.toLowerCase()) > -1) {
  				searchReturn.push(item);
  			}

  		setSearchItems(searchReturn)

  	} else {
  		setSearchItems(false)
  	}
  }, [search])

  return (
	<>
		<div className="p-5 border-b-2 w-full">
				<div className="text-2xl underline w-full pb-4">Item Breakdown</div>
				<input className="border-2 rounded mb-2" placeholder="search" type="search" value={search} onChange={(e) => setSearch(e.target.value)}/>
		  		{searchItems && <div className="mb-4 text-xl">Search Results</div>}
		  		<table className="table-fixed text-left w-full">
				  <thead>
					<tr>
						<th className="p-2">Name</th>
						<th className="text-center p-2">Amount</th>
						<th className="text-right p-2">Total</th>
					</tr>
				  </thead>
				  <tbody className="text-left m-5">
				   {searchItems && searchItems.map((item, index) => {
				  	return (
				  			<tr style={{height: "50px"}} className={`border-b-2 ml-2 rounded ${index % 2 === 0 ? "border bg-gray-100" : ""}`}>
						      <td>{item.name}</td>
						      <td className="text-center">{item.quantity}</td>
						      <td className="text-right">${item.total}</td>
						    </tr>
				  		)
				  })}
				  {searchItems &&
				  	 <tr style={{height: "50px"}}>
				      <td></td>
				      <td></td>
				      <td></td>
				    </tr>
				  }

				  {!searchItems && items && items.map((item, index) => {
				  	return (
				  			<tr style={{height: "50px"}} className={`border-b-2 ml-2 ${index % 2 === 0 ? "border bg-gray-100" : ""}`}>
						      <td>{item.name}</td>
						      <td className="text-center">{item.quantity}</td>
						      <td className="text-right">${item.total}</td>
						    </tr>
				  		)
				  })}

				    <tr style={{height: "50px"}}>
				      <td></td>
				      <td></td>
				      <td></td>
				    </tr>
				    <tr className="border bg-green-100">
				      <td>Total</td>
				      <td className="text-center">{total && total.quantity}</td>
				      <td className="text-right">${total && total.price}</td> 
				    </tr>
				  </tbody>
				</table>
		</div>
	</>
  )

}
