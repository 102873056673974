import React , {useState, useEffect} from 'react'
import { useHistory } from "react-router-dom";

import classNames from '../../../_ClassNames'
export default function Leftnav({customer}) {
    let history = useHistory();
    return (
    	<div className="p-4">
	        <button className={`${classNames.customer_button} text-center`} onClick={() => {
	            history.push(`/newOrder/${customer.id}`)
	        }} >
	            Create Order 
	        </button>
        </div>
    )
}
