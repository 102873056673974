import React, {useState, useRef, useContext} from 'react'
import Checkbox from '../Checkbox';
import ReactTooltip from 'react-tooltip';
import ToolTip from '../ToolTip'
import ApplicationSettingsContext from '../../Context/ApplicationSettings'
import {default as SettingsApi} from '../../Api/Settings'

export default function OrderSettings() {
	let {applicationSettings : settings, setApplicationSettings} = useContext(ApplicationSettingsContext);

	let [show_customer_information_form, setShowCustomerInformation] = useState(settings.show_customer_information_form);
	let [show_order_confirmation, setShowOrderConfirmation] = useState(settings.show_order_confirmation);
	let customerinformation = useRef();
	let confirmationOrder = useRef();

	return (
		<div className="mt-5">
			Order Settings
			

			<ToolTip tip="If Off - Bypasses Customer information when creating a New Order">
				<Checkbox 
					label='Customer Information Form' 
					checked={show_customer_information_form} 
					setChecked={(checked) => {
						SettingsApi.update(settings.id, {show_customer_information_form: checked}).then(data => {
							// console.log(data)
						})
						setShowCustomerInformation(checked)
						setApplicationSettings(settings => {
							settings.show_customer_information_form = checked;
							return settings;
						})
					}} />
			</ToolTip>

			<ToolTip tip="If Off - Skips order confirmation when creating a New Order">
				<Checkbox 
					label='Confirm Order Form' 
					checked={show_order_confirmation} 
					setChecked={(checked) => {
						SettingsApi.update(settings.id, {show_order_confirmation: checked}).then(data => {
							// console.log(data)
						})
						setShowOrderConfirmation(checked)
						setApplicationSettings(settings => {
							settings.show_order_confirmation = checked;
							return settings;
						})
					}} />
			</ToolTip>
		</div>
	)
}
