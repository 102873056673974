import React, {useState, useEffect, useRef} from 'react'
import PageSkeleton from '../../Components/PageSkeleton'
import CategoryList from '../../Components/SidebarLists/CategoryList'
import ItemGrid from '../../Components/Inventory/ItemGrid'
import ItemEdit from '../../Components/Inventory/ItemEdit'

import Categories from '../../Api/Categories'
import Packages from '../../Api/Packages'
import {default as ModifierCategoriesApi} from '../../Api/ModifierCategories'


import Main from './Main'
import Leftnav from './Leftnav'



export default function Inventory() {
	const [items, setItems] = useState([]);
	const [categories, setCategories] = useState([])
	const [currentCategory, setCurrentCategory] = useState('')
	const [packages, setPackages] = useState([]);
	
	const [currentItem, setCurrentItem] = useState(false);
	const [currentPackage, setCurrentPackage] = useState(false);
	const [isPackage, setIsPackage] = useState(false);
	const [page, setPage] = useState('items');


	const [modifierCategories, setModifierCategories] = useState([])
	const [currentModifierCategoryIndex, setCurrentModifierCategoryIndex] = useState(0)

	const [currentModifierCategory, setCurrentModifierCategory] = useState([])
	const [currentModifier, setCurrentModifier] = useState(false)


	useEffect(function() {
		Categories.getWithAllAssociations().then(data => {
			if (data.categories.length) {
				setCategories(data.categories)
				setCurrentCategory(data.categories[0])
				setItems(data.categories[0].items)
			}
		})

		ModifierCategoriesApi.getAllWithAssociations().then(data => {
			setModifierCategories(data.modifier_categories)
			setCurrentModifierCategory(data.modifier_categories[0])
		})

		Packages.getWithAllAssociations().then(data => {
			setPackages(data.packages)
		})

	}, [])

	return (
		<PageSkeleton 
			Main={<Main 
					items={items} 
					setItems={setItems}
					currentItem={currentItem} 
					setCurrentItem={setCurrentItem}
					currentCategory={currentCategory}
					isPackage={isPackage} 
					setIsPackage={setIsPackage}
					setCategories={setCategories}
					categories={categories}
					packages={packages} 
					setPackages={setPackages}
					currentPackage={currentPackage}
					setCurrentPackage={setCurrentPackage}
					page={page}
					setCurrentModifierCategory={setCurrentModifierCategory}
					modifierCategories={modifierCategories}
					setModifierCategories={setModifierCategories}
					currentModifierCategoryIndex={currentModifierCategoryIndex}
					currentModifierCategory={modifierCategories[currentModifierCategoryIndex]}
					setCurrentModifier={setCurrentModifier}
					currentModifier={currentModifier}
			/>}

			Leftnav={
				<Leftnav 
					categories={categories} 
					setCategories={setCategories}
					setCurrentCategory={setCurrentCategory}
					setItems={setItems}  
					packages={packages}
					page={page} 
					setPage={setPage}

					modifierCategories={modifierCategories}
					setModifierCategories={setModifierCategories}
					onModifierCategoryClick={(modifier, index) => {
						setCurrentModifierCategory(modifier);
						setCurrentModifierCategoryIndex(index);
					}}
					onModifierCategoryDelete={(modifierCategory) => {
						ModifierCategoriesApi.destroy(modifierCategory.id).then(data => {
							setModifierCategories(data.modifier_categories)
							if (data.modifier_categories.length) {
								setCurrentModifierCategory(data.modifier_categories[0]);
							} else {
								setCurrentModifierCategory([]);
							}
						})
					}}
				/>
			}
			Rightnav={null}
			nav={{leftOpen: true}}
			omitSearch={true}
		/>

	)
}
