import React from 'react';

export default function Leftnav({setPage}) {
    return (
        <ul className="pt-5">
            <li>
                <button
                    className='border-b-2 rounded w-11/12 p-3 ml-2 mt-2'
                    onClick={() => {
                        setPage("login")
                    }}
                >
                    <small className='text-xs'>Login</small>
                </button>
            </li>

            <li>
                <button
                    className='border-b-2 rounded w-11/12 p-3 ml-2 mt-2'
                    onClick={() => {
                        setPage("register")
                    }}
                >
                    <small className='text-xs'>Register</small>
                </button>
            </li>
        </ul>
    )
}