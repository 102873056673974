import React, {useState, useEffect, useContext} from 'react'

import Categories from '../../Api/Categories'
import Packages from '../../Api/Packages'
import Customers from '../../Api/Customers'
import Orders from '../../Api/Orders'

import {useParams} from 'react-router-dom'

import ApplicationSettingsContext from '../../Context/ApplicationSettings'

import moment from 'moment'

import Customer from './Customer'
import Items from './Items'
import Confirm from './Confirm'

import {useHistory} from 'react-router-dom'

export default function NewOrder() {
	
	let {id} = useParams();
	let history = useHistory();

	let {applicationSettings : settings, setApplicationSettings} = useContext(ApplicationSettingsContext);

	//const [page, set_page] = useState(settings.show_customer_information_form ? 0 : 1);
	const [page, set_page] = useState(0);
	const [customer, set_customer] = useState(false);
	const [first_name, set_first_name] = useState("")
	const [last_name, set_last_name] = useState("")
	const [date, set_date] = useState(moment().format('YYYY-MM-DD'))
	const [time, set_time] = useState("09:00")
	const [address, set_address] = useState("");
	const [latitude, setLatitude] = useState(false);
	const [longitude, setLongitude] = useState(false);

	const [city, set_city] = useState("")
	const [phone, set_phone] = useState("")
	const [email, set_email] = useState("")
	const [notes, set_notes] = useState("")
	const [discount, set_discount] = React.useState(0);
	const [price, set_price] = React.useState(0);

	const [items, setItems] = useState(false);
	const [packages, setPackages] = useState(false);
	const [categories, setCategories] = useState(false);

	const [customers, setCustomers] = useState([]);

	const [customer_items, set_customer_items] = useState([]);
	const [customer_packages, set_customer_packages] = useState([]);
	const [previous_orders, set_previous_orders] = useState(false);

	useEffect(function() {
          if (id) {
            Customers.getCustomerWithOrders(id).then(data => {
              let customer = data.customers;
              set_customer(customer)
              set_first_name(customer.first_name);
              set_last_name(customer.last_name);
              set_address(customer.address);
              set_phone(customer.phone_number);
              set_city(customer.city);
              set_email(customer.email)
              set_notes(customer.notes)
              set_previous_orders(customer.orders);
            })
          } else {
            set_customer(false)
            set_previous_orders(false)
            set_first_name("");
            set_last_name("");
            set_address("");
            set_phone("");
            set_city("");
            set_email("");
            set_notes("");
          }
	}, [id])

	useEffect(function() {
          Categories.getWithAllAssociations().then(data => {
            setItems(data.categories[0].items)
            setCategories(data.categories)
          })

          Packages.getWithAllAssociations().then(data => {
            setPackages(data.packages)
          })

          Customers.getWithAllAssociations().then(data => {
            setCustomers(data.customers)
          })
	}, [])

	useEffect(function() {
          //set_page(settings.show_customer_information_form ? 0 : 1);
          set_page(0);
	}, [settings])

	async function sendOrder() {
          let _customer = false;

          if (!customer) {
            let data = await Customers.create({
              first_name: first_name,
              last_name: last_name,
              date: date,
              time: time,
              address: address,
              city: city,
              phone_number: phone,
              email: email,
              notes: notes,
            })
            _customer = data.customers
          } else {
            _customer = customer
          }

          let order_items = [];
          let order_packages = [];

          for (let i of customer_items) {
            order_items.push({
              name: i.name,
              price: i.price,
              quantity: i.quantity,
              description: i.description,
            })
          }

          for (let pack of customer_packages) {
            let order_package_items = [];
            for (let order_package_item of pack.package_items) {
              order_package_items.push({
                name: order_package_item.name,
                price: order_package_item.price,
                quantity: order_package_item.quantity,
              })
            }

            order_packages.push({
              name: pack.name,
              price: pack.price,
              quantity: pack.quantity,
              order_package_items: order_package_items,
            })
          }


          Orders.createOrdersWithOrderItems({
            customerId: _customer.id,
            first_name: first_name,
            last_name: last_name,
            rental_date: date,
            rental_time: time,
            address: address,
            city: city,
            phone_number: phone,
            email: email,
            notes: notes,
            price: price,
            canceled: false,
            discount: discount,
            order_items: order_items,
            order_packages: order_packages,
          }).then(data => {
            if(data.code === 1) {
              history.push(`/order/${data.orders.id}`)
            }
          }).catch(err => {
            console.log(err);
          })
        }


	if (page === 0) {
          return (
            <Customer
              id={id}
              set_page={set_page}
              customer={customer}
              set_customer={set_customer}
              first_name={first_name}
              set_first_name={set_first_name}
              last_name={last_name}
              set_last_name={set_last_name}
              date={date}
              set_date={set_date}
              time={time}
              set_time={set_time}
              address={address}
              set_address={set_address}
              latitude={latitude}
              setLatitude={setLatitude}
              longitude={longitude}
              setLongitude={setLongitude}
              city={city}
              set_city={set_city}
              phone={phone}
              set_phone={set_phone}
              email={email}
              set_email={set_email}
              notes={notes}
              set_notes={set_notes}
              customers={customers}
            />)
        }

	if (page === 1) {
            return (
              <>
                <Items 
                  customer={customer} 
                  set_page={set_page} 
                  items={items} 
                  packages={packages} 
                  customer_items={customer_items} 
                  set_customer_items={set_customer_items}
                  customer_packages={customer_packages}
                  set_customer_packages={set_customer_packages}
                  price={price}
                  set_price={set_price}
                  discount={discount}
                  set_discount={set_discount}
                    show_confirm={true} categories={categories} 
                    setItems={setItems}
                    previous_orders={previous_orders}
                    removeBackButton={settings.show_customer_information_form ? false : true}
                    onConfirm={settings.show_order_confirmation ? false : async () => {
                        await sendOrder();
                    }}
                />
              </>
            )
	}

	if (page === 2) {
          return (
            <Confirm 
              set_page={set_page}
              customer={customer}
              set_customer={set_customer}
              first_name={first_name}
              set_first_name={set_first_name}
              last_name={last_name}
              set_last_name={set_last_name}
              date={date}
              set_date={set_date}
              time={time}
              set_time={set_time}
              address={address}
              set_address={set_address}
              latitude={latitude}
              setLatitude={setLatitude}
              longitude={longitude}
              setLongitude={setLongitude}
              city={city}
              set_city={set_city}
              phone={phone}
              set_phone={set_phone}
              email={email}
              set_email={set_email}
              notes={notes}
              set_notes={set_notes}
              customers={customers}
              price={price}
              set_price={set_price}
              customer_items={customer_items}
              set_customer_items={set_customer_items}
              customer_packages={customer_packages}
              set_customer_packages={set_customer_packages}
              discount={discount}
              set_discount={set_discount}
              show_confirm={false}
              onConfirm={async () => {
                await sendOrder();
              }}
            />
          )
        }



}


