import React , {useState, useEffect} from 'react'
import QuickDateList from '../../Components/SidebarLists/QuickDateList.js'
import PageSkeleton from '../../Components/PageSkeleton'
import { useHistory, useParams, Link } from "react-router-dom";
import Customers from '../../Api/Customers'
import Orders from '../../Api/Orders'

import Main from './Main'
import Leftnav from './Leftnav'

export default function Customer() {
	let history = useHistory();
    let {id} = useParams();

    let [customer, setCustomer] = useState(false)
    let [totalPrice, setTotalPrice] = useState(false)
    
    useEffect(function() {
        Customers.getCustomerWithOrders(id).then(data=> {
            setCustomer(data.customers)
        })

        Orders.getCustomersOrderTotal(id).then(data=> {
            setTotalPrice(data.total);
        })

    }, [id])

    return (
    	<>
    	     <PageSkeleton 
                	Main={<Main id={id} customer={customer} totalPrice={totalPrice}/>}
                	Rightnav={<QuickDateList onClick={(day) => {history.push(`/search/date/${day.day}`)}} />}
                	Leftnav={<Leftnav customer={customer}/>}
                	nav={{ leftOpen: window.innerWidth > 648 ? true : false, rightOpen: false }}
                />
    	</>
    )
}
