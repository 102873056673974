import React, {useState, useContext} from 'react'
import ApplicationSettingsContext from '../../Context/ApplicationSettings'

import Checkbox from '../Checkbox';
import ToolTip from '../ToolTip';
import {default as SettingsApi} from '../../Api/Settings'


export default function HomeSettings() {
	let {applicationSettings : settings, setApplicationSettings} = useContext(ApplicationSettingsContext);
	let [show_home_customer_information, setShowHomeCustomerInformation] = useState(settings.show_home_customer_information);
	let [toolTips, setToolTips] = useState(settings.toolTips);
	return (
		<>
			

			<ToolTip tip="Turns Off All ToolTips">
				<Checkbox 
					label='Tips on Hover' 
					checked={toolTips} 
					setChecked={(checked) => {
						SettingsApi.update(settings.id, {toolTips: checked}).then(data => {
							// console.log(data)
						})
						setToolTips(checked)
						setApplicationSettings(settings => {
							settings.toolTips = checked;
							return settings;
						})
					}}
				/>
			</ToolTip>
			<ToolTip tip="Turns Off Customer Information On Home Page">
				<Checkbox 
					label='Customer Information' 
					checked={show_home_customer_information} 
					setChecked={(checked) => {
						SettingsApi.update(settings.id, {show_home_customer_information: checked}).then(data => {
							// console.log(data)
						})
						setShowHomeCustomerInformation(checked)
						setApplicationSettings(settings => {
							settings.show_home_customer_information = checked;
							return settings;
						})
					}}
				/>
			</ToolTip>

		</>

	)
}