import React, {useState, useEffect} from 'react'
import {useParams, Link, useHistory} from 'react-router-dom'

import SearchCustomers from '../../Components/Search/SearchCustomers.js'
import QuickDateList from '../../Components/SidebarLists/QuickDateList.js'
import PageSkeleton from '../../Components/PageSkeleton'


import Customers from '../../Api/Customers'


export default function SearchText() {
	
	let {search} = useParams();
	let history = useHistory();

	let [customers, setCustomers] = useState([])
	let [existing_customers, set_existing_customers] = useState([]);
	let [price, setPrice] = useState(0);


	useEffect(function() {
		Customers.getWithAllAssociations().then(data => {
			setCustomers(data.customers)
		})
	}, [search])

	useEffect(function() {
		let price = 0;

		for (let customer of existing_customers) {
			for (let order of customer.orders) {
				price += order.price;
			}
		}
		setPrice(price)
	}, [existing_customers])

	return (
		<PageSkeleton 
        	Main={	
    			<SearchCustomers 
    				onFindEffect={(matches) => set_existing_customers(matches) }
    				search={search}
    				customers={customers}
    				onClick={(customer) => history.push(`/customer/${customer.id}`)}
    			/>
        	}
        	Rightnav={<QuickDateList onClick={(day) => {
        		history.push(`/search/date/${day.day}`)
        	}}/>}

        	Leftnav={
        		<ul className='space-y-4 p-5'>
            		<li className="">
            			Total Price: ${price}
            		</li>
            		<li className="">
            			Customers: {existing_customers.length}
            		</li>
					<li className="border-b-2">
					</li>
	    		</ul>
			}
			nav={{leftOpen: window.innerWidth < 940 ? false : true, rightOpen: window.innerWidth < 940 ? false : true}}
        />
	)
}