import React from 'react'
import usePhoneFormat from '../../Hooks/usePhoneFormat'
import moment from 'moment'
import {isMobile} from 'react-device-detect';
 
export default function Contract({order, merchantCopy}) {
	
	let phone_number = usePhoneFormat(order.phone_number)
	if (isMobile) return null
	return (
		<div className='w-full font-comic p-4' style={{pageBreakBefore: "always"}}>
			<div className="text-center mb-5">
				<div className="text-3xl mt-6">JNB JUMPS</div>
				<div>
					<div className="text-lg">CHILDREN'S INFLATABLE RENTAL AGREEMENT</div>
					<div className="mt-4 text-md">
						<div>Serving Tracy and Surrounding Areas Since 1998</div>
						<div>(209) 830-7588</div>
					</div>
				</div>
			</div>
			<div className='grid grid-cols-2 m-5 text-sm'>
				<div>
					<div>Lessee: {order.first_name} {order.last_name}</div>
					<div>Address: {order.address} {order.city}</div>
					<div>Phone Number: {phone_number || order.phone_number}</div>
				</div>
				<div className="text-right pr-5">
					<div>Date: {moment(order.rental_date).format("MMMM Do YYYY")}</div>
					<div>Time: {moment(`${order.rental_date}T${order.rental_time}`).format("LT")}</div>
				</div>
			</div>
			<div className='m-5'>
				<div className="text-lg">General rules for safe operation:</div>
				<ul className='ml-5' style={{fontSize: "10px"}}>
					<li>MUST be supervised by the lessee or his/her authorized representative(s).</li>
					<li>NO flips, rough play or climbing on net walls.</li>
					<li>NO shoes, jewelry and eyeglasses in the inflatable.</li>
					<li>NO smoking or sharp objects in or around the inflatable.</li>
					<li>NO food, drinks or gum in or around the inflatable.</li>
					<li>NO SILLY STRING or CONFETTI around or near the inflatable (Cleaning charge may apply)__________</li>
					<li>DO NOT mix age groups. Same age groups must play at the same time.</li>
					<li>DO NOT exceed the recommended capacity.</li>
					<li>Turn OFF Automatic sprinklers. (Cleaning charge may apply if unit is wet)__________</li>
				</ul>
			</div>
			
			<div className='m-5'>
				<div>
					<div className='text-lg'>Capacity:</div>
					<div className="pl-3 text-xs" style={{fontSize: "10px"}}>(any given time): 5 years and under (9), or, 6-8 years (6), or, 9-13 years (4)</div>
				</div>

				<div className='mt-5'>
					<div className="text-lg">The lessee also agrees to the following:</div>
					<ol  className="ml-5 text-xs" style={{fontSize: "10px"}}>
						<li>1. The equipment will not be subjected to any unnecessary rough usage, and shall conform to all applicable laws, city/county ordinances and other lawful regulations.</li>
						<li>2. LESSEE agrees not to use the equipment for illegal purposes.</li>
						<li>3. The equipment should be used by duly qualified employees, guest and or agents of lessee, and shall not be sublet or assigned.</li>
						<li>4. If any equipment is returned in a damaged or destroyed condition or is not returnable by reason of carelessness, destruction, confiscation, theft, or for any reason while the equipment is in the posession of the LESSEE, the latter shall pay the full monetary value, based on the manufacturer’s current list price of such items in addition to accrued rental/s.</li>
						<li>5. The LESSEE agrees to return the equipment in the same condition in which it was received save only for normal use and wear, but will be responsible for the cost of necessary repairs of damage beyond this normal wear and use.</li>
						<li>6. I understand and acknowledge that the activity to be engaged in through my rental of an inflatable, interactive amusement device brings with it both known and unknown and unanticipated risks to myself, my guests and invites. Those risks include, but are not limited to fallings, slipping, crashing and colliding and could result in injury, illness, disease, emotional distress, death and/or property damage to myself or my guests and invitees.</li>
						<li>7. LESEE or any of his/ her agent, guest or employee agrees to indemnify and hold LESSOR harmless from any and all claims, actions, suits, proceedings, cost, expenses, damages, and liability including Attorney fees, fines, and penalties arising out of, connected with, or resulting from the use of the equipment including without limitation, the manufacturer, delivery, possession, use operation, conduct or return of said equipment.</li>
						<li>8. LESSEE agrees not to hold LESSOR responsible for any unfavorable weather conditions, high winds exceeding 25 MPH, rain, or any other unexpected conditions that may arise during all the time that the LESSEE is in possession or using the equipment.</li>
					</ol>
				</div>	
			</div>
			<div className="grid grid-cols-2-1 mt-15">
				<div className="grid grid-cols-2 pl-5 text-xs">
					{order && order.order_packages.map((pack, index) => {
						return (
							<div key={`contract-pack-${pack.name}-${index}`} className="pb-2">
								<div className="">{pack.name} - ${pack.price}</div>
								{pack.order_package_items.map((item, index) => {
									return (<div key={`contract-packItem-${item.name}-${index}`} className="pl-4">{item.name}</div>)
								})}
							</div>
						)
					})}
					{order && order.order_items.map((item, index) => {
						return (
							<div key={`contract-item-${item.name}-${index}`} >{item.name} {item.price ? `- ${item.price}` : "" }</div>
						)
					})}
				</div>
				
				<div className="text-right pr-5">
					{merchantCopy && <div className="">_______________________________</div>}
					{merchantCopy && <div className=""> SIGNATURE (Lessee)</div>}
					<div className="font-bold">Total: ${order.price}</div>
					<div className='text-xs' style={{fontSize: "8px"}}>(Please note there will be $10.00 convenience fee for M/C, Visa & Amex)</div>
				</div>
			</div>
			
		</div>
	)

}
