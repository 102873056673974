import React, {useEffect, useState, createContext} from 'react'
import config from './Configuration'
import LandingPage from './Pages/LandingPage'
import ViewOrder from './Pages/ViewOrder'
import NewOrder from './Pages/NewOrder'
import EditOrder from './Pages/EditOrder'

import CreateRoute from './Pages/CreateRoute'
import Inventory from './Pages/Inventory'
import Analytics from './Pages/Analytics'
import SearchDate from './Pages/SearchDate'
import SearchText from './Pages/SearchText'
import Authentication from './Pages/Authentication'
import Settings from './Pages/Settings'
import EContract from './Pages/EContract'


import {default as SettingsApi} from './Api/Settings'

import Customer from './Pages/Customer'
import axios from 'axios';

import {ApplicationSettingsProvider} from './Context/ApplicationSettings'

import Users from './Api/Users';

import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";


axios.defaults.withCredentials = true



function App() {


  let [user, setUser] = useState(false);
  let [applicationSettings, setApplicationSettings] = useState({toolTips: true})

  useEffect(function() {
    Users.getCurrent().then(data => {
      setUser(data.user);
       SettingsApi.getOne(-1).then(data => {
        if (data.code === 1) {
          setApplicationSettings(data.settings);
        }
      })
    }).catch(err => {
      setUser(false);
    })
   

  }, [])



  if (user && user.id > 0) {
     return (
      <Router>
        <div>
          <Switch>
            <Route exact path="/">
              <LandingPage setUser = {setUser}/>
            </Route>

            <Route path="/order/:id">
              <ViewOrder />
            </Route>

            <Route path="/newOrder/:id?">
              <ApplicationSettingsProvider value={{applicationSettings}} >
                 <NewOrder />
              </ApplicationSettingsProvider>
               
            </Route>

            <Route path="/editOrder/:orderId">
              <ApplicationSettingsProvider value={{applicationSettings}} >
                 <EditOrder />
              </ApplicationSettingsProvider>
               
            </Route>

            <Route path="/createRoute/:search">
              <CreateRoute />
            </Route>
         
            <Route path="/inventory">
              <ApplicationSettingsProvider value={{applicationSettings}} >
                <Inventory />
              </ApplicationSettingsProvider>
            </Route>

            <Route path="/analytics">
               <Analytics />
            </Route>

            <Route path="/search/date/:search?/:searchEnd?">
              <SearchDate />
            </Route>


            <Route path="/search/text/:search?">
              <SearchText />
            </Route>
         
            <Route path="/customer/:id">
               <Customer />
            </Route>

            <Route path="/contract/:id">
               <EContract />
            </Route>

            <Route path="/settings">
              <ApplicationSettingsProvider value={{applicationSettings, setApplicationSettings}} >
                <Settings />
              </ApplicationSettingsProvider>
            </Route>


          </Switch>
        </div>
      </Router>
     )
  } else {
    return (
        <Router>
          <Switch>
             <Authentication user={user} setUser={setUser} />
          </Switch>
        </Router>
      )
         
    
  }

  
}

export default App;

