import React , {useState, useEffect} from 'react'
import classNames from '../../../_ClassNames'
import OrderPaymentType from '../../../Api/OrderPaymentType'

export default function PayType({order, paymentTypes}) {
	return (
		<div onClick={(e) => {e.preventDefault()}}>
			<hr/>
			
			<div className="p-4 w-full grid grid-cols-3 gap-1 md:flex md:flex-row lg:flex lg:flex-row xl:flex xl:flex-row">
				{paymentTypes && paymentTypes.map((type, index) => {
					return (
						<PaymentCheckbox key={`${type.id}-${order.id}`} order={order} type={type.name} id={type.id} />
					)
				})}
			</div>
		</div>
	)
}


function PaymentCheckbox({order, type, id}) {
	const [checked, setChecked] = useState(false);
	const [orderPaymentTypeId, setOrderPaymentTypeId] = useState(false)

	useEffect(function() {
		if (order && order.order_payment_types.length) {
			for (let orderPaymentType of order.order_payment_types) {
				if (!orderPaymentType.payment_type || !orderPaymentType.payment_type.id) continue
				if (orderPaymentType.payment_type.id === id) {
					setChecked(true)
					setOrderPaymentTypeId(orderPaymentType.id)
				}

			}

		}
	}, [order])

	return (
		<div className="text-left" onClick={(e) => {
				e.preventDefault();
				setChecked(!checked)
				if (!checked === true) {
					OrderPaymentType.create({paymentTypeId: id, orderId: order.id}).then(data => {
						if (data.code === 1) {

						} else {
							console.log(data.message);
						}
					})
				} else {
					if (orderPaymentTypeId) {
						OrderPaymentType.destroy(order.id, id).then(data => {
							if (data.code === 1) {

							} else {
								console.log(data.message)
							}
						})	
					}
					
				}
				


			}}>
			<div className={`${classNames.payment_type_button} text-xs ${checked ? "bg-green-100" : ""}`}>{type}</div>
		</div>

	)

}

