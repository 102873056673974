import React, {useState, useEffect} from 'react'

let classNames = {
  input: 'appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500',
  label: "block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2",
  customer_button: "w-full mt-3 bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow text-left",
}


export default function SearchCustomers({customers, search, onClick, onFindEffect}) {

  let [existing_customers, set_existing_customers] = useState([]);
  search = search.toLowerCase();

  useEffect(function() {
    function findMatches() {
      if (!search || !customers) return false

      let matches = [];
      for (let customer of customers) {

        if (customer.first_name && customer.first_name.toLowerCase().indexOf(search) !== -1) {
          matches.push(customer)
          continue
        }

        if (customer.last_name && customer.last_name.toLowerCase().indexOf(search) !== -1) {
          matches.push(customer)
          continue
        }

        if (customer.phone_number && customer.phone_number.indexOf(search) !== -1) {
          matches.push(customer)
          continue
        }

        if (customer.email && customer.email.toLowerCase().indexOf(search) !== -1) {
          matches.push(customer)
          continue
        }

        if (customer.address && customer.address.toLowerCase().indexOf(search) !== -1) {
          matches.push(customer);
          continue;
        }
      }

      set_existing_customers(matches);
      if(onFindEffect) {
        onFindEffect(matches);
      }

    }
    findMatches(search);
  }, [search, customers])

  return (
    <div className="pr-4 gap-2 grid xs:grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-1">
      {existing_customers && existing_customers.map((customer, index) => {
        return (
          <div className="existing-customer">
            <button className={classNames.customer_button} onClick={() => onClick(customer)}>
              <div>{customer.first_name} {customer.last_name}</div>
              <div>{customer.phone_number}</div>
              <div>{customer.address}</div>
              <div>Orders: {customer.orders.length}</div>
            </button>
          </div>
        )
      })}
    </div>
  )
}

