import React, {useState, useEffect} from 'react'

import OrderItems from '../../../Components/Orders/OrderItems'
import ShowCart from '../../../Components/Orders/ShowCart'

import PageSkeleton from '../../../Components/PageSkeleton'
import CategoryList from '../../../Components/SidebarLists/CategoryList.js'
import {BiCart, BiCategoryAlt} from 'react-icons/bi'
import { IconContext } from "react-icons";

import classNames from '../../../_ClassNames'

export default function Items(props) {
		let {items, setItems, categories} = props
		const [isPackage, setIsPackage] = useState(false)
		const [searchItems, setSearchItems] = useState(items)
		const [search, setSearch] = useState("");
		const [isPreviousOrder, setIsPreviousOrder] = useState(false)
		const [previousOrderitems, setPreviousOrderItems] = useState([])
		const [previousOrderPackages, setPreviousOrderPackages] = useState([])

		useEffect(function() {
                  if (items) {
                    setSearchItems(items);
                  }
		}, [items])

		useEffect(function() {
                  if (search) {
                    let _searchItems = [];
                    for (let category of categories) {
                      let _items = category.items.filter(item => item.name.toLowerCase().indexOf(search.toLowerCase()) > -1)
                      _searchItems = [..._searchItems, ..._items]
                    }
                    setSearchItems(_searchItems);
                  }
		}, [search])
		return (
			<PageSkeleton
	        	Main={
	        		<>
		        		<div className="ml-5 mt-2 w-full md:w-1/2 px-3 mb-6 md:mb-0">
					      <label className={classNames.label} for="grid-first-name">
					       	Search
					      </label>
					      <input className={classNames.input} value={search} onChange={(e) =>{
					      	setSearch(e.target.value)
					      }}/>
					    </div>

		        		<OrderItems 
		        			{...props}
		        			items={search.length > 0 ? searchItems : items}
		        			isPackage={isPackage} 
		        			isPreviousOrder={isPreviousOrder}
							previousOrderItems={previousOrderitems}
							previousOrderPackages={previousOrderPackages}
							removeBackButton={true}
							skipGrid={search.length > 0}

						/>
					</>
				}
	        	Rightnav={<ShowCart {...props} />}
	        	Leftnav={
	        			<CategoryList 
	        				{...props}
	        				onBackButtonClick={() => {
	        					props.set_page(0)
	        				}}
	        				onPackageClick={(packages) => {
	        					setIsPackage(true);
	        					setIsPreviousOrder(false)
	        				}} 

	        				onCategoryClick={(items) => {
	        					setIsPackage(false);
	        					setIsPreviousOrder(false);
	        				}}
	        				onPreviousOrderClick={(order) => {
	        					setIsPackage(false)
	        					setIsPreviousOrder(true)

	        					setPreviousOrderItems(order.order_items)
	        					setPreviousOrderPackages(order.order_packages);
	        				}}

	        			/>
	        	}
	        	rightToggleIcon={<IconContext.Provider value={{ color: "blue", size: "2em" }}><div className="flex flex-row"><BiCart /><div className="">({props.customer_items.length})</div></div></IconContext.Provider>}
	        	leftToggleIcon={<IconContext.Provider value={{ color: "blue", size: "2em" }}><div className="flex flex-row"><BiCategoryAlt /></div></IconContext.Provider>}
				omitSearch={true}
	        	nav={{leftOpen: true, rightOpen: window.innerWidth < 940 ? false : true}}
	        />

		)
	
}

