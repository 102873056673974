 
 	import axios from "axios";
 	import config from "../../Configuration";

 	const routePrefix = `${config.getHost()}/orders`
	 
 export default {

		async test() {
			let res = await axios.get(`${routePrefix}/test`).catch(err => console.log(err))
			return res.data;
		},
		async getOne(id) {
			let res = await axios.get(`${routePrefix}/${id}`).catch(err => console.log(err))
			return res.data;
		},
		async getAll() {
			let res = await axios.get(`${routePrefix}`).catch(err => console.log(err))
			return res.data;
		},
		async count() {
			let res = await axios.get(`${routePrefix}/count/all`).catch(err => console.log(err))
			return res.data;
		},
		async create(data) {
			let res = await axios.post(`${routePrefix}`, data).catch(err => console.log(err))
			return res.data
		},

		async saveContract(data, id) {
			let res = await axios.put(`${routePrefix}/contract/${id}`, data).catch(err => console.log(err))
			return res.data
		},

		async sendContract(id, email) {
			let res = await axios.post(`${routePrefix}/sendContract`, {id: id, email:email}).catch(err => console.log(err))
			return res.data
		},

		async update(id, data) {
			let res = await axios.put(`${routePrefix}/${id}`, data).catch(err => console.log(err))
			return res.data
		},

		async updateWithOrderItems(id, data) {
			let res = await axios.put(`${routePrefix}/updateWithOrderItem/${id}`, data).catch(err => console.log(err))
			return res.data
		},

		async destroy(id) {
			let res = await axios.delete(`${routePrefix}/${id}`).catch(err => console.log(err))
			return res.data;
		}, 
		

	
		async getByDate(date) {
			let res = await axios.get(`${routePrefix}/date/${date}`).catch(err => console.log(err))
			return res.data;
		},
		
		async getWithDateRange(from, to) {
			let res = await axios.get(`${routePrefix}/date/${from}/${to}`).catch(err => console.log(err))
			return res.data;
		},
		
		async getCancellations(from, to) {
			let res = await axios.get(`${routePrefix}/cancellations/${from}/${to}`).catch(err => console.log(err))
			
			return res.data;
		},


		async getCustomersOrderTotal(customerId) {
			let res = await axios.get(`${routePrefix}/total/customer/${customerId}`).catch(err => console.log(err))
			return res.data;
		},
		

		async getOrdersWithOrderItems(id) {
			let res = await axios.get(`${routePrefix}/${id}/orderItems`).catch(err => console.log(err))
			return res.data;
		},

		async createOrdersWithOrderItems(data) {
			let res = await axios.post(`${routePrefix}/orderItems`, data).catch(err => console.log(err))
			return res.data;
		},

		async getOrderItemsWithAllAssociations(id) {
			let res = await axios.get(`${routePrefix}/${id}/orderItems/all/associations`).catch(err => console.log(err))
			return res.data;
		},


	
	async getWithAllAssociations(page, limit) {
		let res = await axios.get(`${routePrefix}/all/associations/${page}/${limit}`).catch(err => console.log(err))
		return res.data;
	},
	async getOneWithAllAssociations(id) {
		let res = await axios.get(`${routePrefix}/${id}/all/associations`).catch(err => console.log(err))
		return res.data;
	},




}
	
