import React , {useState, useEffect} from 'react'
import OrderList from '../../Components/OrderList'
import QuickDateList from '../../Components/SidebarLists/QuickDateList.js'
import PageNameList from '../../Components/SidebarLists/PageNameList.js'

import PageSkeleton from '../../Components/PageSkeleton'
import { useHistory } from "react-router-dom";
import Orders from '../../Api/Orders'

export default function LandingPage({setUser}) {
	let history = useHistory();
    let [page, setPage] = useState(0);
    let [limit, setLimit] = useState(20)

    let [orders, setOrders] = useState([])
    let [shownOrders, setShownOrders] = useState("orders");

    useEffect(function() {
        Orders.getWithAllAssociations(page, limit).then(data => {
            let _orders = [...orders, ...data.orders]
            setOrders(_orders);
        })
    }, [page, limit])

    

    return (
	<>
	     <PageSkeleton 
            	Main={<OrderList orders={orders} setOrders={setOrders} showMap={false} shownOrders={shownOrders}/> }

            	Rightnav={<QuickDateList onClick={(day) => {
            		history.push(`/search/date/${day.day}`)
            	}}/>}

            	Leftnav={
                    <div className="pt-5 pb-5">
                        <ul className='space-y-4 p-5'>
                            <li className="border-b-2">
                                <button className="w-full p-2 text-left" onClick={() => {
                                    setShownOrders("orders")
                                }}>Current Orders</button>
                            </li>
                            <li className="border-b-2">
                                <button className="w-full p-2 text-left" onClick={() => {
                                    setShownOrders("canceled")
                                }}>Canceled Orders</button>
                            </li>
                             <li className="border-b-2">
                                <button className="w-full p-2 text-left" onClick={() => {
                                    setShownOrders("all")
                                }}>All Orders</button>
                            </li>
                        </ul>
                        <PageNameList setUser={setUser}/>
                    </div>}
            	nav={{
	        		leftOpen: window.innerWidth > 648 ? true : false,
	        		rightOpen: false,
	        	}}
                onScrollBottom={() => {
                    setPage(page + 1);
                }}
            />
	</>
    )

}
