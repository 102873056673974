import React from 'react';
import MapRoute from '../../../Components/Maps/MapRoute.js'
import SortableLoadList from '../../../Components/Deliveries/SortableLoadList.js'
import PrintableLoadList from '../../../Components/Deliveries/PrintableLoadList.js'

export default function Main({customers, setCustomers, printRef, loadList, setLoadList, employees, setEmployees, currentMap, setCurrentMap}) {
	if (!customers) return null;

	return (
		<>
			<MapRoute customers={currentMap === -1 ? customers : employees[currentMap].customers} />
			<SortableLoadList 
				customers={customers} 
				setCustomers={setCustomers} 
				loadList={loadList} 
				setLoadList={setLoadList} 
				employees={employees} 
				setEmployees={setEmployees}
				currentMap={currentMap}
				setCurrentMap={setCurrentMap}
			/>
			<div ref={printRef}>
				{customers.length > 0 && <PrintableLoadList customers={customers} title={"Main List"}/>}
				{employees && employees.map((employee, index) => {
					return (employee.customers && employee.customers.length > 0 && <PrintableLoadList customers={employee.customers} title={`${employee.first_name} ${employee.last_name}`}/>)
				})}
			</div>
		</>

	)
}