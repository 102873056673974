import React, {useState, useEffect} from 'react'
import moment from 'moment'
import usePhoneFormat from '../../Hooks/usePhoneFormat'
import ClassNames from "../../_ClassNames"
import Orders from '../../Api/Orders';
export default function OrderInformation({order}) {

	let phone_number = usePhoneFormat(order.phone_number);
	const [time, set_time] = useState(order.pickup_time || "18:00:00");

	useEffect(function() {
		if (order.pickup_time) {
			set_time(order.pickup_time)
		}
	}, [order])
			
	return (
		<div className="grid grid-cols-2 md:grid md:grid-cols-3 lg:grid lg:grid-cols-3 xl:grid xl:grid-cols-3 mt-10">
			<div className="p-5">
				<div className="md:pl-10 lg:pl-10 xl:pl-10 2xl:pl-10">{order.rental_date}</div>
				<div className="md:pl-12 lg:pl-12 xl:pl-12 2xl:pl-12 pt-5">
					<div>{order.address} {order.city}</div>
					<div>{order.first_name} {order.last_name}</div>
					<div>{phone_number || order.phone_number}</div>

					<div className="mt-10 pt-5 pb-5">
						<div className={ClassNames.label}>Delivery Time: {moment(`${order.rental_date}T${order.rental_time}`).format("LT")}</div>
						<div className="sm:w-full md:w-full lg:w-1/2 xl:w-1/2 2xl:w-1/2">
					      <label className={ClassNames.label} htmlFor="grid-city">
					        Pickup Time:
					      </label>
					      <input type='time' className={`${ClassNames.input} `} value={time} onChange={(e) => {
					      	set_time(e.target.value)
					      	Orders.update(order.id, {pickup_time: e.target.value}).then(data => { 		
					      	})
					      }}/>
						</div>
					</div>

					<div className="pt-10">	
						<div>Notes: {order.notes}</div>
					</div>
				</div>
			</div>

			<div className="m-auto mt-0  p-5">
				{order && order.order_packages.map((pack, index) => {
					return (
						<div key={`package-${pack.name}-${index}`} className="pb-2">
							<div className="">{pack.name} - ${pack.price}</div>
							{pack.order_package_items.map((item, index) => {
								return (<div key={`packageItem-${item.name}-${index}`} className="pl-4">{item.name}</div>)
							})}
						</div>
					)
				})}
				{order && order.order_items.map((item, index) => {
					return (
						// <div className="flex flex-row"><div>{item.name}</div> <div>-${item.price}</div></div>
						<div key={`item-${item.id}-${index}`}>{item.name}</div>
					)
				})}

				<div className='pt-10'>
					<div className=''>
						{order.discount > 0 && <div>Discount: ${order.discount}</div>}
						<div className="font-bold">Total: ${order.price}</div>
					</div>
				</div>
				
			</div>



			
		</div>
	)
}