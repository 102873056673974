import React, {useContext} from 'react'
import {AiOutlineCloseCircle} from 'react-icons/ai'
import Categories from "../../Api/Categories";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import DraggableCategories from './DraggableCategories'
import { IconContext } from "react-icons";
import ApplicationSettingsContext from '../../Context/ApplicationSettings'


export default function ModifierCategories(props) {
	const {modifierCategories, onDelete, onClick} = props
	let {applicationSettings : settings, setApplicationSettings} = useContext(ApplicationSettingsContext);


	return (
				


		<ul className='space-y-4 p-5'>
			{modifierCategories.map((modifier, index) => {
				return (
					<li key={`${modifier.name}-index`} className="border-b-2 text-left flex flex-row">
						<div className="w-full p-2 text-left cursor-pointer"  onClick={() => (onClick && onClick(modifier, index))} >
		      				{modifier.name}
		      			</div>
		      			{onDelete && 
		      				<div className="w-full flex justify-end cursor-pointer">
			      				<IconContext.Provider value={{ color: "red", size: "1.6em", className: "global-class-name" }}>
			      					<AiOutlineCloseCircle onClick={() => (onDelete && onDelete(modifier))}/>
			      				</IconContext.Provider>	
		      			</div>
		      			}

		    		</li>
				)
			})}
		</ul>

	)
}


