import React, {useState, useEffect} from 'react'
import Geocode from "react-geocode";
import swal from 'sweetalert';
import SearchCustomers from '../Search/SearchCustomers'
import { usePlacesWidget } from "react-google-autocomplete";

Geocode.setApiKey("AIzaSyBsXn4UceQN0y8zdryVqEGGhNJXV6t02DU");

export default function AddCustomer(props) {

	let {
			first_name,
			set_first_name,
			last_name,
			set_last_name,
			date,
			set_date,
			time,
			set_time,
			address,
			set_address,
			latitude,
			setLatitude,
			longitude,
			setLongitude,
			city,
			set_city,
			phone,
			set_phone,
			email,
			set_email,
			notes,
			set_notes,
			button_title,
			onClick,
		} = props

		const { ref, autocompleteRef } = usePlacesWidget({
		    apiKey: "AIzaSyBsXn4UceQN0y8zdryVqEGGhNJXV6t02DU",
		    onPlaceSelected: (place) => {
                      let _street_number = "";
                      let _street_name = "";
                      let _city = ""

                      if (place.geometry.location) {
                        setLatitude(place.geometry.location.lat());
                        setLongitude(place.geometry.location.lng());
                      }

                      for (let component of place.address_components) {

                        for (let type of component.types) {
                          if (type === "street_number") {
                            _street_number = component.long_name;
                          }

                          if (type === "route") {
                            _street_name = component.long_name;
                          }

                          if (type === "locality") {
                            _city = component.long_name;
                          }
                        }
                      }
                      set_address(`${_street_number} ${_street_name}`);
                      set_city(_city);

                    },
                    options: {
                      types: [],
                      componentRestrictions: { country: "us" },
                    }
                });

	let classNames = {
		input: 'appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500',
		label: "block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2",
		customer_button: "w-full mt-3 bg-green-100 hover:bg-green-300 text-gray-800 font-semibold py-2 px-4 border border-green-100 rounded shadow text-left",
	}


	return (
		<>
		<div className='add-customer-form-wrapper w-full m-auto p-5 lg:p-0 xl:p-0 2xl:p-0'>

			<div className="w-full max-w-lg ml-auto mr-auto mt-10">	  
			  <div className="flex flex-wrap -mx-3 mb-6">
			    <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
			      <label className={classNames.label} for="grid-first-name">
			        First Name
			      </label>
			      <input className={classNames.input} value={first_name} onChange={(e) =>{
			      	set_first_name(e.target.value)
			      }}/>
			    </div>
			    <div className="w-full md:w-1/2 px-3">
			      <label className={classNames.label} for="grid-last-name">
			        Last Name
			      </label>
			      <input className={classNames.input} value={last_name} id="grid-last-name" type="text" placeholder="last name"  onChange={(e) =>{
			      	set_last_name(e.target.value)
			      }}/>
			    </div>
			  </div>



			  <div className="flex flex-wrap -mx-3 mb-2">
			    <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
			      <label className={classNames.label} for="grid-city">
			        Date
			      </label>
			      <input type='date' className={classNames.input} value={date} onChange={(e) => {
				      	set_date(e.target.value)
				      }}
			      />
			    </div>
			
			    <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
			      <label className={classNames.label} for="grid-city">
			        Time
			      </label>
			      <input type='time' className={classNames.input} value={time} onChange={(e) => {
			      	set_time(e.target.value)
			      }}/>
			    </div>
			  </div>




			  <div className="flex flex-wrap -mx-3 mb-2">
			    <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
			      <label className={classNames.label} for="grid-city">
			        Address
			      </label>
			      <input ref={ref} className={classNames.input} value={address} onChange={(e) =>{
			      		set_address(e.target.value)
			      }}/>
			    </div>
			    <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
			      <label className={classNames.label} for="grid-city">
			        City
			      </label>
			      <input className={classNames.input} value={city} onChange={(e) =>{
			      		set_city(e.target.value)
			      }}/>
			    </div>
			  </div>


			  <div className="flex flex-wrap -mx-3 mb-2">
			    <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
			      <label className={classNames.label} for="grid-city">
			        Phone
			      </label>
			      <input className={classNames.input} value={phone} onChange={(e) =>{
			      		set_phone(e.target.value)
			      }}/>
			    </div>

			    <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
			      <label className={classNames.label} for="grid-city">
			        Email
			      </label>
			      <input className={classNames.input} value={email} onChange={(e) =>{
			      		set_email(e.target.value)
			      }}/>
			    </div>
			  </div>


			  <div className="flex flex-wrap -mx-3 mb-2">
			    <div className="w-full md:w-full px-3 mb-6 md:mb-0">
			      <label className={classNames.label} for="grid-city">
			        notes
			      </label>
			      <textarea rows="5" className={classNames.input} value={notes} onChange={(e) =>{set_notes(e.target.value)}}/>
			    </div>
			  </div>

			  <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
				    <button className={`${classNames.customer_button} text-center`} onClick={() => {
                                      Geocode.fromAddress(address).then(
                                        (response) => {

                                          onClick();

                                        },
                                        (error) => {
                                          swal({
                                            title: "Can't find address with google",
                                            text: "Continue Anyways?",
                                            icon: "warning",
                                            buttons: true,
                                            dangerMode: true,
                                          })
                                            .then((ok) => {
                                              if (ok) {
                                                if(onClick) {
                                                  onClick();
                                                }
                                              } else {

                                              }
                                            });
                                        }
                                      );

                                    }}>
                                      {button_title}
                                    </button>
			    </div>



			   <div className="flex flex-wrap -mx-3 mb-2 h-32">
			    <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
			      
			    </div>

			    
			  </div>

			</div>
			


			
		</div>
		</>


	)
}
