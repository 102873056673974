import React , {useState, useEffect} from 'react'
import { Link } from "react-router-dom";
import moment from 'moment'

import Customers from '../../../Api/Customers'

import OrderItemGrid from '../../../Components/Inventory/OrderItemGrid.js'
import OrderPackageGrid from '../../../Components/Inventory/OrderPackageGrid.js'

import ViewCustomer from '../../../Components/Customers/Customer.js'
import useCurrentWidth from '../../../Hooks/useResize';

export default function Main({id, customer, totalPrice}) {
    
    let [page, setPage] = useState("info");
    let width = useCurrentWidth();
    let pages = {
        info: <CustomerInformation id={id} customer={customer} totalPrice={totalPrice} />,
        orders: <OrderInformation customer={customer} />
    }


    if (width < 768) {
        return (
            <div className="p-4 ">
                <div className="flex flex-row justify-center align-center">
                    <button 
                        className={`p-3 border-2 text-sm font-bold w-64 m-2 h-12 rounded-md ${page === "info" ? "border-green-300 bg-green-300 hover:bg-green-300" : "border-gray-100 bg-gray-100 hover:bg-gray-300"}`} 
                        onClick={() => setPage("info")}>
                    Info</button>
                    <button 
                        className={`p-3 border-2 text-sm font-bold w-64 m-2 h-12 rounded-md ${page === "orders" ? "border-green-300 bg-green-300 hover:bg-green-300" : "border-gray-100 bg-gray-100 hover:bg-gray-300"}`} 
                        onClick={() => setPage("orders")}>
                    Orders</button>
                </div>
                <div style={{margin:"auto"}}>
                    {pages[page]}
                </div>
               
            </div>

        )
    } else {
        return (
            <div className="grid grid-cols-2-1">
                {pages.info}
                {pages.orders}
            </div>

        )
    }

    
}

function CustomerInformation({id, customer, totalPrice}) {
    
    let [first_name, set_first_name] = useState(customer.first_name)
    let [last_name, set_last_name] = useState(customer.last_name)
    let [address, set_address] = useState(customer.address)
    let [city, set_city] = useState(customer.city)
    let [phone, set_phone] = useState(customer.phone_number)
    let [email, set_email] = useState(customer.email)
    let [notes, set_notes] = useState(customer.notes)
    
    useEffect(function() {
        set_first_name(customer.first_name)
        set_last_name(customer.last_name)
        set_address(customer.address)
        set_city(customer.city)
        set_phone(customer.phone_number)
        set_email(customer.email)
        set_notes(customer.notes)

    }, [customer])

    return (
           <div className="w-full p-5">
                <div className='text-lg bold underline mb-5'>{first_name && first_name} {last_name && last_name}</div>
                <div> Total Collected: ${totalPrice}</div>

                <ViewCustomer 
                    first_name={first_name}
                    set_first_name={set_first_name}
                    last_name={last_name}
                    set_last_name={set_last_name}
                    address={address}
                    set_address={set_address}
                    city={city}
                    set_city={set_city}
                    phone={phone}
                    set_phone={set_phone}
                    email={email}
                    set_email={set_email}
                    notes={notes}
                    set_notes={set_notes}
                    onClick={() => {
                        Customers.update(customer.id, {
                            first_name: first_name,
                            last_name: last_name,
                            address: address,
                            city:city,
                            phone_number: phone,
                            email: email,
                            notes: notes,
                        }).then(data => {

                        })
                    }}
                />
            </div>
    )
}

function OrderInformation({customer})  {
    let [view, setView] = useState("orders");


    return (
         <div className="overflow-auto" style={{height: "calc(100vh - 11vh)"}}>
            <div className="flex flex-row justify-evenly p-3 m-1">
                <button className={`p-3 border-2 text-sm font-bold w-64 m-2 h-12 rounded-md ${view === "orders" ? "border-green-300 bg-green-300 hover:bg-green-300" : "border-gray-100 bg-gray-100 hover:bg-gray-300"}`} onClick={() => setView("orders") }>Orders</button>
                <button className={`p-3 border-2 text-sm font-bold w-64 m-2 h-12 rounded-md ${view === "canceled" ? "border-green-300 bg-green-300 hover:bg-green-300" : "border-gray-100 bg-gray-100 hover:bg-gray-300"}`} onClick={() => setView("canceled") }>Canceled</button>
                <button className={`p-3 border-2 text-sm font-bold w-64 m-2 h-12 rounded-md ${view === "all" ? "border-green-300 bg-green-300 hover:bg-green-300" : "border-gray-100 bg-gray-100 hover:bg-gray-300"}`} onClick={() => setView("all") }>All</button>


            </div>
                {customer.orders && 
                    customer.orders.map((order, index) => {
                        if (view === "orders" && order.canceled === false) {
                            return (
                            <div className="p-4 border-b-2">
                                <div className="mb-4">{moment(order.rental_date).format("MMM Do YYYY")}</div>
                                <div className="p-3">
                                    <div className="pt-5 pb-5 mb-5">
                                        <Link to={`/order/${order.id}`} className="p-3 border-2 text-xs rounded-md border-green-100 bg-green-100 hover:bg-green-300">View Order</Link>
                                    </div>
                                    <OrderPackageGrid order_packages={order.order_packages} onClick={(item) => console.log(item)}/>
                                    <OrderItemGrid items={order.order_items} onClick={(item) => console.log(item)}/>
                                    <div className="text-right pr-6 mt-3">Total: ${order.price}</div>
                                </div>
                            </div>
                            )
                        }

                        if (view === "canceled" && order.canceled === true) {
                            return (
                            <div className="p-4 border-b-2">
                                <div className="mb-4">{moment(order.rental_date).format("MMM Do YYYY")}</div>
                                <div className="p-3">
                                    <div className="pt-5 pb-5 mb-5">
                                        <Link to={`/order/${order.id}`} className="p-3 border-2 text-xs rounded-md border-green-100 bg-green-100 hover:bg-green-300">View Order</Link>
                                    </div>
                                    <OrderPackageGrid order_packages={order.order_packages} onClick={(item) => console.log(item)}/>
                                    <OrderItemGrid items={order.order_items} onClick={(item) => console.log(item)}/>
                                    <div className="text-right pr-6 mt-3">Total: ${order.price}</div>
                                </div>
                            </div>
                            )
                        }

                        if (view === "all") {
                            return (
                            <div className="p-4 border-b-2">
                                <div className="mb-4">{moment(order.rental_date).format("MMM Do YYYY")}</div>
                                <div className="p-3">
                                    <div className="pt-5 pb-5 mb-5">
                                        <Link to={`/order/${order.id}`} className="p-3 border-2 text-xs rounded-md border-green-100 bg-green-100 hover:bg-green-300">View Order</Link>
                                    </div>
                                    <OrderPackageGrid order_packages={order.order_packages} onClick={(item) => console.log(item)}/>
                                    <OrderItemGrid items={order.order_items} onClick={(item) => console.log(item)}/>
                                    <div className="text-right pr-6 mt-3">Total: ${order.price}</div>
                                </div>
                            </div>
                            )
                        }

                        
                    })
                }
            </div>
    )
}

