import React, {useState, useEffect} from 'react'

import swal from 'sweetalert'
import {Link} from 'react-router-dom'


import MapView from '../Maps/MapView'

import PaymentTypes from '../../Api/PaymentTypes'
import Orders from '../../Api/Orders'

import CustomerInformation from './CustomerInformation'
import OrderCancelButtons from './OrderCancelButtons'
import ShowItems from './ShowItems'
import PayType from './PayType'

import {AiOutlineDelete} from 'react-icons/ai'

import classNames from '../../_ClassNames'

export default function OrderList({orders, setOrders, showMap, shownOrders}) {
	let [paymentTypes, setPaymentTypes] = useState([])
	useEffect(function() {
        PaymentTypes.getAll().then(data=> {
            setPaymentTypes(data.payment_types)
        })
    }, [])

	if (!orders) {
		return null
	}

    return (
    <div>
		<div className='customer-list-wrapper w-full min-h-full p-5 gap-2 grid xs:grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2'>
		    {orders &&
				orders.flatMap((order, i) => {
					if (shownOrders === "orders" && order.canceled === false) {
                                          return (
                                            <OrderCard key={`ordercard-${i}`}  setOrders={setOrders} orderData={order} paymentTypes={paymentTypes} showMap={showMap} />
                                          )
					}
					else if (shownOrders === "canceled" && order.canceled === true) {
						return (
                                                  <OrderCard key={i}  setOrders={setOrders} orderData={order} paymentTypes={paymentTypes} showMap={showMap} onDelete={(_order) => {
                                                    swal({
                                                      title: "This will delete order permanently!",
                                                      text: "Continue Anyways?",
                                                      icon: "warning",
                                                      buttons: true,
                                                      dangerMode: true,
                                                    })
                                                      .then(async (go) => {
                                                        if (go) {
                                                          let data = await Orders.destroy(order.id);
                                                          if (data.code === 1) {
                                                            swal({title: "Deletion Success", icon: "success"})
                                                            setOrders(prev => {
                                                              let _orders = prev.filter(existing_order => {
                                                                if (_order.id === existing_order.id) {
                                                                  existing_order.canceled = "deleted";
                                                                }
                                                                return true
                                                              })
                                                              return _orders;
                                                            });
                                                          }
                                                        } else {

                                                        }
                                                      });

                                                  }}/>
                                                )
					}
					else if (shownOrders === "all") {
                                          return (
                                            <OrderCard  setOrders={setOrders} key={i} orderData={order} paymentTypes={paymentTypes} showMap={showMap} />
                                          )
					}
				})
		    }
		</div>
	</div>
    )
}

function OrderCard({setOrders, orderData, paymentTypes, showMap, onDelete}) {
	let [order, setOrder] = useState(orderData); 

	if (!order) return null

	return (
		<div className='border-2 rounded w-full hover:bg-gray-50'>
		{onDelete && <div className="text-center text-xs p-2 bg-red-100 hover:bg-red-500 rounded m-3 float-right" onClick={() => {onDelete(order)}}><AiOutlineDelete /></div>}
		<Link to={`/order/${order.id}`}>
			{showMap && <MapView 
				order={order} 
				style={{
				  width: '100%',
				  height: '300px',
				  margin: "auto",
				}}
			/>}
			
			
			<div className="customer-list-item sm:grid sm:grid md:grid lg:grid xl:grid xs:grid-cols-2 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 w-full p-4 "> 
				<CustomerInformation order={order}/>
				<ShowItems order={order}/>
			</div>
			
			<div className="flex flex-row w-full">
				<div className='w-full text-right pr-3'>
		    		<div><b>${order.price}</b></div>
		    	</div>
			</div>

			<div className="grid grid-cols-2 p-4">
				<Link to={`/customer/${order.customerId}`} className={`${classNames.view_customer_button} text-xs`}>Customer</Link>
				<OrderCancelButtons order={order} setOrder={setOrder} setOrders={setOrders} />
			</div>
			
			<PayType order={order} paymentTypes={paymentTypes} />
		</Link>
		</div>
	)

}













