import React, {useState} from 'react'

export default function PackageGrid({packages, onClick}) {

	if (!packages.length) return null
	return (
		<div>
			<div className="grid sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-1 text-black mt-2 p-4">
				{packages && packages.map((order_package, index) => {
					return (
						<ShowPackage 
							key={index} 
							order_package={order_package} 
							onClick={onClick}
							index={index} 
						/>
					)
				})}
			</div>
		</div>


	)

}

function ShowPackage({order_package, onClick, index}) {
	const [showItems, setShowItems] = useState(true);

	return (
		<>
			<button 
				className={`package-items rounded hover:bg-green-50 p-2 m-1 ${index % 2 === 0 ? "bg-blue-50" : "bg-blue-100"}`} 
				onClick={() => { 
					if (onClick) {
						onClick(order_package)
					}
					// setShowItems(!showItems)
				}}
			>

				<div className="">
					<div className="flex flex-row p-1">
						<div className="w-full text-left">
							{order_package.name}
						</div>
						<div className="mr-5 w-full text-right">
							${order_package.price}
						</div>
					</div>
				</div>
				<PackageItemsCollapse 
					package_items={order_package.package_items} 
					show={showItems}
				/>
			</button>
		
		
	</>
	)

}

function PackageItemsCollapse({package_items, show}) {

	if (show) {
		return (
			<>
				{package_items.map(item => {
					return (
						<>
							<div className="pl-5 text-left">	
								<small className="flex flex-row p-1">
									<div className="w-full">
										{item.name}	<span style={{fontSize: 10}}>(x{item.quantity})</span> - ${item.price}
									</div>
								</small>
							</div>
						</>
					)
				})}
			</>
		)
	}

	return null;
	


}
