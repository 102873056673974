import React from 'react';
import {Link} from "react-router-dom"
import ReactToPrint from 'react-to-print';


export default function Leftnav({printRef, orderId, customerId}) {
	return (
		<div className="mt-8">
			<ReactToPrint
		        trigger={() => <button className='mt-4 w-full border-t-2 border-b-2 p-3 bg-green-100 border-green-100'>Print</button>}
		        content={() => printRef.current}
			/>

			<Link to={`/contract/${orderId}`} >
				<div className='text-center mt-4 w-full border-t-2 border-b-2 p-3 bg-blue-100 border-blue-100'>
					Electronic Contract
				</div>
			</Link>
			
			<Link to={`/editOrder/${orderId}`} >
				<div className='text-center mt-4 w-full border-t-2 border-b-2 p-3 bg-yellow-100 border-yellow-100'>
					Edit Order
				</div>
			</Link>

			

			<button className='mt-4 w-full border-t-2 border-b-2 p-3 bg-red-100 border-red-100'>Cancel Order</button>
		</div>

	)
}
