import React, {useState, useEffect} from 'react'
import {useParams, Link, useHistory} from 'react-router-dom'

import OrderList from '../../Components/OrderList'	
import QuickDateList from '../../Components/SidebarLists/QuickDateList.js'
import PageSkeleton from '../../Components/PageSkeleton'

import Customers from '../../Api/Customers'
import Orders from '../../Api/Orders'
import Expenses from '../../Api/Expenses'


export default function SearchDate() {
	let {search, searchEnd} = useParams();
	let history = useHistory();
	
	let [orders, setOrders] = useState([]);
	let [price, setPrice] = useState(0);
	let [discounts, setDiscounts] = useState(0);

	let [gasExpense, setGasExpense] = useState(0); 
	let [employeeExpense, setEmployeeExpense] = useState(0); 
	let [miscExpense, setMiscExpense] = useState(0);
	let [expenseId, setExpenseId] = useState(0); 

	useEffect(function() {
          setOrders([]);
          if (search && searchEnd) {
            Orders.getWithDateRange(search, searchEnd).then(data => {
              setOrders(data.orders)
            });
          } else {
            Orders.getByDate(search).then(data => {
              setOrders(data.orders)
            });
          }

          Expenses.getByDate(search).then(data => {
            setGasExpense(data.expenses.gas);
            setEmployeeExpense(data.expenses.employees);
            setMiscExpense(data.expenses.misc)
            setExpenseId(data.expenses.id)
          })

	}, [search, searchEnd])


	useEffect(function() {
          let price = 0;
          let discount = 0;
          for (let order of orders) {
            price += order.price;
            discount += order.discount;
          }

          setDiscounts(discount)
          setPrice(price)
	}, [orders])


	useEffect(function () {
          if (expenseId) {
            updateExpenses();	
          }
	}, [gasExpense, miscExpense, employeeExpense])

	async function updateExpenses() {
              let data = await Expenses.update(expenseId, {gas: parseFloat(gasExpense) || 0, misc: parseFloat(miscExpense) || 0, employees: parseFloat(employeeExpense) || 0});
	}

	return (
	 	<PageSkeleton 
        	Main={<OrderList orders={orders} showMap={true} shownOrders={"all"} />}
        	Rightnav={<QuickDateList onClick={(day) => {
        		history.push(`/search/date/${day.day}`)
        	}}/>}

        	Leftnav={
        		<ul className='space-y-4 p-5'>
            		<li className="">
            			Total Price: ${price}
            		</li>
            		<li className="">
            			discounts ${discounts}
            		</li>
            		<li className="">
            			Orders: {orders.length}
            		</li>
					<li className="border-b-2">
					</li>

					<li className="">
            			Expenses:
            		</li>

					<li className="ml-2 flex flex-row p-0">
						<span className="pt-2">
							Gas:
						</span>
						<input 
							className="w-11/12 p-2" 
							onChange={(e) => { setGasExpense(e.target.value) }} 
							type="number"
							step="0.01"
							onKeyPress={(e) => {
								if (e.key === "Enter") {
									// send to db
								}
							}}
							value={gasExpense}
						/>
					</li>


					<li className="ml-2 flex flex-row">
						<span className="pt-2">
							Employees: 
						</span>
						<input 
							className="w-11/12 p-2" 
							onChange={(e) => { setEmployeeExpense(e.target.value) }} 
							type="number"
							step="0.01"
							onKeyPress={(e) => {
								if (e.key === "Enter") {
									// send to db
								}
							}}
							value={employeeExpense}
						/>
					</li>

					<li className="ml-2 flex flex-row">
						<span className="pt-2">
							Misc: 
						</span>
						<input 
							className="w-11/12 p-2" 
							onChange={(e) => { setMiscExpense(e.target.value) }} 
							type="number"
							step="0.01"
							onKeyPress={(e) => {
								if (e.key === "Enter") {
									// send to db
								}
							}}
							value={miscExpense}
						/>
					</li>



            		<li className="border-b-2 pt-10">
						<Link to={`/createRoute/${search}`} className="w-full p-2" onClick={() => {	}}>
		      				Create Route
		      			</Link>
		    		</li>

	    		</ul>
			}
			nav={{leftOpen: window.innerWidth < 940 ? false : true, rightOpen: false}}
        />
	)

}
