import React , {useState, useEffect} from 'react'

import PageSkeleton from '../../Components/PageSkeleton'
import { useHistory } from "react-router-dom";
import Orders from '../../Api/Orders'

import EmployeeSettings from '../../Components/Settings/EmployeeSettings';
import HomeSettings from '../../Components/Settings/HomeSettings'
import InventorySettings from '../../Components/Settings/InventorySettings'
import OrderSettings from '../../Components/Settings/OrderSettings'
import PageSettings from '../../Components/Settings/PageSettings'
import PaymentSettings from '../../Components/Settings/PaymentSettings'




export default function LandingPage({}) {
	let history = useHistory();
    let [page, setPage] = useState('Home Settings');

    let components = {
        "Home Settings" : <HomeSettings />,
        "Order Settings" : <OrderSettings />,
        "Payment Settings" : <PaymentSettings />,
        "Employee Settings" : <EmployeeSettings />,
        "Inventory Settings" : <InventorySettings />,
    }

    return (
	<>
	     <PageSkeleton 
            	Main={
                    <div className="w-full">
                        {components[page]}
                    </div>
                  
                }

            	Rightnav={
                    <></>
                }

            	Leftnav={
                    <ul className='space-y-4 p-5'>
                        {components && Object.keys(components).map((component, index) => {
                            return (
                                <li className="border-b-2" key ={`settings-component-${component}-${index}`}>
                                    <button className="w-full p-2" onClick={() => setPage(component)}>{component}</button>
                                </li>
                            )
                        })}
                    </ul>
                }
            	nav={{
	        		leftOpen: true,
	        		rightOpen: false,
	        	}}
            />
	</>
    )

}
