import React from "react"
export default function DatePicker({fromDate, toDate, setFromDate, setToDate}) {

    if (!fromDate || !toDate || !setFromDate || !setToDate) {
        throw new Error("req Props: fromDate, toDate, setFromDate, setToDate");
        return null
    }

	return (
		<div className="p-5">	
			<div className=" rounded mt-5 bg-brown-100 w-full grid xs:grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2  xl:grid-cols-2 p-3">
				<br />
				<div></div>
				<div className="">
			  		<div className="grid grid-cols-2-1 mb-2">
			  			<div>From: </div>
			  			<div>
			  				<input 
			  					className="rounded bg-gray-200" 
			  					type="date" 
			  					value={fromDate} 
			  					onChange={(e) => setFromDate(e.target.value)}
			  				/>
			  			</div>
			  		</div>
			  		<br />
			  		<div className="grid grid-cols-2-1">
			  			<div>To: </div>
			  			<div>
			  		 		<input 
			  		 			className="rounded bg-gray-200" 
			  		 			type="date" 
			  		 			value={toDate} 
			  		 			onChange={(e) => setToDate(e.target.value)}
			  		 		/>
			  			</div>
			  		</div>	
			  	</div> 
			</div>
			<hr />
		</div>
	)
}