import React from 'react'
import Geocode from "react-geocode";
import swal from 'sweetalert';

Geocode.setApiKey("AIzaSyBsXn4UceQN0y8zdryVqEGGhNJXV6t02DU");


export default function ViewCustomer(props) {


	let {
        first_name,
        set_first_name,
        last_name,
        set_last_name,
        address,
        set_address,
        city,
        set_city,
        phone,
        set_phone,
        email,
        set_email,
        notes,
        set_notes,
        onClick,
    } = props

	let classNames = {
		input: 'appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500',
		label: "block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2",
		customer_button: "w-full mt-3 bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow text-left",
	}


	return (

		<div className='mt-3 w-full m-auto'>
			<div className="w-full max-w-lg p-5">	  
			  <div className="flex flex-wrap -mx-3 mb-6">
			    <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
			      <label className={classNames.label} for="grid-first-name">
			        First Name
			      </label>
			      <input className={classNames.input} value={first_name} onChange={(e) =>{
			      	set_first_name(e.target.value)
			      }}/>
			    </div>
			    <div className="w-full md:w-1/2 px-3">
			      <label className={classNames.label} for="grid-last-name">
			        Last Name
			      </label>
			      <input className={classNames.input} value={last_name} id="grid-last-name" type="text" placeholder="last name"  onChange={(e) =>{
			      	set_last_name(e.target.value)
			      }}/>
			    </div>
			  </div>


			  <div className="flex flex-wrap -mx-3 mb-2">
			    <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
			      <label className={classNames.label} for="grid-city">
			        Address
			      </label>
			      <input className={classNames.input} value={address} onChange={(e) =>{
			      		set_address(e.target.value)
			      }}/>
			    </div>
			    <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
			      <label className={classNames.label} for="grid-city">
			        City
			      </label>
			      <input className={classNames.input} value={city} onChange={(e) =>{
			      		set_city(e.target.value)
			      }}/>
			    </div>
			  </div>



			  <div className="flex flex-wrap -mx-3 mb-2">
			    <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
			      <label className={classNames.label} for="grid-city">
			        Phone
			      </label>
			      <input className={classNames.input} value={phone} onChange={(e) =>{
			      		set_phone(e.target.value)
			      }}/>
			    </div>

			    <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
			      <label className={classNames.label} for="grid-city">
			        Email
			      </label>
			      <input className={classNames.input} value={email} onChange={(e) =>{
			      		set_email(e.target.value)
			      }}/>
			    </div>
			  </div>


			  <div className="flex flex-wrap -mx-3 mb-2">
			    <div className="w-full md:w-full px-3 mb-6 md:mb-0">
			      <label className={classNames.label} for="grid-city">
			        notes
			      </label>
			      <textarea rows="5" className={classNames.input} value={notes} onChange={(e) =>{set_notes(e.target.value)}}/>
			    </div>
			  </div>


			   <div className="flex flex-wrap -mx-3 mb-2">
			    <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
			      
			    </div>

			    <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
				    <button className={`${classNames.customer_button} text-center`} onClick={() => {
				    	Geocode.fromAddress(address).then(
						  (response) => {
						   	if (onClick) {
						  		onClick()	
						  	}
						  },
						  (error) => {

						  	swal({
							  title: "Can't find address with google",
							  text: "Continue Anyways?",
							  icon: "warning",
							  buttons: true,
							  dangerMode: true,
							})
							.then((go) => {
							  if (go) {
							  	if (onClick) {
							  		onClick()	
							  	}
							  } else {
							    
							  }
							});
						  }
						);
					}}>
						Save
					</button>
			    </div>
			  </div>
			</div>
		</div>


	)
}