import React from 'react'
import './checkboxStyle.css'

export default function Checkbox({label, checked, setChecked, id}) {

	return (
		<div className='checkbox-container'>
			<input type="checkbox" id={id ? id : label} checked={checked} onChange={(e) => setChecked(e.target.checked)}/>
			
			<label for={id ? id : label} class="checkbox">
				<div class="checkbox__inner">
					<div class="green__ball"></div>
				</div>
			</label>
			<div class="checkbox__text">
				<span>{label}</span>
			</div>
		</div>
	)
}