import React, {useState, useEffect} from 'react';

import MapView from '../../../Components/Maps/MapView'
import Contract from '../../../Components/Contracts'
import OrderInformation from '../../../Components/Orders/OrderInformation'

export default function Main(props) {
	
	let {printRef, order} = props

	return (

		<div className="w-full">
			<div ref={printRef}>
				<MapView order={order} />
				<OrderInformation order={order} />
				<Contract order={order} merchantCopy={true}/>
				<Contract order={order} />
			</div>
			
		</div>

	)
}