import React, {useState, useEffect, useReducer} from 'react';
import classNames from '../../_ClassNames';

import ToolTip from '../ToolTip';
import {default as ModifiersApi} from '../../Api/Modifiers'
import swal from 'sweetalert';

export default function Modifiers(props) {
	const {currentModifierCategory, setCurrentModifierCategory, setModifierCategories, currentModifierCategoryIndex, currentModifier, setCurrentModifier} = props;

	const [newModifierName, setNewModifierName] = useState("");
	const [newModifierPrice, setNewModifierPrice] = useState(0);
	const [addNewModifier, setAddNewModifier] = useState(false)

	const [editModifierName, setEditModifierName] = useState(currentModifier.name || "");
	const [editModifierPrice, setEditModifierPrice] = useState(currentModifier.price || 0);


	useEffect(function() {
		setEditModifierName(currentModifier.name || "")
		setEditModifierPrice(currentModifier.price || 0)
	}, [currentModifier])

	if (!currentModifierCategory) {
		return null
	}

	return (
		<div className="p-5">
			<ToolTip tip={`Master list of modifiers, Add these to items in the Items Tab`} ><div className={`${classNames.inventory.add_button} w-1/12 text-center float-right`}>?</div></ToolTip>
			<div className="">
				<div className="underline text-xl font-bold">
					{currentModifierCategory.name}
				</div>
				<button className={`${classNames.inventory.add_button}`} onClick={() => {
					setCurrentModifier(false);
					setAddNewModifier(true)
				}}>
					Add
				</button>
			</div>

			<div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 text-black" >

				<div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 text-black">
				{currentModifierCategory.modifiers && currentModifierCategory.modifiers.map((modifier, index) => {
					return (
							<div key={`${modifier.id} - index`} >
									<div className="w-full">
										<div 
											style={{marginTop: 5, marginRight: 10}} 
											className="cursor-pointer float-right mb-5 border-2 w-1/12 rounded-md text-center hover:bg-red-300 hover:border-red-300 hover:text-red-800 bg-red-100 border-red-100 text-black"
											onClick={() => {
												ModifiersApi.destroy(modifier.id).then(data => {
													setModifierCategories(prev => {
														prev[currentModifierCategoryIndex].modifiers = prev[currentModifierCategoryIndex].modifiers.filter((mod) => mod.id !== modifier.id);
														return [...prev]
													})
												})
											}}	
										>x</div>
									</div>
								<div 
									className="border-2 rounded m-2 p-3 package-items cursor-pointer" 
									onClick={() => {
										setCurrentModifier(modifier);
										setAddNewModifier(false);
									}}
								>
									<div className="text-left">
										{modifier.name}	
									</div>
									<div className="text-right">
										${modifier.price}
									</div>
								</div>
							</div>
						)
					})}
				</div>

			{/*add new Modifier*/}

			{addNewModifier && 
				<div className="w-full max-w-lg p-2">
					<div className="grid grid-cols-2 p-4">
					  	<button className={classNames.customer_button} onClick={() => {
					  		if (!newModifierName) {
					  			swal({title: "The modifier needs a name!", icon:"warning"})
					  			return ;
					  		}

					  		ModifiersApi.create({name: newModifierName, price: newModifierPrice, modifierCategoryId: currentModifierCategory.id}).then(data => {
					  			setModifierCategories(prev => {
					  				prev[currentModifierCategoryIndex].modifiers = data.modifiers;
					  				return [...prev];
					  			})
					  		})
					  		setNewModifierName("")
					  	}}>Save</button>

					  	<button className={classNames.inventory.cancel_button} onClick={() => {
					  		setAddNewModifier(false)
					  	}}>Cancel</button>  
				    </div>
				    <div className="w-full px-3 mb-6 md:mb-0">
				    	<label className={classNames.label} htmlFor="grid-last-name">
							Name
						</label>
						<input 
							className={classNames.input} 
							value={newModifierName} 
							onChange={(e) =>{
						  		setNewModifierName(e.target.value)
							}}/>
				    </div>
				    <div className="w-full px-3">
						<label className={classNames.label} htmlFor="grid-last-name">
							Default Price
						</label>

						<div className="flex flex-row h-11">
						
						<input 
							type="number"
							step="0.1"
							className={classNames.input} 
							value={newModifierPrice} 
							onChange={(e) =>{
						  		setNewModifierPrice(e.target.value)
							}}/>
							<ToolTip tip={`This is the default price to make setup easier for adding modifiers to items`} ><div className={`${classNames.inventory.tooltip_button} w-1/12 text-center`}>?</div></ToolTip>
						</div>
				    </div>
				</div>
			}

		{/*edit modifier*/}

			{currentModifier && 
				<div className="w-full max-w-lg p-2">
					<div className="grid grid-cols-2 p-4">
					  	<button className={classNames.customer_button} onClick={() => {
					  		ModifiersApi.update(currentModifier.id, {name: editModifierName, price: editModifierPrice}).then(data => {
					  			setModifierCategories(prev => {
					  				let _modifiers = prev[currentModifierCategoryIndex].modifiers;
					  				_modifiers = _modifiers.map((_mod, index) => {
					  					
					  					if (_mod.id === data.modifiers.id) {
					  						_mod = data.modifiers;
					  					}
					  					return _mod
					  				}) 
									prev[currentModifierCategoryIndex].modifiers = _modifiers
									return [...prev]
								})
					  		})

					  	}}>Save</button>

					  	<button className={classNames.inventory.cancel_button} onClick={() => {
					  		setCurrentModifier(false)
					  	}}>Cancel</button>  
				    </div>
				    <div className="w-full px-3 mb-6 md:mb-0">
				    	<label className={classNames.label} htmlFor="grid-last-name">
							Name
						</label>
						<input 
							className={classNames.input} 
							value={editModifierName} 
							onChange={(e) =>{
						  		setEditModifierName(e.target.value)
							}}/>
				    </div>
				    <div className="w-full px-3">
						<label className={classNames.label} htmlFor="grid-last-name">
							Default Price
						</label>
						<input 
							type="number"
							step="0.1"
							className={classNames.input} 
							value={editModifierPrice} 
							onChange={(e) =>{
						  		setEditModifierPrice(e.target.value)
							}}/>
				    </div>
				</div>
			}


			</div>

	</div>
	)
}