import React, {useState, useEffect} from 'react' 
import Modal from 'react-modal';
import ItemModifierCategories from '../../Api/ItemModifierCategories';
import {default as ItemModifiersApi} from '../../Api/ItemModifiers';

let classNames = {
	input: 'appearance-none block w-full h-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500',
	label: "block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2",
	dollar_label: "block uppercase tracking-wide text-gray-700 text-xl font-bold mb-2",
	customer_button: "w-11/12 ml-3 mt-3 bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 mb-5 border border-gray-400 rounded shadow text-left",
	cancel_button: "w-11/12 ml-3 mt-3 bg-white hover:bg-red-100 text-red-800 font-semibold py-2 px-4 mb-5 border border-red-400 rounded shadow text-left",
	close_button: "mt-8 bg-white hover:bg-red-100 text-red-800 font-semibold py-2 px-6 border border-red-400 rounded shadow text-right text-xs",
	modifier_button: "h-full bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 mb-5 border border-gray-400 rounded shadow text-center",
    remove_button: "h-full bg-red-100 hover:bg-red-400 text-gray-800 font-semibold py-2 px-4 mb-5 border border-red-400 rounded shadow text-center",
    update_button: "h-full bg-yellow-100 hover:bg-yellow-400 text-gray-800 font-semibold py-2 px-4 mb-5 border border-yellow-400 rounded shadow text-center",

}

export default function ItemModifiers(props) {
    const {modifierCategories, currentItem} = props;
    const [showModifierModal, setShowModifierModal] = useState(false)

    return (
        <>
        <button className={classNames.customer_button} onClick={() => {
            setShowModifierModal(true)
        }}>Add Modifiers</button>

         <div>
            <Modal 
               isOpen={showModifierModal}
               contentLabel="Modifier Modal"
               ariaHideApp={false}
            >
            <div className='fixed right-20'>
                <button className={`${classNames.close_button}`} onClick={()=> setShowModifierModal(false)}>Close</button>
            </div>
             

            <div class="grid grid-cols-1-2">
                <div>
                    <div className="text-center text-2xl text-red-300">Unselected Modifiers</div>
                    {modifierCategories && 
                        modifierCategories.map((modifierCategory, index) => {
                            return (
                                <div className="p-5 m-5 border-b-2">
                                    <div className="text-xl text-center mb-2">{modifierCategory.name}</div>
                                    <div className="grid justify-center align-center gap-2 w-full">
                                    {modifierCategory.modifiers && modifierCategory.modifiers.map((modifier, index) => {
                                        return (
                                            <AddModifier key={index} modifierCategory={modifierCategory} mod={modifier} currentItem={currentItem}/>
                                        )
                                    })}
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>

                <div className="w-full border-l-2">
                    <div className="text-center text-2xl text-green-800">Active Modifiers</div>
                    {currentItem && currentItem.item_modifier_categories && 
                        currentItem.item_modifier_categories.map((modifierCategory, index) => {
                            if (!modifierCategory.item_modifiers || !modifierCategory.item_modifiers.length) {
                                return null;
                            }
                            return (
                                <div className="p-5 m-10">
                                    <div className="text-xl text-center mb-2">{modifierCategory.name}</div>
                                    <div className="grid grid-cols-2 justify-center align-center gap-2 w-full">
                                    {modifierCategory.item_modifiers && modifierCategory.item_modifiers.map((modifier, index) => {
                                        return (
                                            <EditModifier key={index} modifierCategory={modifierCategory} mod={modifier} currentItem={currentItem}/>
                                        )
                                    })}
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
             </div>


            </Modal>
          </div>
          </>
    )
}

function AddModifier({modifierCategory, mod, currentItem}) {
    const [modifier, setModifier] = useState(mod);

    useEffect(function() {
        if (mod) {
            setModifier(mod);
        }
    }, [mod])
    if (!modifier) return null
    return (
        <div className="p-5 rounded">
            
            <div className="w-ful flex flex-row justify-between space-x-10">
            <div className="text-left text-lg font-bold">
                {modifier.name}
            </div>
            <button className={`${classNames.modifier_button} `} onClick={() => {
                ItemModifierCategories.createWithItemModifier({
                        itemId: currentItem.id,
                        categoryName: modifierCategory.name,
                        modifierCategoryId: modifierCategory.id, 
                        modifier: modifier}).then(data => {
                            console.log(data);
                        })
            }} >Add</button>
        </div>
            
        </div>
    )
}

function EditModifier({modifierCategory, mod, currentItem}) {
    const [modifier, setModifier] = useState(mod);

    useEffect(function() {
        if (mod) {
            console.log(mod)
            setModifier(mod);
        }
    }, [mod])
    if (!modifier) return null
    return (
        <div className="p-5 rounded">
            <div className="text-center text-lg font-bold">
                {modifier.name}
            </div>
            <div className="w-full px-3 flex flex-row">
            <label className={`${classNames.dollar_label} py-2 mr-2`} htmlFor="grid-last-name">
                $
            </label>
            <input 
                type="number"
                step="0.1"
                className={classNames.input} 
                value={modifier.price} 
                onChange={(e) =>{
                    setModifier({id: modifier.id, name: modifier.name, price: e.target.value})
                }}
            />
            
        </div>
        <div className="flex flex-row ml-7 space-x-10">
            <button className={`${classNames.update_button}`} onClick={() => {
                    ItemModifiersApi.update(modifier.id, {name: modifier.name, price: modifier.price}).then(data => {
                        console.log(data);
                    })
                }} >Update</button>

                <button className={`${classNames.remove_button}`} onClick={() => {
                    ItemModifiersApi.destroy(modifier.id).then(data => {
                        console.log(data)
                    });
                }} >Remove</button>
            </div>
        </div>
    )
}