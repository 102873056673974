import React from 'react'
import {BiArrowBack} from 'react-icons/bi'
import {Link} from 'react-router-dom'
import ReactToPrint from 'react-to-print';

export default function Leftnav(props) {
    const {search, price, customerCount, printRef} = props;

    return (
        <ul className='space-y-2 p-5'>
	            		
            <li className="border-b-2 ">
                <Link to={`/search/date/${search}`} className="w-full p-2">
                    <div className="flex flex-row p-2"> 
                        <div className="pt-1 mr-2"><BiArrowBack /> </div>
                        <div>{search}</div>
                    </div>
                </Link>
            </li>

            <li className="pt-10">
                Total Price: ${price}
            </li>
            <li className="">
                Customers: {customerCount}
            </li>
            <li className="border-b-2">
            </li>
            
            <li className="border-b-2 pt-10">
                <ReactToPrint
                    trigger={() => <button className='mt-4 w-full border-t-2 border-b-2 p-3 bg-green-100 border-green-100'>Print</button>}
                    content={() => printRef.current}
                />
            </li>
        </ul>

    )
}