import React, {useState, useEffect} from 'react'
import moment from 'moment'

export default function QuickDateList(props) {
    let {onClick} = props
    let [days, setDays] = useState([])

    useEffect(function() {

		setDays([...getWeekends(1), ...getWeekends(2)])

		function getWeekends(offset) {
			var d = new Date();
			

			d = daysInMonth(d.getMonth(),d.getFullYear(), offset); //Get total days in a month
			var days = d.getDate()
			var weekend = new Array();   //Declaring array for inserting Saturdays
			


			for(var i=1;i<= days; i++){    //looping through days in month
	    	  	  var newDate = new Date(d.getFullYear(),d.getMonth(),i)
	    	  	  if(newDate.getDay()==0){   //if Sunday
	        		weekend.push({day: moment(newDate).format('YYYY-MM-DD'), label: `Sunday ${moment(newDate).format('MMM Do')}`});
	    	  	  }
	    	  	  if(newDate.getDay()==6){   //if Saturday
	        		weekend.push({day: moment(newDate).format('YYYY-MM-DD'), label: `Saturday ${moment(newDate).format('MMM Do')}`});
	    	  	  }

			}
			
			return weekend

		}

		function daysInMonth(month,year, offset) {
		  if (offset > 0) {
			if (month + offset > 12) {
			    year = year + 1
			    month = month + offset - 12
			} else {
			    month = month + offset
			}
		  }
	   	  return new Date(year, month, 0);
		}

    },[])

    return (
	<>
	  <ul>
	    {days.map((day,i) => {
  	     	return (
		      	<li key={i}>
			        <button
					  className='border-2 rounded w-11/12 p-3 ml-2 mt-2'
					  onClick={() => {
						if (onClick) {
							onClick(day);
						}
					  }}
					>
				  		<small className='text-xs'>{day.label}</small>
					</button>
			   	</li>
     		)
	    })
	   }
	  </ul>
	</>

    )


}
