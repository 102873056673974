import React, {useRef, useState, useEffect} from 'react'
import PageSkeleton from '../../Components/PageSkeleton'
import {useParams} from 'react-router-dom'
import Main from "./Main"
import Leftnav from "./Leftnav"


import Orders from '../../Api/Orders'



export default function ViewOrder() {
 	const printRef = useRef();
 	const {id} = useParams();
	const [order, setOrder] = useState(false);
	
	useEffect(function() {
		Orders.getOrdersWithOrderItems(id).then(data => {
			setOrder(data.orders);
		}).catch(err => {
			console.log(err)
		})
	}, [])

	return (
		<PageSkeleton 
			Main={<Main printRef={printRef} order={order}/>}
			Leftnav={<Leftnav printRef={printRef} orderId={order.id}/>}
			Rightnav={null}
			nav={{leftOpen: window.innerWidth > 648 ? true : false}}
		/>

	)
}
