import React, {useState, useEffect, useRef} from 'react'


export default function AddCategory({onSubmit}) {

	const [showInput, setShowInput] = useState(false)
	const [newCategory, setNewCategory] = useState("")
	const inputRef = useRef(null)

	useEffect(function() {
		if (showInput) {
			if (inputRef.current) {
				inputRef.current.focus()
			}
		}
	}, [showInput])

	return (
		<ul className='space-y-4 p-5'>
				<li className="border-b-2">
					{!showInput &&  
						<button 
							className="w-full p-2" 
							onClick={() => {	
								setShowInput(true)
							}

						}>
	      					Add+
	      				</button>
	      			}

	      			{showInput &&  
						<input 
							ref={inputRef}
							className="w-11/12 p-2" 
							onChange={(e) => { setNewCategory(e.target.value) }} 
							onKeyPress={(e) => {
								if (e.key === "Enter") {
									if (onSubmit) {
										onSubmit(newCategory)
									}
									setNewCategory("")
									setShowInput(false)
									// send to db
								}
							}}
							value={newCategory}
						/>
	      			}
		    	</li>
			</ul>

	)
}