import React, {useEffect} from 'react'

import {useHistory} from 'react-router-dom'


import OrderForm from '../../../Components/Orders/OrderForm'
import ShowCart from '../../../Components/Orders/ShowCart'

import PageSkeleton from '../../../Components/PageSkeleton'
import classNames from '../../../_ClassNames'

import {BiCart, BiCategoryAlt} from 'react-icons/bi'
import { IconContext } from "react-icons";

export default function Confirm(props) {
	const {set_page} = props
		return (
			<PageSkeleton
	        	Main={<ConfirmOrder {...props} />}
	        	Rightnav={<ShowCart {...props} />}
	        	Leftnav={<>
	        		<div className="p-5 mb-6 md:mb-0">
					      <button className={`${classNames.back_button} text-center`} onClick={() => {
					      	set_page(1)
					      }}>
						     Back
		      			</button>
    				</div>
	        	</>}
	        	omitSearch={true}
	        	rightToggleIcon={<IconContext.Provider value={{ color: "blue", size: "2em" }}><div className="flex flex-row"><BiCart /><div className="">({props.customer_items.length})</div></div></IconContext.Provider>}
	        	nav={{leftOpen: window.innerWidth < 940 ? false : true, rightOpen: window.innerWidth < 940 ? false : true}}
	        />

		)
}

function ConfirmOrder(props) {
	let {
		set_page,
		customer,
		set_customer,
		first_name,
		set_first_name,
		last_name,
		set_last_name,
		date,
		set_date,
		time,
		set_time,
		address,
		set_address,
		city,
		set_city,
		latitude,
		setLatitude,
		longitude,
		setLongitude,
		phone,
		set_phone,
		email,
		set_email,
		notes,
		set_notes,
		customers,
		customer_items,
		customer_packages,
		discount,
		price,
		onConfirm,

	} = props;
	let history = useHistory();
 return (
 	<>
 		<div className="text-center text-semibold text-2xl"> Confirm Order </div>
 		
 		<OrderForm 
			first_name={first_name}
			set_first_name={set_first_name}
			last_name={last_name}
			set_last_name={set_last_name}
			date={date}
			set_date={set_date}
			time={time}
			set_time={set_time}
			address={address}
			set_address={set_address}
			latitude={latitude}
			setLatitude={setLatitude}
			longitude={longitude}
			setLongitude={setLongitude}
			city={city}
			set_city={set_city}
			phone={phone}
			set_phone={set_phone}
			email={email}
			set_email={set_email}
			notes={notes}
			set_notes={set_notes}
			customers={customers}
			button_title={"Confirm Order"}
			onClick={async () => {
				await onConfirm()
			}}
		/>
 	</>

 )
}

