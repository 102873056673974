import React, {useContext} from 'react'
import {AiOutlineCloseCircle} from 'react-icons/ai'
import Categories from "../../Api/Categories";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import DraggableCategories from './DraggableCategories'
import { IconContext } from "react-icons";
import ApplicationSettingsContext from '../../Context/ApplicationSettings'


export default function CategoryList(props) {
	const {categories, setItems, previous_orders, packages, onPackageClick, onCategoryClick, onPreviousOrderClick, onCategoryDelete, setCategories, onBackButtonClick} = props
	let {applicationSettings : settings, setApplicationSettings} = useContext(ApplicationSettingsContext);

	return (
		<ul className='space-y-4 p-5'>
			{onBackButtonClick && 
					<li className="p-5 pt-2 pb-2 mb-10 border-b-2">
						<button 
							className="text-xs w-full" style={{fontSize: "0.8em", marginLeft: 10}}
							onClick={() => onBackButtonClick()}
						>
							Back
					    </button>
			    	</li>
			    }

			{previous_orders && <>Previous Orders</>}
			{previous_orders && previous_orders.map((order, index) => {
				return (
					<li key={index} className="border-b-2">
						<button className="text-xs w-full" style={{fontSize: "0.8em", marginLeft: 10}} onClick={() => {
							setItems([...order.order_items, ...order.order_packages]);
							if (onPreviousOrderClick) {
								onPreviousOrderClick(order);	
							}
							
						}}>
		      				{order.rental_date}
		      			</button>
		    		</li>
				)
			})}

			{previous_orders && <br />}
			{settings.show_inventory_packages && packages && 
					<li className="border-b-2">
						<button className="w-full p-2" onClick={() => {
							setItems(packages)
							if (onPackageClick) {
								onPackageClick(packages)
							}
						}}>
		      				Packages
		      			</button>
		    		</li>
		    }


		    {setCategories && <DraggableCategories 
			    categories={categories}
				setItems={setItems}
				onCategoryClick={onCategoryClick}
				onCategoryDelete={onCategoryDelete}
				setCategories={setCategories}
			/>}

			{!setCategories && categories && categories.map((category, index) => {
				return (
					<li key={index} className="border-b-2 text-left cursor-pointer flex flex-row">
						<div className="w-full p-2 text-left" onClick={() => {
							setItems(category.items);
							if (onCategoryClick) {
								onCategoryClick(category.items, category)
							}
						}}>
		      				{category.name}

		      			</div>
		      			{onCategoryDelete && 
		      				<div className="w-full flex justify-end">
			      				<IconContext.Provider value={{ color: "red", size: "1.6em", className: "global-class-name" }}>
			      					<AiOutlineCloseCircle onClick={() => {
			      						onCategoryDelete(category);
			      					}}/>
			      				</IconContext.Provider>	
		      			</div>
		      			}

		    		</li>
				)
			})}
		</ul>

	)
}


