import React, {useState, useEffect} from 'react'
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';
import Geocode from "react-geocode";


const containerStyle = {
  width: '90%',
  height: '500px',
  margin: "auto",
  marginTop: "50px",
};

Geocode.setApiKey("AIzaSyBsXn4UceQN0y8zdryVqEGGhNJXV6t02DU");

export default function MapView({order, latitude, longitude, style}) {
	const [map, setMap] = React.useState(null)
	const [marker, setMarker] = useState(false)

	const { isLoaded } = useJsApiLoader({
	    id: 'google-map-script',
	    googleMapsApiKey: "AIzaSyBsXn4UceQN0y8zdryVqEGGhNJXV6t02DU"
	})

  	const onLoad = React.useCallback(function callback(map) {
    	const bounds = new window.google.maps.LatLngBounds();
    	setMap(map)
  	}, [])

	const onUnmount = React.useCallback(function callback(map) {
		setMap(null)
	}, [])

	useEffect(function() {
		if(order) {
				Geocode.fromAddress(`${order.address} ${order.city}`).then((response) => {
					const { lat, lng } = response.results[0].geometry.location;
					setMarker({lat: lat, lng: lng});
				},
				(error) => {}
				);	
		} else if (latitude && longitude && !marker) {
			setMarker({lat: latitude, lng: longitude})
		}
		
	}, [order, latitude, longitude])


	return (
	  (isLoaded && marker) ? (
	      <GoogleMap
	        mapContainerStyle={style || containerStyle}
	        center={marker}
	        zoom={16}
	        onLoad={onLoad}
	        onUnmount={onUnmount}
			options={{gestureHandling: "cooperative"}}
	      >
	         <Marker
		      onLoad={onLoad}
		      position={marker}
		    />
	      </GoogleMap>
	  ) : <><div style={style || containerStyle} className="text-center"> No Map Found</div></>
	)



}
