import React from 'react'
import DatePicker from '../../../Components/DatePicker';

export default function Main({orders, page, setPage, fromDate, toDate, setToDate, setFromDate, pageComponents}) {
    return (
        <div className="">
		<div className="mt-10 ml-8 text-xl font-semibold underline">{page}</div>
          <DatePicker 
          	fromDate={fromDate} 
          	setFromDate={setFromDate} 
          	setToDate={setToDate} 
          	toDate={toDate}
          />
          {pageComponents[page] || ""}
        </div>
    )
}

