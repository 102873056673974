import { useEffect, useRef, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { Document, Page, pdfjs } from 'react-pdf'
import SignatureCanvas from 'react-signature-canvas'
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import moment from 'moment'
import useResize from '../../Hooks/useResize'
import Checkbox from '../../Components/Checkbox'
import {FaArrowCircleLeft, FaArrowCircleRight} from 'react-icons/fa'
import { IconContext } from "react-icons";
import ClassNames from '../../_ClassNames'
import Orders from '../../Api/Orders';
import {isMobile} from 'react-device-detect';
import swal from 'sweetalert'
pdfMake.vfs = pdfFonts.pdfMake.vfs;
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function NavButtons ({page, setPage, scrollTop}) {
  return (
     <div className="grid grid-cols-2 gap-4 place-content-between p-10">
          <IconContext.Provider value={{ color: "rgba(0, 150, 150, 1)", size: "2em" }}>
             <button className="ml-5" onClick={() => {
                page === 1 ? setPage(1) : setPage(page - 1)
                scrollTop();
            }}> <FaArrowCircleLeft /></button>

             {page < 5 && 
              <button className="justify-self-end mr-5" onClick={() => {
                page + 1 >= 5 ? setPage(5) : setPage(page + 1)
                scrollTop()
             
              }}> <FaArrowCircleRight /></button>
            }
          </IconContext.Provider>
        </div>
  )
}


export default function EContract() {
  const {id} = useParams();

  const [url, setUrl] = useState(null);
  const [phone_number, set_phone_number] = useState(null);
  const [agree, set_agree] = useState(false);
  const [signature, set_signature] = useState("");
  const [page, setPage] = useState(1)
  const [order, setOrder] = useState(false);

  const [done, setDone] = useState(false);
  const [signedContract, setSignedContract] = useState("");

  let signatureRef = useRef();
  let topRef = useRef(null);
  let signScrollRef = useRef(null)
  const width = useResize();
  const [docWidth, setDocWidth] = useState(width)
  const [email, setEmail] = useState("")
  const [contract_phone_number, set_contract_phone_number] = useState("");

  useEffect(function() {
    if (isMobile) {
         if (width < 387) {
          setDocWidth(width)
        } else if (width >= 387 && width < 436) {
          setDocWidth(width * .9)
        }
        else if (width >= 436 && width < 492) {
          setDocWidth(width * .8)
        } else if (width >= 492 && width < 554) {
          setDocWidth(width * .7)
        } else if (width >= 554 && width < 652) {
          setDocWidth(width * .6)
        }
        else if (width >= 652 && width < 782) {
          setDocWidth(width * .5)
        }
        else if (width >= 782 && width < 870) {
          setDocWidth(width * .4)
        }
    } else {
      setDocWidth(width * .6)
    }
   

  }, [width])
  
  useEffect(function() {
    Orders.getOrdersWithOrderItems(id).then(data => {
      setOrder(data.orders);
      setEmail(data.orders.email)
      set_contract_phone_number(data.orders.phone_number)
    }).catch(err => {
      console.log(err)
    })
  }, [])

  useEffect(function() {
    if (order && order.contract) {
        setSignedContract(order.contract)
    }
  }, [order])

  const create = () => {
    let orderInfo = []

    for (let pack of order.order_packages) {
        orderInfo.push({style: "rulesText", text: `${pack.name} - $${pack.price}`, margin: [0, 0, 0, 0]})
        for (let item of pack.order_package_items) {
            orderInfo.push({style: "rulesText", text: `${item.name}`, margin: [10, 0, 0, 0]})
        }
    }

    for (let item of order.order_items){
        orderInfo.push({style: "rulesText", text: `${item.name} - $${item.price}`, margin: [15, 10, 0, 0]})
    }   

    var docDefinition = {
      content: [
         {style:"header", text: "JNB JUMPS" },
         {style:"subheader", text: "CHILDREN'S INFLATABLE RENTAL AGREEMENT", margin: [0, 0, 0, 5]},
         {style:"subheader2", text: "Serving Tracy and Surrounding Areas Since 1998",margin: [0, 0, 0, 10]},
         {style:"subheader2", text: "(209) 830-7588", margin: [0, 0, 0, 10]},
         {columns: [
            {
              width: 'auto',
              style: "customerInformation",
              text: `Lessee: ${order.first_name} ${order.last_name}`
            },
            {
              width: '*',
              text: ''
            },
            {
              width: 'auto',
              style: "customerInformation",
              text: `${moment(order.rental_date).format("MMMM Do YYYY")}`
            }
          ]},
          {columns:  [
            {
              width: 'auto',
              style: "customerInformation",
              text: `Address: ${order.address} ${order.city}`
            },
            {
              width: '*',
              text: ''
            },
            {
              width: 'auto',
              style: "customerInformation",
              text: `${moment(`${order.rental_date}T${order.rental_time}`).format("LT")}`
            }
          ]},

       {style: "customerInformation", text: `Phone Number: ${phone_number || order.phone_number}`},
       {style: "rulesHeader", text: "General rules for safe operation:", margin: [0, 5, 0, 0]},
       {style: "rulesText", text: "MUST be supervised by the lessee or his/her authorized representative(s).", margin: [10, 0, 0, 0]},
       {style: "rulesText", text: "NO flips, rough play or climbing on net walls.", margin: [10, 10, 0, 0]},
       {style: "rulesText", text: "NO shoes, jewelry and eyeglasses in the inflatable.", margin: [10, 10, 0, 0]},
       {style: "rulesText", text: "NO smoking or sharp objects in or around the inflatable.", margin: [10, 10, 0, 0]},
       {style: "rulesText", text: "NO food, drinks or gum in or around the inflatable.", margin: [10, 10, 0, 0]},
       {style: "rulesText", text: "NO SILLY STRING or CONFETTI around or near the inflatable (Cleaning charge may apply)", margin: [10, 10, 0, 0]},
       {style: "rulesText", text: "DO NOT mix age groups. Same age groups must play at the same time.", margin: [10, 10, 0, 0]},
       {style: "rulesText", text: "DO NOT exceed the recommended capacity.", margin: [10, 10, 0, 0]},
       {style: "rulesText", text: "Turn OFF Automatic sprinklers. (Cleaning charge may apply if unit is wet)", margin: [10, 10, 0, 0]},

       {style: "rulesHeader", text: "Capacity:", margin: [0, 5, 0, 0]},
       {style: "rulesText", text: "(any given time): 5 years and under (9), or, 6-8 years (6), or, 9-13 years (4)", margin: [10, 10, 0, 10]},
       {style: "rulesHeader", text: "The lessee also agrees to the following:", margin: [0, 10, 0, 0]},

       {style: "rulesText", text: "1. The equipment will not be subjected to any unnecessary rough usage, and shall conform to all applicable laws, city/county ordinances and other lawful regulations.", margin: [10, 10, 0, 0]},

       {style: "rulesText", text: "1. The equipment will not be subjected to any unnecessary rough usage, and shall conform to all applicable laws, city/county ordinances and other lawful regulations.", margin: [10, 10, 0, 0]},
       {style: "rulesText", text: "2. LESSEE agrees not to use the equipment for illegal purposes.", margin: [10, 10, 0, 0]},
       {style: "rulesText", text: "3. The equipment should be used by duly qualified employees, guest and or agents of lessee, and shall not be sublet or assigned.", margin: [10, 10, 0, 0]},
       {style: "rulesText", text: "4. If any equipment is returned in a damaged or destroyed condition or is not returnable by reason of carelessness, destruction, confiscation, theft, or for any reason while the equipment is in the posession of the LESSEE, the latter shall pay the full monetary value, based on the manufacturer’s current list price of such items in addition to accrued rental/s.", margin: [10, 10, 0, 0]},
       {style: "rulesText", text: "5. The LESSEE agrees to return the equipment in the same condition in which it was received save only for normal use and wear, but will be responsible for the cost of necessary repairs of damage beyond this normal wear and use.", margin: [10, 10, 0, 0]},
       {style: "rulesText", text: "6. I understand and acknowledge that the activity to be engaged in through my rental of an inflatable, interactive amusement device brings with it both known and unknown and unanticipated risks to myself, my guests and invites. Those risks include, but are not limited to fallings, slipping, crashing and colliding and could result in injury, illness, disease, emotional distress, death and/or property damage to myself or my guests and invitees.", margin: [10, 10, 0, 0]},
       {style: "rulesText", text: "7. LESEE or any of his/ her agent, guest or employee agrees to indemnify and hold LESSOR harmless from any and all claims, actions, suits, proceedings, cost, expenses, damages, and liability including Attorney fees, fines, and penalties arising out of, connected with, or resulting from the use of the equipment including without limitation, the manufacturer, delivery, possession, use operation, conduct or return of said equipment.", margin: [10, 10, 0, 0]},
       {style: "rulesText", text: "8. LESSEE agrees not to hold LESSOR responsible for any unfavorable weather conditions, high winds exceeding 25 MPH, rain, or any other unexpected conditions that may arise during all the time that the LESSEE is in possession or using the equipment.", margin: [10, 10, 0, 150]},
        
       {style: "esig", text: "Each party agrees that the electronic signatures, whether digital or encrypted, are intended to authenticate this writing and to have the same force and effect as manual signatures.", margin: [10, 10, 0, 30]},
    
        ...orderInfo,

        { style: ["price", "bold", "medium"], text: `Total: $${order.price}`},
        {image: signature ? signature : "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEAYABgAAD/2wBDAP//////////////////////////////////////////////////////////////////////////////////////wAALCAABAAEBAREA/8QAJgABAAAAAAAAAAAAAAAAAAAAAxABAAAAAAAAAAAAAAAAAAAAAP/aAAgBAQAAPwBH/9k", style: "signature", width: 150},

        { style: ["small", "signature"], text: "(Please note there will be $10.00 convenience fee for M/C, Visa & Amex)"},
    

      ],

      tmpContent : [],

      pageMargins: [ 40, 40, 40, 0 ],

      styles: {
        header: {
          fontSize: 30,
          bold: true,
          alignment: "center",
          marginTop: 6,
        },
        subheader: {
          fontSize: 22,
          bold: true,
          alignment: "center",
        },
        subheader2: {
          fontSize: 20,
          bold: true,
          alignment: "center",
        },

        customerInformation: {
          fontSize: 18,
          alignment: "left",
          bold: true,
        },

        anotherStyle: {
          italics: true,
          alignment: 'right'
        },
        rulesHeader: {
          fontSize: 24,
          bold: true,
          alignment: "left",
        },
        rulesText: {
          fontSize: 20,
          lineHeight: 1.2,
          alignment: "left",
        },
         esig: {
          fontSize:20,
          alignment: "left",
        },
        small: {
          fontSize: 8,
        },
        signature: {
          alignment: "right",
        },
        price: {
          alignment: "right",
          fontSize: 25,
        },

        bold : {
          bold: true,
        }
      },

    };
    try {

        const pdfDocGenerator = pdfMake.createPdf(docDefinition);
        pdfDocGenerator.getBase64((data) => {
          setUrl(data);
        });
        return pdfDocGenerator;
    } catch(e) {
      console.log(e)
    }
   
  };

  useEffect(function() {
    if (!order) return ;
    
      create();
    
  }, [order, signature]) 

  useEffect(() => {
    return () => {
      if (url !== null) {
        URL.revokeObjectURL(url);
      }
    };
  }, [url]);

  if (done) {
    return (






        <div className="h-full m-auto ">
          <div className="text-center text-2xl font-bold mt-10">Send Copy</div>
            <div className={'grid grid-cols-1 p-10 mb-10'}>
              <div className="w-full px-3 mb-6 md:mb-0">
                <label className={ClassNames.label} for="grid-city">
                  Email
                </label>
                <input className={ClassNames.input} value={email} onChange={(e) =>{
                    setEmail(e.target.value)
                }}/>
                <button className={ClassNames.e_contract_confirm_button} onClick={() => {
                  Orders.sendContract(order.id, email).then(data => {

                  })
                }}>Send Email</button>
              </div>
            </div>

            <div className="w-full">
            <Link className="" to={`/order/${order.id}`}>
              <button className={`${ClassNames.e_contract_confirm_button}`}>Done</button>
            </Link>
            </div>
        </div>


    )
  }

  return (
      <div style={{margin:"auto"}}>
        <div ref={(ref) => topRef = ref} style={{height: 1}}></div>
       

        {page < 5 && 
          <div className="flex w-full justify-center">
            {!signedContract && url && <Document file={`data:application/pdf;base64,${url}`} pages={4}><Page width={docWidth} pageNumber={page} /></Document>} 
            {signedContract && <Document file={`data:application/pdf;base64,${signedContract}`} pages={4} onLoadError={(e) => console.log(e)}><Page width={docWidth} pageNumber={page} /></Document>}
          </div>
        }

        {page === 5 && 
          <>
           <div className="text-center" ref={(ref) => signScrollRef = ref}>Total: ${order.price}</div>
        
          <SignatureCanvas penColor='black' ref={(ref) => signatureRef = ref} onEnd={() => {
            set_signature(signatureRef.toDataURL("image/png"))
          }} canvasProps={{style:{border: "1px solid black"}, width: width, height: 400, className: 'sigCanvas'}} />

          <button className={`${ClassNames.e_contract_confirm_button}`} onClick={() => {
            if (!signature) {
              swal({title:"No Signature", icon: "warning"});
              return ;
            }
            let signatureImage = signatureRef.toDataURL("image/png");
            let pdfDocGenerator = create()
            let contractImage;
            pdfDocGenerator.getBase64((data) => {
              setUrl(data);
              contractImage = data
              Orders.saveContract({signatureImage, contractImage}, order.id).then(data => {
                if (data.code === 1 ) {
                  setDone(true)
                  swal({title: "success", icon: "success"})
                } else {
                  swal({title: "Something went Wrong =(", icon: "warning"})
                }
              })
            });

            set_signature(signatureImage)

          }}>Submit</button>
          </>
        }
        <NavButtons page={page} setPage={setPage} scrollTop={() => {
            if (topRef) topRef.scrollIntoView({behavior: "smooth"})
        }} />

       

        

       
      </div>
  );
}
