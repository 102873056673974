import React , {useState, useEffect} from 'react'
import { useParams } from "react-router-dom";
import moment from 'moment'

import PageSkeleton from '../../Components/PageSkeleton'



import QuickDateList from '../../Components/SidebarLists/QuickDateList.js'
import OrderSummary from '../../Components/Analytics/OrderSummary'
import ItemSales from '../../Components/Analytics/ItemSales'
import Cancellations from '../../Components/Analytics/Cancellations'

import Main from './Main'
import Leftnav from './Leftnav'

import Orders from '../../Api/Orders'

export default function Analytics() {
  let {id} = useParams();
  let [page, setPage] = useState("Order Summary");
  let [fromDate, setFromDate] = useState(moment().format("YYYY-MM-DD"))
  let [toDate, setToDate] = useState(moment().format("YYYY-MM-DD"))
  let [orders, setOrders] = useState([]);
  	useEffect(function() {
          Orders.getWithDateRange(fromDate, toDate).then(data => {
            setOrders(data)
          })
  	}, [fromDate, toDate])

  	let pageComponents = {
    	"Order Summary": <OrderSummary from={fromDate} to={toDate} />,
    	"Item Sales": <ItemSales from={fromDate} to={toDate} />,
    	"Cancellations" : <Cancellations orders={orders} from={fromDate} to={toDate}/>
    };

    let pageNames = Object.keys(pageComponents)
    
    return (
    	<>
    	     <PageSkeleton 
                	Main={<Main 
                                  page={page} 
                                  setPage={setPage} 
                                  fromDate={fromDate} 
                                  toDate={toDate} 
                                  setFromDate={setFromDate}
                                  setToDate={setToDate}
                                  orders={orders}
                                  pageComponents= {pageComponents}
                		/>
                	}
                	Rightnav={<QuickDateList onClick={(day) => {
                		setFromDate(day.day)
                		setToDate(day.day)
                	}}/>}
                	Leftnav={<Leftnav setPage={setPage} pageNames={pageNames} />}
                	nav={{leftOpen: true, rightOpen: false }}
                />
    	</>
    )

}

