import React, {useState, useEffect} from 'react'
import classNames from '../../_ClassNames'



export default function CustomerItems({price, set_price, set_page, customer_items, set_customer_items, customer_packages, set_customer_packages, discount, set_discount, show_confirm, onConfirm}) {

	useEffect(function() {
		let total = 0;
		
		for (let item of customer_items) {
			total += item.price;	
		}

		for (let pack of customer_packages) {
			total += pack.price;
		}

		total = total - discount;
		set_price(total)
	}, [customer_items, customer_packages, discount])

	return (
		<div>
			{show_confirm && 
				<button className={`${classNames.confirm_button} text-center col-span-5 col-start-7 xs:col-span-3 xs:col-start-9 sm:col-span-3 sm:col-start-9 md:col-span-2 md:col-start-10 lg:col-span-2 lg:col-start-10 `} onClick={() => {
					if (onConfirm) {
						onConfirm();
					} else {
						set_page(2)
					}
					
				}}>
					Confirm 
				</button>
			}
			<div className=" p-2 md:mb-0">
			      <label className={`${classNames.label} text-xl `}>
			       Total: ${price}
			      </label>
		    </div>

			<div className=" p-2 md:mb-0">
			      <label className={classNames.label}>
			        Discount
			      </label>
			      <input type="number" step='0.1' className={classNames.input} value={discount} onChange={(e) =>{
			      		set_discount(e.target.value)
			      }}/>
		    </div>
		    
		    <div className="overflow-auto customer-items">

		    	{customer_packages && customer_packages.map((item, index) => {
			    	return (
			    		<div key={index} className="border-2 rounded m-2 p-4">
							<button className={`${classNames.item_remove_button}`} onClick={() => {
								customer_packages.splice(index, 1);
								set_customer_packages([...customer_packages])
							}}>
								X
							</button>
							<div className="text-left">
								<div className="mb-2">{item.name}</div>
								<div>${item.price}</div>
									{item.package_items && item.package_items.map(item => {
									return (
										<>
											<div className="pl-5 text-left">	
												<small>
													<div className="w-full">
														<div>{item.name}</div>
														<div className="flex flex-row items-end ">
															<div style={{fontSize: 12}}>({item.quantity})</div>
															<div style={{fontSize: 12}} >x ${item.price}</div>
														</div>
													</div>
												</small>
											</div>
										</>
									)
								})}
							</div>
							
						</div>
			    	)
			    })}

			    {customer_items && customer_items.map((item, index) => {
			    	return (
			    		<div key={index} className="border-2 rounded m-2 p-4">
							<button className={`${classNames.item_remove_button}`} onClick={() => {
								customer_items.splice(index, 1);

								set_customer_items([...customer_items]);
							}}>
								X
							</button>
							<div className="text-left">
								<div className="mb-2">{item.name}</div>
								<div>${item.price}</div>
							</div>
							
						</div>
			    	)
			    })}
		    </div>
		</div>

	)
}