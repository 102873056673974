import React from 'react'
import OrderForm from '../../../Components/Orders/OrderForm'

import PageSkeleton from '../../../Components/PageSkeleton'
import QuickDateList from '../../../Components/SidebarLists/QuickDateList.js'


export default function Customer(props) {
	
	const {set_page, set_date, onClick} = props

		return (
			<PageSkeleton
	        	Main={
	        		<OrderForm 
	        			{...props}
						button_title={"Next"}
						onClick={() => {
							if (onClick) {
								onClick();
							}
							set_page(1);
						}}
	        		/>
	        	}
	        	Rightnav={
	        		null
	        	}
	        	Leftnav={<QuickDateList onClick={(day) => {set_date(day.day)}}/>}
	        	omitSearch={true}
	        	nav={{leftOpen: window.innerWidth < 940 ? false : true, rightOpen: false}}
	        	
	        />
		)

}
