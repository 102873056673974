import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {AiOutlineCloseCircle} from 'react-icons/ai'
import { IconContext } from "react-icons";
import {default as CategoryApi} from '../../Api/Categories';


const grid = 8;
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};



function Category({ category, index, onCategoryClick, onCategoryDelete, setItems }) {
  return (
    <Draggable draggableId={category.name} index={index}>
      {provided => (
        <li className="flex flex-row pt-5 pb-5" style={{
                      width: "200px",
                      border: "1px solid grey",
                      marginBottom: "${grid}px",
                      backgroundColor: "lightblue",
                      padding: `${grid}px`,
                    }}

          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
           <div className="w-full p-2 text-left" onClick={() => {
              setItems(category.items);
              if (onCategoryClick) {
                onCategoryClick(category.items, category)
              }
            }}>
              {category.name}
            </div>

            {onCategoryDelete && 
              <div className="w-full flex justify-end">
                  <IconContext.Provider value={{ color: "red", size: "1.6em", className: "global-class-name" }}>
                    <AiOutlineCloseCircle onClick={() => {
                      onCategoryDelete(category);
                    }}/>
                  </IconContext.Provider> 
              </div>
            }
        </li>
      )}
    </Draggable>
  );
}

const CategoryList = React.memo(function CategoryList({ categories, onCategoryClick, onCategoryDelete, setItems }) {
  return categories.map((category, index) => (
    <Category category={category} onCategoryClick={onCategoryClick} onCategoryDelete={onCategoryDelete} setItems={setItems} index={index} key={category.id} />
  ));
});



export default function DraggableCategories({categories, onCategoryClick, setItems, onCategoryDelete}) {
  const [state, setState] = useState({ categories: categories });

  useEffect(function() {
    setState({categories});
  }, [categories])

  function onDragEnd(result) {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const categories = reorder(
      state.categories,
      result.source.index,
      result.destination.index
    );
    CategoryApi.updateRenderOrder(categories).then(data => {
      console.log(data);
    });
    
    setState({categories});
  }

  if (!state.categories) return null

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="list">
        {provided => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            <CategoryList categories={state.categories} onCategoryClick={onCategoryClick} onCategoryDelete={onCategoryDelete} setItems={setItems} />
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}


