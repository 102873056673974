import React, {useState, useEffect} from 'react'
import OrderList from '../OrderList'
import Orders from "../../Api/Orders"

export default function Cancellations({from,to}) {
  let [orders, setOrders] = useState([])
  let [totals, setTotals] = useState(false)
  useEffect(function() {
  	Orders.getCancellations(from, to).then(data => {
  		setOrders(data.cancellations.orders)
  		setTotals(data.cancellations.orderTotals)
  	})
  }, [from, to])

  return (
	<>
		<div className="p-10 border-b-2">
			<div> Total: $-{totals && totals.price}</div>
			<div>Count: {totals && totals.count}</div>
	  		<OrderList orders={orders} showMap={false} />
		</div>
	</>
  )

}
