import React from 'react'
import {Link} from 'react-router-dom'
import Users from '../../Api/Users';
export default function PageNameList(props) {
	let {setUser} = props;
	return (

		<ul className='space-y-4 p-5'>
			<li className="border-b-2">
				<Link to='/' className="w-full p-2">Home</Link>
    		</li>
    		<li className="border-b-2">
				<Link to='/newOrder' className="w-full p-2">New Order</Link>
    		</li>
    		<li className="border-b-2">
				<Link to='/inventory' className="w-full p-2">Inventory</Link>
    		</li>
    		<li className="border-b-2">
				<Link to='/analytics' className="w-full p-2">Analytics</Link>
    		</li>
    		<li className="border-b-2">
				<Link to='/settings' className="w-full p-2">Settings</Link>
    		</li>
    		{setUser && 
    			<li className="border-b-2">
					<button
	                  className='border-b-2 rounded w-11/12 p-3 ml-2 mt-2'
	                  onClick={async () => {
	                        let data = await Users.logout();
	                        if (data.code === 1){
	                        	setUser(false);
	                        }
	                  }}
	                >
	                    <small className='text-xs'>Logout</small>
	                </button>
	    		</li>
    		}
		</ul>

	)
}