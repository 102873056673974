import React , {useState, useEffect} from 'react'

import PageSkeleton from '../../Components/PageSkeleton'
import Leftnav from './Leftnav';
import Users from '../../Api/Users';
import Login from './Main/Login';
import Register from "./Main/Register"

export default function Authentication({user, setUser}) {
	const [page, setPage] = useState("login")
    
    const pages = {
        login:  <Login setUser={setUser} />,
        register: <Register setUser={setUser} />
    }

    return (
	<>
	     <PageSkeleton 
            	Main={pages[page]}
            	Rightnav={null}
            	Leftnav={<Leftnav setPage={setPage}/>}
            	nav={{
	        		leftOpen: true,
	        		rightOpen: false,
	        	}}
            />
	</>
    )

}
