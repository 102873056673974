import React, {useState, useContext, useEffect} from 'react'
import ApplicationSettingsContext from '../../Context/ApplicationSettings'
import _ClassNames from '../../_ClassNames';

import PaymentTypes from '../../Api/PaymentTypes'


export default function PaymentSettings() {
	let {applicationSettings : settings, setApplicationSettings} = useContext(ApplicationSettingsContext);
	let [show_home_customer_information, setShowHomeCustomerInformation] = useState(settings.show_home_customer_information);
	let [toolTips, setToolTips] = useState(settings.toolTips);
	let [paymentTypes, setPaymentTypes] = useState([])
	let [newPaymentType, setNewPaymentType] = useState("")
	useEffect(function() {
        PaymentTypes.getAll().then(data=> {
            setPaymentTypes(data.payment_types)
        })
    }, [])

	return (
		<>
			<hr className="p-5 mt-5"/>

			<div>
				<h4>Payment Types</h4>
					{paymentTypes && paymentTypes.map((type, index) => {
						return (
							<div className="w-1/2">
								<div className="grid grid-cols-3-1">
									<div className={`${_ClassNames.confirm_button}`}>
										{type.name}
									</div>
									<div className="p-5">
										<div className={`${_ClassNames.item_remove_button} justify-content-center`} onClick={() => {
											PaymentTypes.destroy(type.id).then(data => {
												if (data.code === 1){

													let _payTypes = paymentTypes.filter(_type => _type.id !== type.id)
													setPaymentTypes(_payTypes);
												}
											})
										}}>
											Remove
										</div>
									</div>
								</div>
							</div>
						)
					})}
					<div className="w-1/2">
						<div className="grid grid-cols-3-1">
							<div className="w-full px-3 mb-6 md:mb-0">
						      <label className={_ClassNames.label} for="grid-first-name">
						        Payment Type
						      </label>
						      <input className={_ClassNames.input} value={newPaymentType} onChange={(e) =>{
						      	setNewPaymentType(e.target.value)
						      }}/>
						    </div>
							<div className="p-5">
								<div className={`${_ClassNames.add_item_button} text-center justify-content-center`} onClick={() => {
									PaymentTypes.create({name: newPaymentType}).then(data => {
										if (data.code === 1) {

											setPaymentTypes(prev => {
												return ([...prev, data.payment_types]);
											})
											setNewPaymentType("")
										}
									})
								}} >
									Add
								</div>
							</div>
						</div>
					</div>
			</div>

		</>

	)
}