import React, {useState, useEffect} from 'react'
import moment from 'moment'
import Users from "../../Api/Users"

export default function OrderSummary({from, to}) {
	let [breakdown, setBreakdown] = useState(false)
	let [typePrice, setTypePrice] = useState({price: 0, count: 0});

	useEffect(function() {
		Users.getOrderSummary(from, to).then(data => {
			setBreakdown(data.breakdown)
		})
	}, [from, to]) 


  return (
	<>
			<div className="p-5">
				<div className="mb-5 p-10 border-2 rounded">
					<div className="text-2xl underline">Breakdown</div>
			  		<table className="table-fixed text-left w-full">
					  <thead>
					    <tr>
					      <th className="w-2/4">Name</th>
					      <th className="w-1/4"></th>
					      <th className="w-1/4 text-right">Amount</th>
					    </tr>
					  </thead>
					  <tbody>
					    <tr className="border bg-gray-100">
					      <td>Gross</td>
					      <td></td>
					      <td className='text-right'>${breakdown && breakdown.income.gross}</td>
					    </tr>
					    <tr>
					      <td>Expenses</td>
					      <td></td>
					      <td className='text-right'>${breakdown && breakdown.expenses.total}</td>
					    </tr>
					    <tr className="border bg-gray-100">
					      <td>Discounts: </td>
					      <td></td>
					      <td className='text-right'>$ {breakdown && breakdown.expenses.discounts}</td>
					    </tr>
					     <tr className="" style={{height: "50px"}}>
					      <td></td>
					      <td></td>
					      <td></td>
					    </tr>
					    <tr className="border bg-green-100">
					      <td>Net: </td>
					      <td></td>
					      <td className="text-right">${breakdown && breakdown.income.net}</td>
					    </tr>
					  </tbody>
					</table>
			</div>
	  	</div>

			<div className="p-5">
				<div className="mb-5 p-10 border-2 rounded">
					<div className="text-2xl underline">Expenses</div>
			  		<table className="table-fixed text-left w-full">
					  <thead>
					    <tr>
					      <th className="w-2/4">Name</th>
					      <th className="w-1/4"></th>
					      <th className="w-1/4 text-right">Amount</th>
					    </tr>
					  </thead>
					  <tbody>
					    <tr className="border bg-gray-100">
					      <td>Gas</td>
					      <td></td>
					      <td className='text-right'>-${breakdown && breakdown.expenses.gas}</td>
					    </tr>
					    <tr>
					      <td>Payroll</td>
					      <td></td>
					      <td className='text-right'>-${breakdown && breakdown.expenses.employees}</td>
					    </tr>
					    <tr className="border bg-gray-100">
					      <td>Misc: </td>
					      <td></td>
					      <td className='text-right'>-$ {breakdown && breakdown.expenses.misc}</td>
					    </tr>
					     <tr className="" style={{height: "50px"}}>
					      <td></td>
					      <td></td>
					      <td></td>
					    </tr>
					    <tr className="border bg-red-100">
					      <td>Total: </td>
					      <td></td>
					      <td className="text-right">-$ {breakdown && breakdown.expenses.total}</td>
					    </tr>
					  </tbody>
					</table>
			</div>
				{/*</div>*/}
	  	</div>



	</>
  )

}
