import React, {useState, useEffect} from 'react'
import { GoogleMap, useJsApiLoader, Marker, DirectionsService,DirectionsRenderer } from '@react-google-maps/api';
import Geocode from "react-geocode";


const containerStyle = {
  width: '90%',
  height: '500px',
  margin: "auto",
  marginTop: "50px",
};

const center = {
  lat: -3.745,
  lng: -38.523
};

Geocode.setApiKey("AIzaSyBsXn4UceQN0y8zdryVqEGGhNJXV6t02DU");

export default function MapRoute({customers, style}) {

	const [map, setMap] = React.useState(null)
	const [markers, setMarker] = useState([])
	const [directionResponse, setDirectionResponse] = useState(false)
	const [origin, setOrigin] = useState('2353 del mar court tracy ca')
	const [endDestination, setEndDestination] = useState('2353 del mar court tracy ca');


	const { isLoaded } = useJsApiLoader({
	    id: 'google-map-script',
	    googleMapsApiKey: "AIzaSyBsXn4UceQN0y8zdryVqEGGhNJXV6t02DU"
	})

  	const onMapLoad = React.useCallback(function callback(map) {
    	setMap(map)
  	}, [])


  	const onMarkerLoad = React.useCallback(function callback(map) {
    	setMap(map)
  	}, [])

	const onUnmount = React.useCallback(function callback(map) {
		setMap(null)
	}, [])

	useEffect(function() {
			async function geo() {
				let mapPlots = [];
				for (let customer of customers) {
					let res = await Geocode.fromAddress(`${customer.address} ${customer.city}`).catch(err => console.log(err))
					if (res) {
						const { lat, lng } = res.results[0].geometry.location;	
						mapPlots.push({lat: lat, lng: lng});
					}
					
				}
				setMarker(mapPlots);
			}	
			geo()
	}, [customers])

	useEffect(function() {
		if (!map) return false
		
		let directionsService = new window.google.maps.DirectionsService();
		    const routesCopy = markers.map(marker => {
		      return {
		        location: { lat: marker.lat, lng: marker.lng },
		        stopover: true
		      };
		    });

		    directionsService.route(
	          {
	            origin: origin,
	            destination: origin,
	            travelMode: "DRIVING",
	            waypoints: routesCopy,
	          },
	          (result) => {
	            if (result.status === "OK") {
	              	setDirectionResponse({directions: result})
	            } else {
	            	console.log(result)	
	            }
	          }
	        )
	}, [map, markers])

	return (
	  (isLoaded && markers.length) ? (
			<GoogleMap
				mapContainerStyle={style || containerStyle}
				center={markers[0]}
				zoom={10}
				onLoad={onMapLoad}
				onUnmount={onUnmount}
			>
			
			{directionResponse !== null &&
					<DirectionsRenderer
					  // required
					  options={directionResponse}
					  // optional
					  // onDirectionsChanged={() => 	console.log("Directions Changed")}
					  onLoad={directionsRenderer => {
					    // console.log('DirectionsRenderer onLoad directionsRenderer: ', directionsRenderer)
					  }}
					  // optional
					  onUnmount={directionsRenderer => {
					    console.log('DirectionsRenderer onUnmount directionsRenderer: ', directionsRenderer)
					  }}
					/>
			}

	        
	      </GoogleMap>
	  ) : <></>
	)



}
