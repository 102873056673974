import React, {useState} from 'react'
import OrderForm from '../../../Components/Orders/OrderForm'

import PageSkeleton from '../../../Components/PageSkeleton'
import QuickDateList from '../../../Components/SidebarLists/QuickDateList.js'
import SearchCustomers from '../../../Components/Search/SearchCustomers'
import {BsSearch} from 'react-icons/bs'
import classNames from '../../../_ClassNames'
import { IconContext } from "react-icons";


export default function Customer(props) {
	
	const {set_page, set_date} = props
	const [searchCount, setSearchCount] = useState(0);
		return (
			<PageSkeleton
	        	Main={
	        		<OrderForm 
	        			{...props}
						button_title={"Next"}
						onClick={() => {
							set_page(1);
						}}
	        		/>
	        	}
	        	Rightnav={
	        		<Search {...props} setSearchCount={setSearchCount}/>
	        	}
	        	Leftnav={<QuickDateList onClick={(day) => {set_date(day.day)}}/>}
	        	omitSearch={true}
	        	nav={{leftOpen: window.innerWidth < 940 ? false : true, rightOpen: window.innerWidth < 940 ? false : true}}
	        	rightToggleIcon={<IconContext.Provider value={{ color: "blue", size: "1.2em" }}><div className="flex flex-row"><BsSearch /><div className="text-lg">({searchCount})</div></div></IconContext.Provider>}
	        />
		)

}


function Search({customers, first_name, set_customer, set_first_name, set_last_name, set_address, set_phone, set_city, set_email, set_notes, setSearchCount}) {
	return (
		<div>
			Customer Search
			<button className={`${classNames.clear_button}`} onClick={() => {
				set_customer(false);
				set_first_name("");
				set_last_name("");
				set_address("");
				set_phone("");
				set_city("");
				set_email("")
				set_notes("")
			}}>
				Clear				
			</button>
			<div className="customer-add-search overflow-auto w-full">
				<SearchCustomers customers={customers} search={first_name} 

				onClick={(customer) => {
					set_customer(customer)
					set_first_name(customer.first_name);
					set_last_name(customer.last_name);
					set_address(customer.address);
					set_phone(customer.phone_number);
					set_city(customer.city);
					set_email(customer.email)
					set_notes(customer.notes)

				}}
				onFindEffect={(matches) => {
						setSearchCount(matches.length)
				}}
				/>
			</div>
		</div>
	)
}
