
import React, {useState, useEffect} from 'react';
import ItemGrid from '../Inventory/ItemGrid'
import PackageGrid from '../Inventory/PackageGrid'
import OrderPackageGrid from '../Inventory/OrderPackageGrid'

export default function OrderItems(props) {
	
	const {
		set_page, 
		items, 
		customer_items, 
		set_customer_items,
		customer_packages,
		set_customer_packages,
		packages, 
		discount,
		set_discount,
		price,
		set_price,
		isPackage,
		isPreviousOrder,
		previousOrderItems,
		previousOrderPackages,
		removeBackButton,
		skipGrid,
	} = props;

	let classNames = {
		input: 'appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500',
		label: "block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2",
		customer_button: "w-full mt-3 bg-white hover:bg-green-100 text-green-800 font-semibold py-2 px-4 border border-green-400 rounded shadow text-left",
		item_remove_button: "text-xs float-right bg-white hover:bg-red-100 text-red-800 font-semibold py-1 px-2 border border-red-400 rounded shadow text-center",
		back_button: "ml-5 mt-3 bg-white hover:bg-red-100 text-red-800 font-semibold py-2 px-4 border border-red-400 rounded shadow text-left",
		confirm_button: "ml-5 mt-3 bg-white hover:bg-green-100 text-green-800 font-semibold py-2 px-4 border border-green-400 rounded shadow text-left",
	}
	
	return (
			<div>
				{!removeBackButton && 
					<div className="p-5">
						<button 
							className={`${classNames.back_button} col-span-2 text-center col-span-5 sm:col-span-3 md:col-span-2 lg:col-span-2 `} 
							onClick={() => set_page(0)}
						>
							Back
					    </button>
			    	</div>
			    }
			    <hr />

				<div className="pt-4 grid text-black">
					<div className="md:overflow-auto lg:overflow-auto xl:overflow-auto add-customer-items">
						
						{isPreviousOrder && 
							<>
							<div className="p-5">
							Packages
							<OrderPackageGrid order_packages={previousOrderPackages} showAlways={true} onClick={(pack) => {
								set_customer_packages((prevState) => ([
						 		 ...prevState,
								 {
								 	name: pack.name,
								 	price: pack.price,
								 	description: pack.description,
								 	quantity: pack.quantity,
								 	package_items: pack.order_package_items, 
								 },
								]));

							}} />
							</div>
							<div className="p-5">Items</div>
							<ItemGrid items={previousOrderItems} onClick={(item) => {
									set_customer_items((prevState) => ([
							 		 ...prevState,
									 item,
									]));
								}} 
							/>
							</>

						}

						{isPackage && 
							<PackageGrid packages={items} onClick={(pack) => {
								set_customer_packages((prevState) => ([
						 		 ...prevState,
								 pack,
								]));
							}} />
						}

						{!isPackage && !isPreviousOrder && <ItemGrid skipGrid={skipGrid} items={items} onClick={(item) => {
								set_customer_items((prevState) => ([
						 		 ...prevState,
								 item,
								]));
							}} 
						/>
						}
					</div>
	
				</div>
			</div>
		)
}




