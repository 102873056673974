import React, {useState, useEffect} from 'react';
import {FaTruck} from 'react-icons/fa'

import { IconContext } from "react-icons";

const grid = 8;

const getItemStyle = () => ({
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,
  background:  'white',
});

const getListStyle = () => ({
  background: 'white',
  padding: grid,
  width: "100%",
  margin: "auto",
  marginBottom: 50,
});

function reverse(array) {
  let reversed = [];
  if (!array) return reversed;

  for (let i = array.length - 1; i>=0; i--) {
    reversed.push(array[i]);
  }
  return (reversed)

}

export default function DeliveryLoadPrintList({customers, title}) {

  let [reversed, setReversed] = useState(reverse(customers))

  useEffect(function() {
    setReversed(reverse(customers));
  }, [customers])

     return (
      <div className="p-10" style={{pageBreakBefore: "always"}}>
        <div className="m-auto text-center p-5 text-3xl"> {title} </div>
        
        <div className="rotate-90">
            <IconContext.Provider value={{ color: "black", size: "3em", className: "m-auto w-full p-5 text-3xl" }}>
                  <FaTruck /> 
            </IconContext.Provider>   
        </div>  
        
        <div  style={getListStyle()} >
            {reversed.map((customer, index) => (
                <div>
                    <div style={getItemStyle()} >
                        <div className='w-full'>
                            <div className="grid xs:grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2">
                                {customer.order_items && customer.order_items.map((item, index) => {
                                    return (<div className="text-left border-b-2 w-1/2 m-auto flex flex-row">
                                        <div className="w-full">{item.name}{' '}</div>
                                        <div className="w-full text-right">({item.quantity})</div>
                                    </div>)  
                                })}
                            </div>
                        </div>
                    </div>

                </div>
            ))}

        </div>
      </div>
     );
   
}