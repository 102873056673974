 
 	import axios from "axios";
 	import config from "../../Configuration";

 	const routePrefix = `${config.getHost()}/emailer`
	 
 export default {

		
		async sendRoutes(data) {
			let res = await axios.post(`${routePrefix}/sendRoutes`, data).catch(err => alert("Something went wrong"))
			return res.data
		},

		




}
	