import React, {useState, useEffect, useContext} from 'react'

import Categories from '../../Api/Categories'
import Packages from '../../Api/Packages'
import Customers from '../../Api/Customers'
import Orders from '../../Api/Orders'
import {useParams} from 'react-router-dom'
import ApplicationSettingsContext from '../../Context/ApplicationSettings'
import moment from 'moment'
import Items from './Items'
import Customer from './Customer'
import {useHistory} from 'react-router-dom'


export default function EditOrder() {
	
	let {orderId} = useParams();
	let history = useHistory();

	let {applicationSettings : settings, setApplicationSettings} = useContext(ApplicationSettingsContext);
	const [customerId, setCustomerId] = useState(false)
	//const [page, set_page] = useState(settings.show_customer_information_form ? 0 : 1);
	const [page, set_page] = useState(0);
	const [customer, set_customer] = useState(false);
	const [first_name, set_first_name] = useState("")
	const [last_name, set_last_name] = useState("")
	const [date, set_date] = useState(moment().format('YYYY-MM-DD'))
	const [time, set_time] = useState("09:00")
	const [address, set_address] = useState("")
	const [latitude, setLatitude] = useState(false);
	const [longitude, setLongitude] = useState(false);

	const [city, set_city] = useState("")
	const [phone, set_phone] = useState("")
	const [email, set_email] = useState("")
	const [notes, set_notes] = useState("")
	

	const [discount, set_discount] = React.useState(0);
	const [price, set_price] = React.useState(0);

	const [items, setItems] = useState([]);
	const [packages, setPackages] = useState([]);
	const [categories, setCategories] = useState([]);

	const [customers, setCustomers] = useState([]);

	const [customer_items, set_customer_items] = useState([]);
	const [customer_packages, set_customer_packages] = useState([]);
	const [previous_orders, set_previous_orders] = useState(false);

	useEffect(function() {
          if (orderId) {
            Orders.getOneWithAllAssociations(orderId).then(data => {
              set_discount(data.orders.discount);
              setCustomerId(data.orders.customer.id)
              set_customer(data.orders.customer);
              set_first_name(data.orders.first_name);
              set_last_name(data.orders.last_name)
              set_date(data.orders.rental_date)
              set_time(data.orders.rental_time)
              set_address(data.orders.address)
              set_city(data.orders.city)
              set_phone(data.orders.phone_number)
              set_email(data.orders.email)
              set_notes(data.orders.notes)
              setLatitude(data.orders.latitude);
              setLongitude(data.orders.longitude);

              set_customer_items(data.orders.order_items || []);
              set_customer_packages(data.orders.order_packages || []);
            })
          }
	}, [orderId])

	useEffect(function() {
          Categories.getWithAllAssociations().then(data => {
            setItems(data.categories[0].items)
            setCategories(data.categories)
          })

          Packages.getWithAllAssociations().then(data => {
            setPackages(data.packages)
          })
	}, [])

	async function updateCustomer() {
          let data = await Orders.update(orderId, {
            first_name: first_name,
            last_name: last_name,
            rental_date: date,
            rental_time: time,
            address: address,
            city: city,
            phone_number: phone,
            email: email,
            notes: notes,
          })
	}

	async function updateOrder() {

          let order_items = [];
          let order_packages = [];

          for (let i of customer_items) {
            order_items.push({
              name: i.name,
              price: i.price,
              quantity: i.quantity,
              description: i.description,
              existing: i.existing || false
            })
          }


          for (let pack of customer_packages) {
            let order_package_items = [];
            //comes from customers order / order_packages
            if (pack.order_package_items) {
              for (let order_package_item of pack.order_package_items) {
                order_package_items.push({
                  name: order_package_item.name,
                  price: order_package_item.price,
                  quantity: order_package_item.quantity,
                })
              }
            }

            //comes from plain packages
            if (pack.package_items) {
              for (let order_package_item of pack.package_items) {
                order_package_items.push({
                  name: order_package_item.name,
                  price: order_package_item.price,
                  quantity: order_package_item.quantity,
                })
              }
            }


            order_packages.push({
              name: pack.name,
              price: pack.price,
              quantity: pack.quantity,
              order_package_items: order_package_items,
            })
          }

          Orders.updateWithOrderItems(orderId, {
            price: price,
            canceled: false,
            discount: discount,
            latitude: latitude,
            longitude: longitude,
            order_items: order_items,
            order_packages: order_packages,
          }).then(data => {
            if(data.code === 1) {
              history.push("/")
            } 
          }).catch(err => {
            console.log(err);
          })
        }

	if (page === 0) {
		return (<Customer 
					id={customerId}
	    			set_page={set_page}
	    			customer={customer}
					set_customer={set_customer}
					first_name={first_name}
					set_first_name={set_first_name}
					last_name={last_name}
					set_last_name={set_last_name}
					date={date}
					set_date={set_date}
					time={time}
					set_time={set_time}
					address={address}
					set_address={set_address}
					city={city}
					latitude={latitude}
					setLatitude={setLatitude}
					longitude={longitude}
					setLongitude={setLongitude}
					set_city={set_city}
					phone={phone}
					set_phone={set_phone}
					email={email}
					set_email={set_email}
					notes={notes}
					set_notes={set_notes}
					onClick={updateCustomer}
	    		/>)
	}
	if (page === 1){
		return (
		<Items 
			set_page={() => set_page(0)} 
			items={items} 
			packages={packages} 
			customer_items={customer_items} 
			set_customer_items={set_customer_items}
			customer_packages={customer_packages}
			set_customer_packages={set_customer_packages}
			price={price}
			set_price={set_price}
			discount={discount}
			set_discount={set_discount}
			categories={categories} 
			setItems={setItems}
			previous_orders={previous_orders}
			removeBackButton={false}
			show_confirm={true}
			onConfirm={async () => {
				await updateOrder();
			}}
		/>
	)
	}
	
	

}


