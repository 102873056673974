let config =  {
	getHost: function() {
		if (process.env.NODE_ENV === "development") {
			return process.env.REACT_APP_DEV_API_URL
		} else {
			return process.env.REACT_APP_API_URL;
		}
	}

}


export default config