import React from 'react'


export default function ShowItems({order}) {
  if (! order) return null

  return (
    <div className='customer-list-products text-left'>
      <div className='customer-list-price font-medium mt-5 sm:grid sm:grid-cols-2 md:grid md:grid-cols-2 lg:grid lg:grid-cols-2 xl:grid xl:grid-cols-2 w-full '>
      <div>
        {order && order.order_packages.map((pack, index) => {
          return (
            <div key={`pack-${pack.name}-${index}`} className="text-xs pb-1">
              <div className="underline">{pack.name}</div>
              {pack.order_package_items.map((item, index) => {
                return (<div key={`packitem-${item.name}-index}`} className="">{item.name}</div>)
              })}
            </div>
          )
        })}
      </div>
      <div>
      {order.order_items.map((order_item, i)=> {
        return (
          <div key={`orderitem-${i}`} className='text-xs'>
            {order_item && order_item.name} 
          </div>
        )
      })}
      </div>
      </div>
    </div>
  )
}
