import React, {useState, useEffect} from 'react'

import OrderItems from '../../../Components/Orders/OrderItems'
import ShowCart from '../../../Components/Orders/ShowCart'

import PageSkeleton from '../../../Components/PageSkeleton'
import CategoryList from '../../../Components/SidebarLists/CategoryList.js'
import {BiCart, BiCategoryAlt} from 'react-icons/bi'
import { IconContext } from "react-icons";
import classNames from '../../../_ClassNames'

export default function Items(props) {
		const [isPackage, setIsPackage] = useState(false)
		const [isPreviousOrder, setIsPreviousOrder] = useState(false)
		const [previousOrderitems, setPreviousOrderItems] = useState([])
		const [previousOrderPackages, setPreviousOrderPackages] = useState([])

		return (
			<PageSkeleton
	        	Main={<OrderItems 
	        			{...props} 
	        			isPackage={isPackage} 
	        			isPreviousOrder={isPreviousOrder}
						previousOrderItems={previousOrderitems}
						previousOrderPackages={previousOrderPackages}
						removeBackButton={true}
					/>}
	        	Rightnav={<ShowCart {...props} />}
	        	Leftnav={
	        			<CategoryList 
	        				{...props}
	        				onBackButtonClick={() => {
	        					props.set_page(0)
	        				}}
	        				onPackageClick={(packages) => {
	        					setIsPackage(true);
	        					setIsPreviousOrder(false)
	        				}} 

	        				onCategoryClick={(items) => {
	        					setIsPackage(false);
	        					setIsPreviousOrder(false);
	        				}}
	        				onPreviousOrderClick={(order) => {
	        					setIsPackage(false)
	        					setIsPreviousOrder(true)

	        					setPreviousOrderItems(order.order_items)
	        					setPreviousOrderPackages(order.order_packages);
	        				}}

	        			/>
	        	}
	        	rightToggleIcon={<IconContext.Provider value={{ color: "blue", size: "2em" }}><div className="flex flex-row"><BiCart /><div className="">({props.customer_items.length})</div></div></IconContext.Provider>}
	        	leftToggleIcon={<IconContext.Provider value={{ color: "blue", size: "2em" }}><div className="flex flex-row"><BiCategoryAlt /></div></IconContext.Provider>}
	        	omitSearch={true}
	        	nav={{leftOpen: true, rightOpen: window.innerWidth < 940 ? false : true}}
	        />

		)
	
}

