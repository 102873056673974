import React, {useState, useEffect} from 'react' 
import Items from '../../Api/Items'
import Packages from '../../Api/Packages'
import PackageItems from '../../Api/PackageItems'


let classNames = {
	input: 'appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500',
	label: "block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2",
	customer_button: "w-11/12 ml-3 mt-3 bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 mb-5 border border-gray-400 rounded shadow text-left",
	cancel_button: "w-11/12 ml-3 mt-3 bg-white hover:bg-red-100 text-red-800 font-semibold py-2 px-4 mb-5 border border-red-400 rounded shadow text-left",
	remove_button: "mt-8 bg-white hover:bg-red-100 text-red-800 font-semibold py-2 px-6 border border-red-400 rounded shadow text-right text-xs",

}

export default function PackageEdit({currentPackage, setCurrentPackage, setEditingPackage, setPackages}) {

	if (!currentPackage) {
		return null
	}

	function createPackage() {
		Packages.create(currentPackage).then(data => {
			setCurrentPackage(false)
			setPackages(data.packages);
		})
	}

	function updatePackage() {
		Packages.bulkEdit(currentPackage.id, currentPackage).then(data => {
			setPackages(data.packages);
		})

		
	}

	return(
			<div className="w-full max-w-lg p-5">
			<div className="underline">Editing Package</div>
				<div className="grid grid-cols-2">
				  	
				  	<button className={classNames.customer_button} onClick={() => {
				  		if (currentPackage.id) {
				  			updatePackage();
				  		} else {
				  			createPackage();
				  		}
				  		setEditingPackage(false);
				  	}}>Save</button>

				  	<button className={classNames.cancel_button} onClick={() => {
				  		setCurrentPackage(false)
				  		setEditingPackage(false);
				  	}}>Cancel</button>  
			    </div>
			    
			    <div className="w-full px-3 mb-6 md:mb-0">
			      <label className={classNames.label} for="grid-first-name">
			        Package Name
			      </label>
			      <input 
			      		className={classNames.input} 
			      		value={currentPackage.name} 
				      	onChange={(e) =>{
					      	setCurrentPackage((prevState) => {
					      		return (
					      			{...prevState, name: e.target.value}
					      		)
					      	})
			      }}/>
			    </div>
			    
			    <div className="w-full px-3">
			      <label className={classNames.label} for="grid-last-name">
			        Price
			      </label>
			       <input 
			       		type="number"
			       		step="0.1"
			      		className={classNames.input} 
			      		value={currentPackage.price} 
				      	onChange={(e) =>{
					      	setCurrentPackage((prevState) => {
					      		return (
					      			{...prevState, price: e.target.value}
					      		)
					      	})
			      }}/>
			    </div>
			 	
			    <div className="w-full px-3 mb-6 md:mb-0">
			      <label className={classNames.label} for="grid-first-name">
			        Description
			      </label>
			      <input 
			      		className={classNames.input} 
			      		value={currentPackage.description} 
				      	onChange={(e) =>{
					      	setCurrentPackage((prevState) => {
					      		return (
					      			{...prevState, description: e.target.value}
					      		)
					      	})


			      }}/>
			    </div>
			    
			    {currentPackage.package_items && currentPackage.package_items.map((package_item, index) => {
			    	return (
			    		<div key={index}>

				    		<div className="">
						      <div className="pb-5 pt-5 text-left">{package_item.name}</div>
						    </div>
						    <div className="flex flex-row">

							    <div className="w-full">
							      <label className={classNames.label} for="grid-last-name">
							        Price
							      </label>
							       <input 
							       		type="number"
							       		step="0.1"
							      		className={classNames.input} 
							      		value={package_item.price} 
								      	onChange={(e) =>{
									      	let pack = {...currentPackage}
									      	let price = 0;
											pack.package_items[index].price = e.target.value;

											for (let i in pack.package_items) {
							    				price += pack.package_items[i].price * pack.package_items[i].quantity
							    			}
							    			pack.price = price
							    			setCurrentPackage(pack)
							      }}/>
							    </div>
							     <div className="w-full px-6">
							      <label className={classNames.label} for="grid-last-name">
							        Quantity
							      </label>
							       <input 
							       		type="number"
							      		className={classNames.input} 
							      		value={package_item.quantity} 
								      	onChange={(e) =>{
											let pack = {...currentPackage}
											let price = 0;

											pack.package_items[index].quantity = parseInt(e.target.value);
											for (let i in pack.package_items) {
												price += parseFloat(pack.package_items[i].price) * parseInt(pack.package_items[i].quantity)
							    			}
							    			pack.price = price
							    			setCurrentPackage(pack)

							      }}/>
							    </div>
							    <div className='w-full mb-6'>
							    	<button className={classNames.remove_button} onClick={async () => {
							    		let pack = {...currentPackage}
							    		
							    		let price = 0;
							    		
							    		if (package_item.id) {
							    			let data = await PackageItems.destroy(package_item.id)	
							    		}

										pack.package_items.splice(index, 1);					    		
							    		
							    		for (let i in pack.package_items) {
							    			price += pack.package_items[i].price * pack.package_items[i].quantity
							    		}

							    		pack.price = price;
							    		setCurrentPackage(pack)

							    	}}>Delete</button>
						    	</div>
							</div>
							<hr />
						   
					    </div>
			    	)
			    })}

			  
			</div>
	) 

}