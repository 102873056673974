import React, {useState, useEffect, useRef} from 'react'
import {useParams, useHistory} from 'react-router-dom'


import Orders from '../../Api/Orders';
import QuickDateList from '../../Components/SidebarLists/QuickDateList.js'
import PageSkeleton from '../../Components/PageSkeleton'

import Leftnav from './Leftnav'
import Main from './Main'


export default function CreateRoute() {
	let {searchType, search} = useParams();
	let history = useHistory();
	let [customerList, setCustomerList] = useState(false)
	let [loadList, setLoadList] = useState([])
	let [price, setPrice] = useState(0)
	let [employees, setEmployees] = useState([]);
	
	let [currentMap, setCurrentMap] = useState(-1);
	let [customerCount, setCustomerCount] = useState(0);

	const printRef = useRef();

	useEffect(function() {
		Orders.getByDate(search).then(data => {
			setCustomerCount(data.orders.length)
			setCustomerList(data.orders)
			let price = 0;
			for (let order of data.orders) {
				price += order.price;
			}
			setPrice(price)
		});
	}, [search])


	return (
		 <PageSkeleton 
            	Main={<Main 
						customers={customerList} 
						setCustomers={setCustomerList} 
						loadList={loadList} 
						setLoadList={setLoadList} 
						employees={employees} 
						setEmployees={setEmployees} 
						printRef={printRef}
						currentMap={currentMap}
						setCurrentMap={setCurrentMap}
				/>}

            	Rightnav={<QuickDateList onClick={(day) => {history.push(`/search/date/${day.day}`)}} />}

            	Leftnav={<Leftnav search={search} price={price} customerCount={customerCount} printRef={printRef} />}

            	nav={{
	        		leftOpen: true,
	        		rightOpen: false,
	        	}}
            />

	)
}

