import React, {useState, useEffect} from 'react'

import {FaBars} from 'react-icons/fa'
import {AiOutlineCalendar} from 'react-icons/ai'
import {BsPersonLinesFill, BsCalendar} from 'react-icons/bs'
import PageList from '../SidebarLists/PageList'
import Topnav from './Topnav'

import moment from 'moment'
import { useBottomScrollListener } from 'react-bottom-scroll-listener';
 
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
  useHistory,
} from "react-router-dom";
import { IconContext } from "react-icons";

import './style.css'
 	

export default function PageSkeletion(props) {

	const {nav, onScrollBottom} = props;

	const history = useHistory();
	const [collapseLeft, setCollapseLeft]  =  useState((nav && nav.leftOpen) ? "" : 'collapse-left')
	const [collapseRight,setCollapseRight]  =  useState((nav && nav.rightOpen) ? "" : 'collapse-right')
	const [collapseAll, setCollapseAll]  =  useState('collapse-all')
	
	const scrollRef = useBottomScrollListener(() => {
		if (onScrollBottom) {
			onScrollBottom();
		}
	});
	
	useEffect(function () {
		if (collapseLeft && collapseRight) {
			setCollapseAll('collapse-all')
		} else {
			setCollapseAll('')
		}

	}, [collapseLeft, collapseRight])

	
  	return (
		<div className={'page-skeleton-container ' + collapseLeft + ' ' + collapseRight + ' ' + collapseAll}>
			<Topnav 
					collapseLeft={collapseLeft}
					setCollapseLeft={setCollapseLeft}
					collapseRight={collapseRight}
					setCollapseRight={setCollapseRight}
					showRightToggle={props.Rightnav ? true : false}
					showLeftToggle={props.Leftnav ? true : false}
					rightToggleIcon={props.rightToggleIcon || false}
					leftToggleIcon={props.leftToggleIcon || false}
					omitSearch={props.omitSearch || false}
			/>

		    <div className='leftnav'>
		    	<PageList />
					{props.Leftnav}
		    </div>

		    <div ref={scrollRef} className='main w-full'>
		        {props.Main}
		    </div>

		    <div className='rightnav'>
				{props.Rightnav}
		    </div>
		</div>

	)
}