import React from 'react'
import CategoryList from '../../../Components/SidebarLists/CategoryList'
import ModifierCategories from '../../../Components/SidebarLists/ModifierCategories'

import Categories from '../../../Api/Categories'
import {default as ModifierCategoriesApi} from '../../../Api/ModifierCategories'

import AddCategory from '../../../Components/Inventory/AddCategory'
import classNames from '../../../_ClassNames'
import swal from 'sweetalert';

export default function Leftnav(props) {
	

	const {		
		page,
		setPage,
		categories,
		setCategories,
		setCurrentCategory,
		setItems,
		packages,
		modifierCategories,
		setModifierCategories,
		onModifierCategoryDelete,
		onModifierCategoryClick,
	} = props
	
	return (
		<>	
			<div className=" w-full p-1 grid grid-cols-2 gap-1">
				<button className={`text-xs ${page === "items" ? classNames.inventory.show_items_selected : classNames.inventory.show_items}`} onClick={() => setPage("items")}>Items</button>
				<button className={`text-xs ${page === "modifiers" ? classNames.inventory.show_modifiers_selected : classNames.inventory.show_modifiers}`} onClick={() => setPage("modifiers")}>Modifiers</button>
			</div>

			{page === "modifiers" && 
				<>
					<AddCategory 
						onSubmit={(newCategory) => {
							ModifierCategoriesApi.create({name: newCategory}).then(data => {
								setModifierCategories(data.modifier_categories)
							})
						}}
					/>
					<ModifierCategories 
						modifierCategories={modifierCategories} 
						modifiers={[]} 
						onDelete={(modifierCategory) => onModifierCategoryDelete(modifierCategory)} 
						onClick={(modifier, index) => onModifierCategoryClick(modifier, index)} />
				</>
			}

			{page === "items" && 
				<>
				<AddCategory 
					onSubmit={(newCategory) => {
						Categories.create({name: newCategory}).then(data => {
							setCategories(data.categories)
						})
					}}
				/>

				<CategoryList 
					categories={categories} 
					setCategories={setCategories}
					setItems={setItems}
					packages={packages}
					onCategoryClick={(items, category) => { 
						setCurrentCategory(category)
					}}
					onPackageClick={() => {
						setCurrentCategory({name: "Packages"})
					}}
					onCategoryDelete={(category) => {
						swal({
						  title: `Delete ${category.name}?`,
						  text: `This will Delete all items in ${category.name}!`,
						  icon: "warning",
						  buttons: true,
						  dangerMode: true,
						})
						.then((ok) => {
						  if (ok) {
						    Categories.destroy(category.id).then(data => {
						    	setCategories(_categories => {
						    		
						    			let _c = _categories.filter(_category => {
						    				return (category.id !== _category.id)
						    			})
						    			return _c;
						    		
						    	})
						    })
						  } else {
						    
						  }
						});
					}}
					previous_orders={false}
				/>
				</>
			}
		</>
	)
}




