import React, {useState, useEffect} from 'react';

import classNames from '../../_ClassNames';

import {DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import Employees from '../../Api/Employees';
import Emailer from '../../Api/Emailer';
import swal from 'sweetalert'
const reorder =  (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const move = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);

    destClone.splice(droppableDestination.index, 0, removed);

    const result = {};
    result.source = sourceClone;
    result.destination = destClone;

    return result;
};


const grid = 8;

const getItemStyle = (draggableStyle, isDragging) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,
  
  // change background colour if dragging
  background: isDragging ? 'lightgreen' : 'white',
  borderTop: "1px solid black",
  borderBottom: "1px solid black",
  borderRadius: "10px",
  
  // styles we need to apply on draggables
  ...draggableStyle
});

const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? 'lightblue' : 'white',
  padding: grid,
  width: "90%",
  margin: "auto",
  marginBottom: 10,
  borderRadius: "10px",
  border: "1px solid black"
});


function Title({title}) {
  return (
    <div className="text-xl text-center p-2 pt-5">
      {title}
    </div>

  )
}

export default function DeliveryLoadList({customers, setCustomers, loadList, setLoadList, employees, setEmployees, currentMap, setCurrentMap}) {
  useEffect(function() {
    Employees.getDrivers().then(data => {
      setEmployees(data.employees)

      if (data.employees.length) {
        for (let _employee of data.employees) {
            _employee.customers = [];
        }
      }
    })
  }, [])

  function onDragEnd (result) {
    const { source, destination } = result;

        if (!destination) {
            return;
        }
        if (source.droppableId === destination.droppableId) {
          if (source.droppableId === 'droppable') {
              const items = reorder(
                  customers,
                  source.index,
                  destination.index
              );
              setCustomers(items);
          }
           

        employees.map(_employee => {
            if (source.droppableId === `droppable${_employee.id}`) {
              const items = reorder(
                _employee.customers,
                source.index,
                destination.index
              );

              setEmployees(_old => {
                let tmp = _old.map(_emp => {
                    if (_emp.id === _employee.id) {
                      _emp.customers = items;
                    }
                    return _emp;
                })
                return tmp
              })
            }
          })
        } else {
           //main list

          let srcList;
          let destList;

          if (source.droppableId === "droppable") {
              srcList = customers;
          }

          if (destination.droppableId === "droppable") {
              destList = customers;
          }



          employees.map(_employee => {
            if (source.droppableId === `droppable${_employee.id}`) {
                srcList = _employee.customers;
            }

            if (destination.droppableId === `droppable${_employee.id}`) {
              destList = _employee.customers;
            }
          })

          const result = move(
              srcList,
              destList,
              source,
              destination
          );

         

          if (source.droppableId === "droppable") {
              setCustomers(result.source);
          } 
          if (destination.droppableId === "droppable") {
              setCustomers(result.destination);
          } 

          employees.map(_employee => {
                if (source.droppableId === `droppable${_employee.id}`) {
                  setEmployees(_old => {
                    let tmp = _old.map(_emp => {
                        if (_emp.id === _employee.id) {

                          _emp.customers = result.source;
                        }
                        return _emp;
                    })
                    return tmp;
                  })
                }

                if (destination.droppableId === `droppable${_employee.id}`) {
                  setEmployees(_old => {
                    let tmp = _old.map(_emp => {
                        if (_emp.id === _employee.id) {
                          _emp.customers = result.destination;
                        }
                        return _emp;
                    })
                    return tmp
                  })
                }
            })
        }
  }



     return (
      <>
      <div className="text-center p-5 text-3xl"> Arrange Route</div>
      <div className="grid grid-cols-2">

       <DragDropContext onDragEnd={onDragEnd}>
       <div>
       <div className="p-5">
          <label className="text-lg">Show On Map </label>
          <input type="radio" name="map_selection" value={-1} checked={currentMap === -1 ? true : false} onClick={(e) => {setCurrentMap(-1)}}/>
        </div>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div 
              ref={provided.innerRef} 
              style={getListStyle(snapshot.isDraggingOver)}
              {...provided.droppableProps}
            >
            
            <Title title={'Main List'} />
            
            
              {customers.map((customer, index) => (
                <Draggable
                  key={customer.id}
                  draggableId={customer.id.toString()}
                  index={index}
                >
                  {(provided, snapshot) => (
                    <div>
                      <div
                        ref={provided.innerRef}
                        {...provided.dragHandleProps}
                        {...provided.draggableProps}
                        style={getItemStyle(
                          provided.draggableProps.style,
                          snapshot.isDragging
                        )}
                      >
                        <div className="grid grid-cols-2">
                          <div className='w-full'>
                            <div className="border-b-2">Stop: {String.fromCharCode(66 + index)}</div>
                            <div className="grid grid-cols-2">
                            {customer && customer.order_packages.map((pack, index) => {
                              return (
                                <div className="text-xs pb-1">
                                  <div className="underline">{pack.name}</div>
                                  {pack.order_package_items.map((item, index) => {
                                    return (<div className=" pl-3">{item.name}</div>)
                                  })}
                                </div>
                              )
                            })}

                            {customer.order_items && customer.order_items.map((item, index) => {
                              return (<div>{item.name}</div>)
                            })}
                            </div>
                          </div>
                          <div className='w-full text-right'>{customer.address}</div>
                        </div>
                      </div>
                      {provided.placeholder}
                    </div>
                   )}
                </Draggable>
               ))}
              {provided.placeholder}
            </div>
           )}
        </Droppable>
        </div>
        <div>
          {employees && employees.map((employee, index) => {
              return (
                <div className="">
                <div className="p-5" onChange={(e) => setCurrentMap(parseInt(e.target.value))}>
                  <label className="text-lg">Show On Map </label>
                  <input type="radio" name="map_selection" checked={currentMap === index ? true : false}  value={index} />
                </div>
                <Droppable droppableId={`droppable${employee.id}`}>
                  {(provided, snapshot) => (
                    <div 
                      ref={provided.innerRef} 
                      style={getListStyle(snapshot.isDraggingOver)}
                      {...provided.droppableProps}
                    >
                    <div className="flex flex-row place-content-evenly">
                      <Title title={`${employee.first_name} ${employee.last_name} `} />
                      <button className={`${classNames.select_page_button}`} onClick={() => {
                        if (!employee.customers.length) {
                          swal({title: `Drag customers into ${employee.first_name}'s list`, icon: "warning"})
                          return ;
                        }
                        Emailer.sendRoutes({employee}).then(data => swal({title: "Success, Email sent", icon: "success"}))
                      }}> Send to - {employee.email}</button>
                    </div>
                      {employee.customers && employee.customers.map((customer, index) => (
                        <Draggable
                          key={`${employee.id}-${customer.id}`}
                          draggableId={`${employee.id}-${customer.id}`}
                          index={index}
                        >
                          {(provided, snapshot) => (
                            <div>
                              <div
                                ref={provided.innerRef}
                                {...provided.dragHandleProps}
                                {...provided.draggableProps}
                                style={getItemStyle(
                                  provided.draggableProps.style,
                                  snapshot.isDragging
                                )}
                              >
                                <div className="grid grid-cols-2">
                                  <div className='w-full'>
                                    <div className="border-b-2">Stop: {String.fromCharCode(66 + index)}</div>
                                    <div className="grid grid-cols-2">
                                    {customer && customer.order_packages.map((pack, index) => {
                                      return (
                                        <div className="text-xs pb-1">
                                          <div className="underline">{pack.name}</div>
                                          {pack.order_package_items.map((item, index) => {
                                            return (<div className=" pl-3">{item.name}</div>)
                                          })}
                                        </div>
                                      )
                                    })}

                                    {customer.order_items && customer.order_items.map((item, index) => {
                                      return (<div>{item.name}</div>)
                                    })}
                                    </div>
                                  </div>
                                  <div className='w-full text-right'>{customer.address}</div>
                                </div>
                              </div>
                              {provided.placeholder}
                            </div>
                           )}
                        </Draggable>
                       ))}
                      {provided.placeholder}
                    </div>
                   )}
                </Droppable>

                </div>
              )

          })}
          </div>
        </DragDropContext>
      </div>
      </>
     );
   
}