import React, {useState, useEffect, useRef} from 'react'
import { useParams, useHistory, Link } from "react-router-dom";

import ReactTooltip from 'react-tooltip';
import moment from 'moment'

import { IconContext } from "react-icons";
import {FaBars} from 'react-icons/fa'
import {BsPersonLinesFill, BsCalendar, BsFillCalendar2RangeFill} from 'react-icons/bs'

function ToolTip({children, tip}) {
  let ref = useRef();
  return (
    <>
      <div ref={ref => ref = ref} data-tip={tip} className="" style={{backgroundColor: "white"}}>
        {children}
      </div>

      <ReactTooltip />
    </>
  )
}

export default function Topnav({collapseLeft, setCollapseLeft, collapseRight, setCollapseRight, rightToggleIcon, showRightToggle, leftToggleIcon, showLeftToggle, omitSearch}) {

  const {search, searchEnd} = useParams()
  const history = useHistory();
  const [currentSearch, setCurrentSearch] = useState(moment().format('YYYY-MM-DD'))
  const [currentSearchEndDate, setCurrentSearchEndDate] = useState('')
  const [currentSearchType, setCurrentSearchType] = useState("date");
  const [searchDateEnd, setSearchDateEnd] = useState(false);

  useEffect(function() {
    if (search) {
      if (moment(search, 'YYYY-MM-DD', true).isValid()) {
        setCurrentSearch(moment(search).format('YYYY-MM-DD'))
        setCurrentSearchType("date");
        if (searchEnd && moment(searchEnd, 'YYYY-MM-DD', true).isValid()) {
          setCurrentSearchEndDate(moment(searchEnd).format('YYYY-MM-DD'))
          setSearchDateEnd(true);
        }
      } else {
        setCurrentSearch(search)
        setCurrentSearchType("text");
      }
    }
  }, [search, searchEnd])


  return (
    <div className="nav grid grid-cols-1-10-1 bg-gray-200">
      {showLeftToggle && 
        <button className='pl-5' onClick={() => {
          if(collapseLeft.length > 0) {
            setCollapseLeft('')
          } else {
            setCollapseLeft('collapse-left')
          }
      }}> {leftToggleIcon ? leftToggleIcon : < FaBars />}
      </button>}


    {!omitSearch && 
        <div className='p-1 w-full grid grid-cols-10-1-1 pl-2 pr-2 justify-center place-content-center'>
        {
          currentSearchType === 'date' && 
            <span>
              <input 
                type='date' 
                className={`border-2 rounded-md h-full pr-2 ${searchDateEnd ? 'w-1/2' : 'w-full'} `} 
                value={currentSearch} 
                placeholder='search' 
                onChange={(e) =>{
                  setCurrentSearch(e.target.value)
                }}

                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    history.push(`/search/${currentSearchType}/${currentSearch}`)
                  }
                }}
              />
            { searchDateEnd === true && <input 
                    type='date' 
                    className='border-2 rounded-md h-full pr-2 w-1/2' 
                    value={currentSearchEndDate} 
                    placeholder='search' 
                    onChange={(e) =>{
                      setCurrentSearchEndDate(e.target.value)
                    }}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        history.push(`/search/${currentSearchType}/${currentSearch}/${currentSearchEndDate}`)
                      }
                    }}
                  />
            }
          </span>
        }
        {
          currentSearchType === 'text' &&
            <input 
              type='text' 
              className='border-2 rounded-md pr-2 w-full' 
              value={currentSearch} 
              placeholder='search'
              onChange={(e) =>{
                setCurrentSearch(e.target.value)
              }}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  history.push(`/search/${currentSearchType}/${currentSearch}`)
                }
              }}
            />
        }

        <Link to={`/search/${currentSearchType}/${currentSearch}/${currentSearchEndDate}`} className="p-2">
          <button className="text-xs">Search</button>
        </Link>


      <span className='flex flex-row'>

        <ToolTip tip="Search By Date">
          <IconContext.Provider value={{color: currentSearchType === 'date' && searchDateEnd === false ? 'blue' : 'black', size: "1.6em", className: "m-2" }}>
              <BsCalendar 
                onClick={() => {
                  setCurrentSearch(moment().format('YYYY-MM-DD'))
                  setCurrentSearchEndDate('')
                  setCurrentSearchType('date')
                  setSearchDateEnd(false);
                }}
              />
          </IconContext.Provider>
        </ToolTip>

        <ToolTip tip="Search Date Range">
          <IconContext.Provider value={{color: currentSearchType === 'date' && searchDateEnd === true ? 'blue' : 'black', size: "1.6em", className: "m-2" }}>
              <BsFillCalendar2RangeFill 
                onClick={() => {
                  setCurrentSearch(moment().format('YYYY-MM-DD'))
                  setCurrentSearchEndDate(moment().format('YYYY-MM-DD'))
                  setCurrentSearchType('date')
                  setSearchDateEnd(true);
                }}
              />
          </IconContext.Provider>
        </ToolTip>
        
        <ToolTip tip="Search Record">
          <IconContext.Provider value={{color: currentSearchType === 'text' ? 'blue' : 'black', size: "1.6em", className: "m-2" }}>
              <BsPersonLinesFill 
                onClick={() => { 
                  setCurrentSearch('');
                  setCurrentSearchType('text')
                  setCurrentSearchEndDate('')
                  setSearchDateEnd(false);
                }}
              />
          </IconContext.Provider>
        </ToolTip>
      </span>
      </div>
    }

    {showRightToggle && 
        <button className={`text-right justify-self-end pr-5 `} onClick={() => {
          if(collapseRight.length > 0) {
            setCollapseRight('')
          } else {
            setCollapseRight('collapse-right')
          }

        }}>{rightToggleIcon ? rightToggleIcon : < FaBars />}</button>}
    </div>

  )
}
