import React from 'react'
import {Link} from 'react-router-dom'

import { IconContext } from "react-icons";
import {AiOutlineHome, AiFillDropboxCircle} from 'react-icons/ai'
import {BsFillPersonPlusFill, BsWrench} from 'react-icons/bs'
import {RiProductHuntLine} from 'react-icons/ri'
import {DiGoogleAnalytics} from 'react-icons/di'

export default function PageList() {
  return (
    <div className="flex flex-row justify-around p-2">
      <IconContext.Provider value={{ color: "black", size: "2em", className: "global-class-name" }}>
          <Link to='/'><AiOutlineHome /></Link>
          <Link to='/newOrder'><BsFillPersonPlusFill /></Link>
          <Link to='/inventory'><AiFillDropboxCircle /></Link>
          <Link to='/analytics'><DiGoogleAnalytics /></Link>
      </IconContext.Provider>		
      <IconContext.Provider value={{ color: "black", size: "1.6em", className: "global-class-name" }}>
        <Link to='/settings' style={{marginTop: 3}}><BsWrench /></Link>		
      </IconContext.Provider>		
    </div>
  )
}
