import React, {useState} from 'react'

export default function OrderPackageGrid({order_packages, onClick, showAlways}) {

	const [showItems, setShowItems] = useState(showAlways || false);
	if (!order_packages.length) return null
	return (
		<div>
			<div className="grid sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-1 text-black">
				{order_packages && order_packages.map((order_package, index) => {
					return (
						<>
							<>
								<button 
									className="mt-5 package-items bg-blue-50 hover:bg-blue-100 rounded" 
									onClick={() => { 
										if (onClick) {
											onClick(order_package)
										}
										setShowItems(!showItems)
									}}
								>

									<div className="">
										<div className="flex flex-row p-1">
											<small className="w-full text-left">
												{order_package.name}
											</small>
											<div className="mr-5 w-full text-right">
												${order_package.price}
											</div>
										</div>
									</div>
									<PackageItemsCollapse 
										package_items={order_package.order_package_items} 
										show={showItems}
										showAlways={showAlways}
									/>
								</button>
							</>
							
						</>
					)
				})}
			</div>
		</div>


	)

}

function PackageItemsCollapse({package_items, show, showAlways}) {
	if (show || showAlways) {
		return (
			<>
				{package_items.map(item => {
					return (
						<>
							<div className="pl-5 text-left">	
								<small className="flex flex-row p-1">
									<div className="w-full">
										{item.name}	<span style={{fontSize: 10}}>(x{item.quantity})</span> - ${item.price}
									</div>
								</small>
							</div>
						</>
					)
				})}
			</>
		)
	}

	return null;
	


}
